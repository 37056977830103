import React from 'react';

import TaskCompose from "../task/TaskCompose";

const TaskPage = () => {
  return (
     <TaskCompose />
  );
}

export default TaskPage;