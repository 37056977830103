import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import {onLogin} from "../reduce/actions";
import {
    IonButton,
    IonContent, IonFooter,
    IonHeader, IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage, IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    NavContext
} from "@ionic/react";

import React, {useCallback, useContext, useEffect, useState} from "react";
import {forgottenPasswordChange, forgottenPasswordQuery} from "../common/ApiUtils";

const ForgottenPassword = (props)  => {

    const {navigate} = useContext(NavContext);

    const [data, setData] = useState({ user:  null, message: null });
    const [passwordMessage, setPasswordMessage] = useState(false);
    const [done, setDone] = useState(false);

    //console.log(props)

/*    const redirect = useCallback(
        () => navigate('/', "forward"),
        [navigate]
    );
*/
    let _entity = {
        password: null,
        passwordAgain: null,
    };

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const result = await forgottenPasswordQuery(props.match.params.uuid);
            if (result.message) {
                setData({user: null, message: result.message});
            } else {
                setData({user: result, message: null});
            }
        }
        fetchData();
        //console.log(result);

    }, [props.match.params.uuid]);


    if (!props.match || !props.match.params || !props.match.params.uuid) return (<></>);

    const submitEnabled = () => {
        return _entity.password.value &&
            _entity.passwordAgain.value && _entity.password.value === _entity.passwordAgain.value;
    }

    const showError = () => {
        return (
            <div
                style={{
                    color: "red",
                    padding: 5,
                    paddingLeft: 12,
                    fontSize: "smaller"
                }}
            >
                Hesla nejsou shodná.
            </div>
        );
    };

    const changePasswordChange = async entity => {
        const result = await forgottenPasswordChange(entity);
        if (result) {
            setDone(true);
            //redirect();
        }
    }


    const submit = e => {
        e.preventDefault();
        var disabled = !submitEnabled();
        setPasswordMessage(disabled);
        if (disabled) {
            // _entity.password.value = "";
            // _entity.passwordAgain.value = "";
            return;
        }

        const entity = {
            uuid: props.match.params.uuid,
            password: _entity.password.value,
            passwordAgain: _entity.passwordAgain.value
        }
        changePasswordChange(entity);

    };

    if (done) return (<Redirect to="/login" />);


    return (

        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Obnova zapomenutého hesla</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {data && data.user &&
                    <form onSubmit={submit}>
                        <IonList>
                            <IonItem>
                                <IonLabel position="stacked">Jméno</IonLabel>
                                <IonInput type={"text"} value={data.user.name} disabled={true} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Uživatelké jméno pro přihlášení</IonLabel>
                                <IonInput type={"text"} value={data.user.username} disabled={true} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Email</IonLabel>
                                <IonInput type={"text"} value={data.user.email} disabled={true} />
                            </IonItem>

                            <IonItem>
                                <IonLabel position="stacked">Nové heslo</IonLabel>
                                <IonInput type={"password"} ref={input => _entity.password = input} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Nové heslo znovu</IonLabel>
                                <IonInput type={"password"} ref={input => _entity.passwordAgain = input} />
                            </IonItem>
                            { passwordMessage && showError() }
                            <IonFooter>
                                <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
                            </IonFooter>

                        </IonList>
                    </form>
                }
                {data && data.message &&
                    <IonList>
                        <IonItem>
                            <IonText color="danger">
                                <h4 >{data.message}</h4>
                            </IonText>
                        </IonItem>
                    </IonList>

                }
            </IonContent>
        </IonPage>
    )
}


const mapDispatchToProps = function (dispatch) {
    return {
        dispatchOnLogin(load) {
            {
                dispatch(onLogin(load))
            }
        },
    }
}


export default connect(null, mapDispatchToProps)(withRouter(ForgottenPassword));
