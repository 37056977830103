import React, {Component} from "react";
import {aprsLogbookFind} from "../common/ApiUtils";
import {
    IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent, IonItem,
    IonLabel,
    IonList
} from "@ionic/react";
import {igcDownloadLink, REST_APRSLOGBOOK_URL} from "../common/constants";
import {
    addTimeArr,
    addZero,
    dateStringJavaToDate,
    dayConvertToHuman,
    reverseDateToLocalDate, subtractTimeArr,
    timeOfDateString
} from "../common/TimeUtils";
import "./AprsLogbookContent.css"
import {download} from "ionicons/icons";

/*const AprsLookbookContent = (EntityComponent, mainResourceURL, resourceName,
                        listMessage ="List of items",
                        sortFce=((a,b) => (a.name < b.name) ? 1: -1), headerListProperty=null, headerFce=f=>f ) =>
*/

const identifierOrKey = (record) => {
    return record.identifier && record.identifier.trim().length > 0 ? record.identifier : record.key;
}

const totalTime = (group) => {
    let time = [0,0];
    for (var i = 0; i < group.length ; i++) {
        var t = group[i].duration;
        if (t) {
            time = addTimeArr(t, time);
        }
    }

    return time[0] + ":" + addZero(time[1].toString());

}


class AprsLogbookContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entityList: [],
      search: "",
      disableInfiniteScroll: false,
      scrollPage: 0,
      place : props.place
    };
    this.searchChange = this.searchChange.bind(this);
  }


  componentWillReceiveProps(nextProps) {
      if (nextProps.place !== this.props.place ||
          nextProps.identifier !== this.props.identifier ||
          nextProps.day !== this.props.day)
      {
          this.setState({place: nextProps.place, scrollPage: 0, disableInfiniteScroll : false});
          this.loadEntityResource({place: nextProps.place,
              identifier: nextProps.identifier,
              date: nextProps.day});
      }
  }


  componentDidMount() {
    this.loadEntityResource();
  }

  async loadEntityResource(data) {
    const {scrollPage, place} = this.state;
    const {identifier, day} = this.props;
    let dayFormatted = day ? reverseDateToLocalDate(day) : null;
    let message = {page: scrollPage, identifier : identifier, place : place, date: dayFormatted};
    if (data) {
        let dayFormatted = data.date ? reverseDateToLocalDate(data.date) : null;
        message = {page: 0, identifier : data.identifier, place : data.place, date: dayFormatted};
    }
    //console.log("identifier : " +message.identifier + " place: "  + message.place);


    const resource = await aprsLogbookFind(message);
    if (resource) {
      //console.log(resource);
      this.setState({
        entityList: resource
      })
    } else {
      //console.log("Resource of " + mainResourceURL + " return null");
    }
  }

  async loadEntityNext() {
    const {scrollPage, entityList, place } = this.state;
    const {identifier, day} =  this.props;
    let dayFormatted = day ? reverseDateToLocalDate(day) : null;
    let message = {page: scrollPage +1, identifier : identifier, place : place, date: dayFormatted};
    const resource = await aprsLogbookFind(message);
    if (resource) {
      //console.log("Resource: " + resource.toSource());
      var list = resource;
      if (list && list.length >= 100) {
        this.setState({
          scrollPage: scrollPage + 1,
          entityList: [...entityList, ...list]
        })
      } else {
        this.setState({
          disableInfiniteScroll: true
        })

      }

    }
  }

  searchChange(e) {
    this.setState({search: e.target.value});
  }

  async pageNext(e) {
    await this.loadEntityNext();
    e.target.complete();
  }

  groupByDay(list) {
      var grouped = [];
      var dayList = [];
      var lastDay = "";
      for (var i = 0; i < list.length; i++) {

          if (list[i].day !== lastDay) {
              if (dayList.length !== 0) {
                  grouped.push(dayList);
                  dayList = [];
              }
              lastDay = list[i].day;
          }
          // skip OE or D flights
          if (list[i].identifier) {
              if (list[i].identifier.startsWith('OE')
                  || list[i].identifier.startsWith('D')) continue;
          }

          dayList.push(list[i]);
      }
      if (dayList.length !== 0) {
          grouped.push(dayList);
      }
      return grouped;
  }

    placeOrAnchor = (pl) => {
        if (!pl) return pl;
        var i = pl.indexOf(" ");
        if (i < 0) return <span onClick={() => this.props.setPlace(pl)}>{pl}</span>;
        return <a target={'_blank'} href={'https://mapy.cz/zakladni?z=17&q='+pl} title={pl}>GPS</a>
    }




  render() {
    const {entityList, search, disableInfiniteScroll} = this.state;
    const {findFce, filterFce, sortFce, headerFce} = this.props;
    let sortFunction = f => f;
    let headerFunction = f => f;
    if (!sortFce) {
        sortFunction = sortFce;
    }
      if (!headerFce) {
          headerFunction = headerFce;
      }
    let searchInput;
    let lastProperty = null;
    let list = entityList;
    if (filterFce) {
      list = list.filter(filterFce);
    }
    if (findFce) {
      setTimeout(() => {
        if (searchInput) searchInput.setFocus();
      },150);

    }
    return (
        <>
          <IonList>
            {list && (!findFce || findFce && !search) && this.groupByDay(list.sort(sortFce)).map((group, i) =>
                <IonItem>
                    <IonLabel>
                        <h2>{dayConvertToHuman(group.at(0).day)}</h2>

                        <table className={'aprs-table'}>
                            <tr>
                                <th>Identifikátor</th>
                                <th>vzlet</th>
                                <th>přistání</th>
                                <th className={'no-mobile'}>doba</th>
                                <th>místo vz.</th>
                                <th>místo př.</th>
                                <th>IGC</th>
                            </tr>
                            {group.map((record, j) =>
                                <tr>
                                    <td onClick={() => this.props.setIdentifier(record.identifier)}>{identifierOrKey(record)}</td>
                                    <td>{record.takeOff ? record.takeOff : ""}</td>
                                    <td>{record.landing ? record.landing : ""}</td>
                                    <td className={'no-mobile'}>
                                        {record.duration ? record.duration : ""}
                                    </td>
                                    <td>{this.placeOrAnchor(record.takeoffPlace)}</td>
                                    <td>{this.placeOrAnchor(record.landingPlace)}</td>
                                    <td>{record.hasIgcFile ? <a href={igcDownloadLink(record)}><IonIcon icon={download} /></a>:''}</td>
                                </tr>
                            )}
                            {this.props.identifier && group.length > 0 &&
                                <tr>
                                    <td>Celkem</td>
                                    <td>{group.length}x</td>
                                    <td>{totalTime(group)}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }
                        </table>

                    </IonLabel>
                </IonItem>
            )}
          </IonList>
          <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll}
              onIonInfinite={(e) => this.pageNext(e)}>
            <IonInfiniteScrollContent loadingText="Načítám další ...">
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
    )
  }
}


export default AprsLogbookContent;