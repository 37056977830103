import React, {Component} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage, IonTitle,
  IonToolbar
} from "@ionic/react";

import {arrowBack, menu} from "ionicons/icons";
import {TowTaskList} from "./TowTaskList";

class TowTaskCompose extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    const {setTask, back} = this.props;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <>
                {back &&
                <IonButtons slot="start">
                  <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                    <IonIcon icon={arrowBack}/> Zpět
                  </IonButton>
                </IonButtons>
                }
                <IonTitle>
                  Úlohy
                </IonTitle>
              </>
              <IonButtons slot="end">
                <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <TowTaskList setEntity={setTask ? setTask : f=>f} />
          </IonContent>
        </IonPage>
    );
  }
}

export default TowTaskCompose;