import {
    IonMenu,
    IonContent,
    IonList,
    IonIcon,
    IonLabel,
    IonItem,
    IonFooter, IonToolbar, IonTitle
} from "@ionic/react";
import { withRouter } from 'react-router';
import * as React from "react";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    timeSharp,
    pin,
    logOut,
    hammerOutline,
    radio,
    paw,
    keyOutline,
    airplaneOutline,
    clipboardOutline,
    flagOutline,
    personOutline,
    libraryOutline,
    pawOutline,
    cloudDownloadOutline, albumsOutline, manOutline, mapOutline
} from 'ionicons/icons'
import {hasRole, loadResource, sendCloseDate, sendReOpenDate} from "../common/ApiUtils";
import {fromHumanToLocalDate} from "../common/TimeUtils";
import {logout, onCloseReceive, onReOpenReceive, infoDataDispatch} from "../reduce/actions";
import {
    ACCESS_TOKEN, AISVIEW_METAR_URL,
    concatUrlId,
    DOWNLOAD_PDF_URL,
    REST_TASK_GROUP_URL,
    ROLE_ADMIN,
    ROLE_USER
} from "../common/constants";
import "./SideMenuWithRouter.css";
import {useRef} from "react";
import WeatherModal from "./WeatherModal";
import {Label} from "reactstrap";
import ListItem from "antd/es/upload/UploadList/ListItem";

const pages = [
    { title: 'Šmírka', path: '/', icon: clipboardOutline,  role: ROLE_USER },
    { title: 'Flotila', path: '/gliders', icon: airplaneOutline,  role: ROLE_USER },
    { title: 'Piloti', path: '/pilots', icon: manOutline,  role: ROLE_USER },
    { title: 'Letiště', path: '/place', icon: pin,  role: ROLE_USER },
    { title: 'Úlohy', path: '/task', icon: flagOutline,  role: ROLE_USER },
    { title: 'Deník kluzáku', path: '/logbook', icon: libraryOutline,  role: ROLE_USER },
    { title: 'Uživatelé', path: '/users', icon: personOutline, role: ROLE_ADMIN},
    { title: 'Tracking', path: '/tracking', icon: pawOutline, role: ROLE_USER },
    { title: 'Aprs logbook', path: '/aprslogbook', icon: paw, role: ROLE_USER },
    { title: 'Aprs aktivní', path: '/aprs', icon: radio, role: ROLE_USER },
    { title: 'Uživatelské konto', path: '/user', icon: keyOutline, role: ROLE_USER},
    { title: 'Plánovací tabulka', path: 'https://docs.google.com/spreadsheets/d/1HDzePKIosYsxaWtD2_BpfOCEC73SJra2tMIGSIcp3kY/edit#gid=0', icon: albumsOutline, role: ROLE_USER, external:true },
    { title: 'Glider tracker', path: 'https://glidertracker.org/#lst=http://www.cambarus.cz/lkhs/lkhs.csv&lat=49.578&lon=14.759&z=9&id=&nowelcome', icon: mapOutline, role: ROLE_USER, external:true },
    //{ title: 'About', path: '/about', icon: information }
];

const WeatherSvgIcon = () => (
    <svg viewBox="0 0 64 64" style={{ height: '25px', fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
        <path
            d="M23.634 36.002a2.284 2.284 0 0 0 2.162-1.554 6.098 6.098 0 0 1 5.813-4.214 6.166 6.166 0 0 1 5.842 4.204 2.25 2.25 0 0 0 2.133 1.532c.964.032 1.949.032 1.949.032a6.498 6.498 0 0 1 6.5 6.5v.003a6.501 6.501 0 0 1-6.5 6.499H21.684a6.5 6.5 0 0 1-6.499-6.499v-.003a6.497 6.497 0 0 1 6.499-6.5h1.95z"
            style={{ fill: 'none', stroke: '#222a33', strokeWidth: '1.5px' }}
        />
        <path
            d="M11.864 44.68a6.499 6.499 0 0 1-4.5-6.184v-.003a6.499 6.499 0 0 1 6.499-6.5h3.778a6.163 6.163 0 0 1 6.147-5.768c2.76 0 5.099 1.82 5.88 4.324M37.232 26.996a6.107 6.107 0 0 1 2.98-.771 6.165 6.165 0 0 1 5.842 4.205 2.251 2.251 0 0 0 2.133 1.531c.964.032 1.95.032 1.95.032a6.501 6.501 0 0 1 6.499 6.5v.003a6.499 6.499 0 0 1-6.499 6.5h-2.6"
            style={{ fill: 'none', stroke: '#222a33', strokeWidth: '1.5px' }}
        />
        <path
            d="M38.864 25.996c0-3.312 2.688-6 6-6a6.003 6.003 0 0 1 5.658 8M44.864 14.996v2M52.642 18.217l-1.414 1.415M55.864 25.996h-2M37.085 18.217l1.415 1.415"
            style={{ fill: 'none', stroke: '#222a33', strokeWidth: '1.5px' }}
        />
    </svg>
);

const SideMenu = ({ history, canClose, day, canDownloadPdf, currentUser, dispatchLogout, isAuthenticated, onCloseDay, onReOpenDay, canReopen, dispatchInfoData, infoData}) => {

    const [activePage, setActivePage] = useState(pages[0].title);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        const loadInfoData = async () => {
            const res = await loadResource(AISVIEW_METAR_URL);
            dispatchInfoData(res.data);
        };
        loadInfoData();

    }, []);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };


    const hideMenu = () => {
      if (menRef.current) {
        menRef.current.close();
      }
    }

    let menRef = useRef(null);

    const renderInfoData = () => {
        if (!infoData) {
            return null;
        }

        const observeDate = new Date(infoData.observeDate);
        const threeHoursAgo = new Date(Date.now() - 3 * 60 * 60 * 1000);
        const isRecent = observeDate > threeHoursAgo;


        const infoDataStyle = !isRecent ? { backgroundColor: 'yellow' } : {};

        return (
            <IonItem style={infoDataStyle} className="info-data" onClick={() => {setShowModal(true)}} slot={"start"}>
                {/*<p><strong>METAR:</strong> {infoData.metar}</p>*/}
                <WeatherSvgIcon/>
                <Label>QNH: {infoData.qnh}</Label>

                {/*<p><strong>Visibility:</strong> {infoData.visibility}</p>
                <p><strong>Wind:</strong> {infoData.wind}</p>
                <p><strong>Observation Date:</strong> {formatDate(observeDate)}</p>
                <p><strong>Temperature:</strong> {infoData.temperature}</p>
                <p><strong>{infoData.sunriseSunsetElement.txt}:</strong> {infoData.sunriseSunset}</p> */}

            </IonItem>
        );
    };

    const renderMenuItems = () => {
        //console.log(currentUser);
        let filtered = pages.filter((page) => hasRole(page.role, currentUser));
        var menuItems = filtered.map((page) => (
            <IonItem button key={page.title} auto-hide="false"
                     color={page.title === activePage ? 'primary' : ''}
                     onClick={() => navigateToPage(page)}
                     slot={"start"}>
                <IonIcon icon={page.icon}/>&nbsp;
                <IonLabel>
                    {page.title}
                </IonLabel>
            </IonItem>
        ));
        return menuItems;
    };

    const closeDay = async () => {
        var res = await sendCloseDate({flyDay: fromHumanToLocalDate(day)});
        hideMenu();
        onCloseDay(res);
    };

    const reopenDay = async () => {
        var res = await sendReOpenDate({flyDay: fromHumanToLocalDate(day)});
        hideMenu();
        onReOpenDay(res);
    };

    const handleLogout = (redirectTo = "/") => {
        localStorage.removeItem(ACCESS_TOKEN);
        dispatchLogout();
        history.push(redirectTo);
        window.location.reload();
    };

    const navigateToPage = (page) => {
        if (page.external) {
            window.location.href = page.path;
        } else {
            history.push(page.path);
            setActivePage(page.title);
            hideMenu();
        }

    };

    return (
        <IonMenu contentId="main" ref={menRef}>
            {/*<IonHeader>*/}
            {/*    <IonToolbar>*/}
            {/*        <IonTitle>*/}
            {/*            Menu*/}
            {/*        </IonTitle>*/}
            {/*    </IonToolbar>*/}
            {/*</IonHeader>*/}
            <IonContent>
                <IonList>
                    {renderMenuItems()}
                    {
                        canClose &&
                        <IonItem button auto-hide="false"
                                 slot={"start"} onClick={() => closeDay()}>
                            <IonIcon icon={timeSharp}/>&nbsp;
                            <IonLabel>
                                Uzavřít den
                            </IonLabel>
                        </IonItem>
                    }

                  {
                    canDownloadPdf &&
                    <IonItem button auto-hide="false" download={'neco'} href={concatUrlId(DOWNLOAD_PDF_URL, day.replace(/\./g, "-"))} target={"_blank"} slot={"start"}>

                        <IonIcon icon={cloudDownloadOutline}/>&nbsp;
                        <IonLabel>
                          Stáhnout PDF
                        </IonLabel>
                    </IonItem>
                  }
                  {
                    canReopen &&
                    <IonItem button auto-hide="false"
                             slot={"start"} onClick={() =>reopenDay()}>
                      <IonIcon icon={hammerOutline}/>&nbsp;
                      <IonLabel>
                        Znovu otevřít
                      </IonLabel>
                    </IonItem>
                  }
                </IonList>
            </IonContent>
            {
              currentUser &&
                <IonFooter>
                    { infoData &&
                        <WeatherModal isOpen={showModal} onClose={closeModal} infoData={infoData} />
                    }
                    <IonList>
                        {renderInfoData()}
                        <IonItem button auto-hide="false" onClick={() => handleLogout()} slot={"start"}>
                            <IonIcon icon={logOut}/>&nbsp;
                            <IonLabel>
                                Odhlásit ({currentUser.name})
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </IonFooter>
            }
        </IonMenu>
    );
};

const mapStateToProps = state => {
  return {
    canClose: state? state.canClose: false,
    canDownloadPdf : state ? state.canDownloadPdf:false,
    day:state? state.day: "",
    isAuthenticated : state ? state.isAuthenticated : false,
    currentUser : state ? state.currentUser : null,
    canReopen : state ? state.canReopen : false,
    infoData : state ? state.infoData : false,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseReceive(response) {
      dispatch(onCloseReceive(response))
    },
    dispatchLogout() {
      dispatch(logout())
    },
    onCloseDay(response) {
      dispatch(onCloseReceive(response))
    },
    onReOpenDay(response) {
      dispatch(onReOpenReceive(response))
    },
    dispatchInfoData(response) {
      //alert("bb");
      dispatch(infoDataDispatch(response))
    }
  }
};

const VisibleSideMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(SideMenu);


export default withRouter(
    VisibleSideMenu
);