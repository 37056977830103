import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/l10n/cs.js"
import "./CustomCalendar.css"
import React, { Component } from 'react'
import {activeDays} from "../common/ApiUtils";
import {formatDate} from "../common/TimeUtils";


class CustomCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.date,
      enabledDays: [ new Date()]
    };
    this.onSelectDate = this.onSelectDate.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      const {enabledDays} = this.state;
      if (enabledDays.indexOf(this.props.date) > 0) {
        this.setState({date : this.props.date});
      } else {
        let newDays = [...enabledDays];
        newDays.push(this.props.date);
        this.setState({date : this.props.date , enabledDays : newDays});
      }
    }
  }


  async onSelectDate(a, b, instance) {
    var month = instance.currentMonth +1;
    var year = instance.currentYear;
    var request = { year: year, month: month};
    var response = await activeDays(request);
    let now = new Date();
    let enabled = [];
    if (response.enabled) {
      enabled = [...response.enabled];
      //console.log(enabled);
    }
    if (month === (now.getMonth() +1) && year === now.getFullYear()) {
      var nowFormated = now.getDate() + "." +(now.getMonth() +1)+ "." + now.getFullYear();
      enabled.push(nowFormated);
    }
    this.setState({enabledDays: enabled.length > 0 ?
          enabled : [date => false]});
  }

  onDateChange(date) {
    //console.log("Date changed: " +date);
    if (date) {
      this.props.history.push('/flyday/' + formatDate(date));
    }
  }

  render() {
    const { date, enabledDays } = this.state;
    enabledDays.push(date);
    return (
        /*<<>
          <IonDatetimeButton datetime="date" displayFormat="YYYY-MM-DD"></IonDatetimeButton>

          <IonModal keepContentsMounted={true}>
            <IonDatetime isDateEnabled={enabledDays} id="date" displayFormat="DD.MM.YYYY"></IonDatetime>
          </IonModal>
        </>         <IonModal keepContentsMounted={true}>
            <IonDatetime isDateEnabled={enabledDays} id="date" displayFormat="DD.MM.YYYY"></IonDatetime>
          </IonModal>
        </>
        */
        <>
      <Flatpickr value={date} options={{dateFormat: "d.m.Y", enable: enabledDays, locale: "cs" }}
                   onOpen={this.onSelectDate} onMonthChange={this.onSelectDate}
                   onYearChange={this.onSelectDate} onChange={this.onDateChange} />
        </>
    )
  }
}

export default CustomCalendar;

