import React, {Component} from "react";
import {createResource, deleteResource, errorsMessage, loadResource} from "../common/ApiUtils";
import {
    IonButton, IonButtons, IonCol,
    IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonLabel,
    IonList,
    IonListHeader,
    IonModal, IonRow, IonSearchbar, IonTitle, IonToast,
    IonToolbar
} from "@ionic/react";
import {add} from "ionicons/icons";


const ExpandableList = (EntityComponent, EntityForm, mainResourceURL, resourceName,
                        listMessage ="List of items",newEntityMessage = "new item",
                        newEntityButtonMessage = "add item",
                        sortFce=((a,b) => (a.name < b.name) ? 1: -1) ) =>


  class ExpandableList extends Component {

    constructor(props) {
      super(props);
      this.state = {
        entityList: [],
        addModal :false,
        search: ""
      };
      this.newEntitySave = this.newEntitySave.bind(this);
      this.deleteEntity = this.deleteEntity.bind(this);
      this.searchChange = this.searchChange.bind(this);
      this.setShowToast = this.setShowToast.bind(this);
    }

    componentDidMount() {
      this.loadResource();
    }


    async deleteEntity(entity) {
      //console.log("delete resource: " + entity._links.self.href);
      let list = [...this.state.entityList];
      var resp = await deleteResource(entity._links.self.href);
      if (Math.floor(resp.statusCode / 100) === 2) {
        list = list.filter(p => p._links.self.href !== entity._links.self.href);
        this.setState({entityList:list});
      } else {
        this.setShowToast(true, "Záznam nelze smazat");
      }
    }

    async loadResource() {
      const resource = await loadResource(mainResourceURL, this.props.loadApiQuery);
      if (resource) {
        //console.log("Resource: ");
        //console.log(resource);
        this.setState({
          entityList: resource._embedded[resourceName]
        })
      } else {
        //console.log("Resource of " + mainResourceURL + " return null");
      }
    }

    closeModal() {
      this.setState({addModal:false})
    }

    showModal() {
      this.setState({addModal:true})
    }

    setShowToast(show, message) {
      this.setState({showToast: show, toastMessage: message})
    }

    toastAlert() {
      const {showToast, toastMessage} = this.state;
      return (
          <IonToast
              isOpen={showToast}
              onDidDismiss={() => this.setShowToast(false, "")}
              message={toastMessage}
              duration={5000}
          />
      )
    }

    async newEntitySave(entity) {
      const createUrlResource = this.props.createEntityUrl ? this.props.createEntityUrl :mainResourceURL;
      const p = await createResource(createUrlResource, entity);
      if (p.errors) {
          this.setState({showToast: true, toastMessage: errorsMessage(p.errors)});
      } else {
          this.setState({addModal: false, entityList: [...this.state.entityList, p]});
      }
      //alert(p.toSource());

    }

    searchChange(e) {
      this.setState({search: e.target.value});
    }


    render() {
      const {entityList, addModal, search} = this.state;
      let entities = [... entityList];
      const {findFce, addon, mapFce} = this.props;
      let searchInput;
      if (findFce) {
        setTimeout(() => {
          if (searchInput) searchInput.setFocus();
        },200);

      }
      if (mapFce) {
          entities = mapFce(entities);
          //console.log(entities);
      }

      return (
        <>
          <IonList lines="full">
            {(listMessage || findFce) &&
              <IonListHeader>
                  <IonToolbar>
                      {listMessage && <IonLabel>{listMessage}</IonLabel>}
                      {findFce &&
                      <IonSearchbar onInput={this.searchChange} ref={_input => searchInput = _input}
                                    placeholder={'Hledat'}/>
                      }
                  </IonToolbar>
              </IonListHeader>
            }
            {entities && findFce && search && entities.filter(findFce(search)).sort(sortFce).map((entity, i) => (
                <EntityComponent key={i} entity={entity} deleteEntity={this.deleteEntity} type={this.props.type} setShowToast={this.setShowToast} {...this.props}/>
            ))}
            {entities && (!findFce || findFce && !search) && entities.sort(sortFce).map((entity, i) => (
                <EntityComponent key={i} entity={entity} deleteEntity={this.deleteEntity} type={this.props.type} setShowToast={this.setShowToast} {...this.props}/>
            ))}
          </IonList>
          <IonModal isOpen={addModal} backdropDismiss={false} >
            <IonHeader translucent={true}>
              <IonToolbar>
                <IonTitle>{newEntityMessage}</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              <EntityForm onSave={this.newEntitySave} setShowToast={this.setShowToast} {...this.props} />
              {this.toastAlert()}
            </IonContent>
          </IonModal>
          {/*<IonFooter>
            <IonButton expand={'full'} onClick={() => this.showModal()}>
              {newEntityButtonMessage}
            </IonButton>
          </IonFooter>
          */}

            <IonFab vertical="bottom" horizontal="center" slot="fixed">
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonFabButton onClick={() => this.showModal()}>
                                <IonIcon icon={add} />
                            </IonFabButton>
                        </IonCol>
                        { addon &&
                        addon
                        }
                    </IonRow>
                </IonGrid>
            </IonFab>
        </>
      )
    }

  }
/*
  ExpandableList.propTypes = {
    mainResourceURL : React.PropTypes.string.isRequired,
    resourceName: React.PropTypes.string.isRequired, // pilot, glider
  };
  */

export default ExpandableList;