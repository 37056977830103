import {
  IonButton,
  IonButtons, IonChip, IonContent,
  IonHeader, IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage, IonToast,
  IonToolbar
} from "@ionic/react";
import React, {useRef, useState} from "react";
import {closeCircle, menu, refreshCircle} from 'ionicons/icons'
import {concatUrlId, UPDATE_TRACKER_URL} from "../common/constants";
import {loadResource} from "../common/ApiUtils";
import {connect} from "react-redux";
import {TrackerList} from "./TrackerList";


const TrackingComponent = ({day}) => {

  const refContainer = useRef(0);
  const [showToast, setShowToast] = useState(false);
  const [filterValue, setFilterValue] = useState(null);

  let toastMessage = "";
  let refList;

  const updateTracker = async () => {
    const res = await loadResource(concatUrlId(UPDATE_TRACKER_URL, day.replace(/\./g, "-")));
    if (!res.ok) {
      toastMessage = res.message;
      setShowToast(true);
    } else {
      refContainer.current.loadEntityResource();
    }
  };

  const setFilter = (value) => {
    setFilterValue(value);
  };

  const filterFce = (entity) => filterValue ? entity.registration === filterValue : entity;

  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            {/*!group && back &&
            <IonButtons slot="start">
              <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                <IonIcon icon={'arrow-back'}/> Zpět
              </IonButton>
            </IonButtons>
            */}
            <IonButtons slot="start">
                <IonButton slot={'start'} color={'primary'} onClick={() => updateTracker()}>
                  <IonIcon icon={refreshCircle}/>&nbsp;{day}
                </IonButton>
            </IonButtons>
            {filterValue &&
              <IonChip onClick={() => setFilterValue(null)}>
                <IonLabel>{filterValue}</IonLabel>
                <IonIcon icon={closeCircle} />
              </IonChip>
             }
            <IonButtons slot="end">
              <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <TrackerList key={'trackingList'} setFilter={setFilter} filterFce={filterFce} ref={refContainer} loadApiQuery={'sort=date,desc&sort=takeOffTime,desc'}/>
          {showToast &&
          <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={toastMessage}
              duration={5000}
          />
          }
        </IonContent>
      </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    day:state? state.day: ""
  }
};


export const Tracking = connect(
    mapStateToProps,
    null
)(TrackingComponent)