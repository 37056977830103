import React, {Component, useState} from "react";
import {
    IonButton, IonCheckbox,
    IonContent, IonFooter, IonIcon,
    IonInput,
    IonItem,
    IonLabel, IonList
} from "@ionic/react";
import "../common/Common.css"
import { syncOutline } from 'ionicons/icons';
import {errorsMessage, generateTokenKey, passwordChange} from "../common/ApiUtils";
import {hasAdminRole} from "../common/UserUtils";
import "./ChangeAccount.css"

export const ChangeAccountForm = ({entity={id:null, name: "", email: "", username: "",
    sendClosedPdf:false, sendDocumentWarn:false, roles: [], agentApiKey:""}, onSave= f=>f}) =>
{
    const [email, setEmail] = useState(entity.email);
    const [username, setUsername] = useState(entity.username);
    const [getChangePassword, setChangePassword] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState(false);
    const [sendClosedPdf, setSendClosedPdf] = useState(entity.sendClosedPdf);
    const [sendDocumentWarn, setSendDocumentWarn] = useState(entity.sendDocumentWarn);
    const [agentApiKey, setAgentApiKey] = useState(entity.agentApiKey);



    let _entity = {
        id: entity.id,
        name: entity.name,
        email: entity.email,
        username: entity.username,
        changePassword : null,
        password: null,
        passwordAgain: null,
        agentApiKey: entity.agentApiKey
    };

    const getTokenKey = async () => {
        let res = await generateTokenKey();
        if (res) {
            setAgentApiKey(res.tokenKey);
        }
    }

    const showError = () => {
        return (
            <div
                style={{
                    color: "red",
                    padding: 5,
                    paddingLeft: 12,
                    fontSize: "smaller"
                }}
            >
            Hesla nejsou shodná.
            </div>
        );
    };

    const submit = e => {
        e.preventDefault();
        var disabled = !submitEnabled();
        setPasswordMessage(disabled);
        if (disabled) {
            _entity.password.value = "";
            _entity.passwordAgain.value = "";
            return;
        }


        const accountChange = {
            // name: _entity.name.value,
             id : entity.id,
             email: email,
             username: username,
            // active: _entity.active.checked,
            changePassword: getChangePassword,
            password: getChangePassword ? _entity.password.value : null,
            passwordAgain: getChangePassword ? _entity.passwordAgain.value : null,
            sendClosedPdf : sendClosedPdf,
            sendDocumentWarn : sendDocumentWarn,
            agentApiKey : agentApiKey
            //pilotId : entity.pilotRelation
        }
        //console.log(accountChange);
        onSave(accountChange);

    };


    const submitEnabled = () => {
        return !getChangePassword || (_entity.password.value &&
            _entity.passwordAgain.value && _entity.password.value === _entity.passwordAgain.value);
    }

    const passwordChangeCheckbox = e => {
        setChangePassword(e === true);
        _entity.password = null;
    }

    //console.log("has admin:" + hasAdminRole(entity));
    return (
        <form onSubmit={submit}>
            <IonList>
                <IonItem>
                    <IonLabel>{entity.name}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput onIonChange={e => setEmail(e.detail.value)} value={email} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Uživatelské jméno</IonLabel>
                    <IonInput onIonChange={e => setUsername(e.detail.value)} value={username} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zasílat PDF po uzavření dne</IonLabel>
                    <IonCheckbox checked={sendClosedPdf} onIonChange={e => setSendClosedPdf(e.detail.checked)}/>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zasílat upozornění o expiraci dokumentu</IonLabel>
                    <IonCheckbox checked={sendDocumentWarn} onIonChange={e => setSendDocumentWarn(e.detail.checked)}/>
                </IonItem>
                { hasAdminRole(entity) &&
                    <IonItem>
                        <IonLabel position="stacked" >API Klíč Flight office agenta</IonLabel>
                        <IonButton onClick={()=> getTokenKey()} className="custom-icon-align" fill="clear" slot="end">
                            <IonIcon size={'large'} icon={syncOutline} />
                        </IonButton>
                        <IonInput readonly={true}>{agentApiKey}</IonInput>
                    </IonItem>
                }
                <IonItem>
                    <IonLabel position="stacked">Změnit heslo</IonLabel>
                    <IonCheckbox ref={input => _entity.changePassword = input} checked={getChangePassword}
                                 onIonChange={e => passwordChangeCheckbox(e.detail.checked)}/>
                </IonItem>
                {getChangePassword &&
                    <>
                        <IonItem>
                            <IonLabel position="stacked">Heslo</IonLabel>
                            <IonInput type={"password"} ref={input => _entity.password = input} debounce={10} autocomplete={'off'}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Heslo znovu</IonLabel>
                            <IonInput type={"password"} ref={input => _entity.passwordAgain = input} />
                        </IonItem>
                        { passwordMessage && showError() }
                    </>
                }
            </IonList>
            <IonFooter>
                <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
            </IonFooter>
        </form>
    )
};



class ChangeAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entity : props.entity,
        };
        this.onUpdate = this.onUpdate.bind(this);
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.entity._links.self.href !== this.state.entity._links.self.href) {
    //         this.setState({entity :nextProps.entity});
    //     }
    // }



    async onUpdate(changeRequest) {
        //this.closeModal();
        //const {entity} = this.state;
        const {setToastMessage, setShowToast} = this.props;
        const p = await passwordChange(changeRequest);
        if (p.errors) {
            setToastMessage(errorsMessage(p.errors));
            setShowToast(true);
        } else {
            this.setState({entity: p});
            setToastMessage("Uživatelské konto bylo aktualizováno");
            setShowToast(true);
        }

    }

    render() {
        const {entity} = this.state;
        let setEntity = this.props.setEntity;
        if (!setEntity) {
            setEntity = f=>f;
        }
        //console.log(entity);
        //const isActive = entity && active && active.entity && active.entity._links.self.href === entity._links.self.href;
        return (
            <IonContent>
                <ChangeAccountForm entity={entity} onSave={this.onUpdate} />
            </IonContent>
        )
    }
}

export default ChangeAccount;