import React from "react";
import AeroTow from "./AeroTow";
import "./AeroTow.css";
import Reel from "../reel/Reel";
import {connect} from "react-redux";
import OnlyTow from "./OnlyTow";
import {setActiveGlobal} from "../common/ApiUtils";
import {SET_ACTIVE_URL} from "../common/constants";
import Suggest from "./Suggest";


const addTypeIndex = (aeroTowList = []) => {
  let newList = [];
  let aerotowIndex = 0;
  let reelIndex = 0;
  let onlyTowIndex = 0;
  for (var i=0; i< aeroTowList.length; i++) {
    const item = aeroTowList[i];
    if (item.suggest) {
      newList.push({...item, typeIndex: aerotowIndex})
      continue
    }
    if (item.towTask && item.towTask.onlyTow) {
      newList.push({...item, typeIndex: onlyTowIndex});
      onlyTowIndex +=1;
    } else if (item.towingPlane) {
      newList.push({...item, typeIndex: aerotowIndex});
      aerotowIndex +=1;
    } else {
      let reel = {...item, typeIndex: reelIndex};
            newList.push(reel);
      reelIndex +=1;
    }
  }
  return newList;
}

const filterPilotCondition = (tow, filterPilot) => {
  return (tow.first && tow.first.id === filterPilot.id)
      || (tow.second && tow.second.id === filterPilot.id);
}

export const filterList = (aeroTowList, filterPilot, filterGlider, filterTowingPlane, filterTowingPilot) => {
  var list = aeroTowList;
  if (!list) return [];
  if (filterPilot) {
    list = list.filter(item => filterPilotCondition(item, filterPilot));
  }
  if (filterGlider) {
    list = list.filter(item => item.glider && item.glider.id === filterGlider.id);
  }
  if (filterTowingPlane) {
    list = list.filter(item => item.towingPlane && item.towingPlane.id === filterTowingPlane.id);
  }
  if (filterTowingPilot) {
    list = list.filter(item => item.lifter && item.lifter.id === filterTowingPilot.id);
  }
  return list;
};

const incompleteList = (aerotowList, incompleteFilter) => {
  if (incompleteFilter) {
    return aerotowList.filter(item => (item.towingPlane && !item.towingPlaneLanding)
        || (item.glider && !item.gliderLanding) );
  } else {
    return aerotowList;
  }
}

const setActive = (aerotow, active, changeActive) =>{
  let a = {...active};
  a['glider'] = aerotow.glider;
  a['towingPlane'] = aerotow.towingPlane;
  a['first'] = aerotow.first;
  a['second'] = aerotow.second;
  a['lifter'] = aerotow.lifter;
  a['reel'] = aerotow.reel;
  let message = {...a};
  message["type"] = "all";
  setActiveGlobal(SET_ACTIVE_URL, message);
  changeActive(a);

  //console.log("ACTIVE: " + active.toSource());
}

export const AeroTowListCls = ({ aeroTowList= [], timeChange=f=>f, detail=f=>f, taskChange=f=>f, towTaskChange=f=>f,
                                 markId=null, filterPilot, filterGlider, filterTowingPlane, filterTowingPilot,
                                 scrollToPosition=f=>f, incompleteFilter}) =>
{
  return (<>
        {filterList(incompleteList(addTypeIndex(aeroTowList), incompleteFilter),
            filterPilot, filterGlider, filterTowingPlane, filterTowingPilot).reverse().map((aerotow, index) =>
              <table className={'aerotow-table' + (aerotow.suggest? ' suggest': '')} key={index+"_t"}>
                <thead>
                </thead>
                <tbody>
                {aerotow.suggest ?
                    <Suggest {...aerotow} key={index} tow={aerotow} index={index} detail={detail}/>
                  :
                  aerotow.towTask && aerotow.towTask.onlyTow || aerotow.glider == null
                      ?
                      <OnlyTow {...aerotow} index={index} key={index} timeChange={timeChange} detail={detail}
                                          taskChange={taskChange} tow={aerotow} mark={markId === aerotow.id}
                             towTaskChange={towTaskChange} setActive={setActive}/>
                      :
                      aerotow.towingPlane ?
                        <AeroTow {...aerotow} index={index} key={index} timeChange={timeChange} detail={detail}
                               taskChange={taskChange} tow={aerotow} mark={markId === aerotow.id}
                                 towTaskChange={towTaskChange} scrollToPosition={scrollToPosition} setActive={setActive}/>

                        :
                        <Reel {...aerotow} index={index} key={index} timeChange={timeChange} detail={detail}
                            taskChange={taskChange} tow={aerotow} mark={markId === aerotow.id} setActive={setActive}/>
                }
                </tbody>
              </table>
        )}        </>
  )
}


const mapStateToProps = state => {
  return {
    filterPilot : state ? state.filterPilot : null,
    filterGlider : state ? state.filterGlider : null,
    filterTowingPlane : state ? state.filterTowingPlane : null,
    filterTowingPilot : state ? state.filterTowingPilot : null,
    incompleteFilter : state ? state.incompleteFilter : false,
  }
}

const AeroTowList = connect(
    mapStateToProps,
    null
)(AeroTowListCls);


export default AeroTowList;