import {applyMiddleware, createStore} from "redux";
import {flyDay} from "../reduce/reducers";
import {stateData} from "./stateData";


const logger = store => next => action => {
    let result;
    // console.groupCollapsed("dispatching", action.type);
    // console.log("prev state", store.getState());
    // console.log("action", action);
    result = next(action);
    // console.log("next state", store.getState());
    // console.groupEnd();
    return result;
}

const saver = store => next => action => {
    let result = next(action);
    // console.log("Fake store :)",store.getState());
    return result;
}

/*const store = createStore(flyDay, stateData);
export default store;
*/
// Function to merge the initial state with the local storage data
export const mergeInitialState = (initialState) => {
    const activeState = localStorage.getItem('active');
    //console.log("activeState: " + activeState);
    if (activeState) {
        const mergedState = { ...initialState}
        mergedState['active'] = JSON.parse(activeState);
        return mergedState;
    }
    return initialState;
}


const storeFactory = (initialState= stateData) => {
    const ss = mergeInitialState(initialState);
    //console.log(ss);
    return applyMiddleware(logger, saver)(createStore)(
        flyDay,ss)
}



export default storeFactory;




