import React, {Component}  from "react";
import {login, sendForgottenPassword} from "../common/ApiUtils";
import {ACCESS_TOKEN} from "../common/constants";
import {
    IonAlert, IonBadge,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonList, IonPopover,
    IonRow, IonText, IonToast
} from "@ionic/react";
import {withRouter} from "react-router";
import "./LoginForm.css"


class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.userNameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.state = {
            showMessage: false,
            message: null,
            forgottenPassword: false,
            emailOrUser: "",
            showPopover: false,
            eventPopOver: undefined
        }
    }

    showMessageTimeOut(message) {
        this.setState({showMessage: false});
        this.setState({ message : message, showMessage: true});
        setTimeout(() => {
            this.setState({ message : null, showMessage: false});
        }, 10000)
    }



    componentDidMount() {
        setTimeout(() => {
            this.focusName();
        }, 2000);
    }

    focusName() {
        if (this.userNameRef.current)
            this.userNameRef.current.setFocus();
    }

    setForgottenPassword(value) {
        this.setState( {
            emailOrUser: this.userNameRef.current.value,
            forgottenPassword : value
        })
    }


    handleSubmit(event) {
        event.preventDefault();
        let values = {
            usernameOrEmail: this.userNameRef.current.value.trim(),
            password: this.passwordRef.current.value
        };
        if (values.usernameOrEmail) {
            values.usernameOrEmail = values.usernameOrEmail.trim();
        }

        const loginRequest = Object.assign({}, values);
        login(loginRequest)
            .then(response => {
                if (response.accessToken) {
                    localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                    this.props.onLogin(response);
                } else {
                    if (response.status !== null) {
                        this.passwordRef.current.value = "";
                        this.showMessageTimeOut("Nesprávné jméno nebo heslo");
                    } else {
                        console.log(response);
                    }
                }
            }).catch(error => {
            if(error.status === 401) {
                this.passwordRef.current.value = "";
                this.showMessageTimeOut(error.message);
            } else {
                //alert(error);
            }
        });
    }

    render() {
        const {message, forgottenPassword, emailOrUser, showMessage, showPopover, eventPopover} = this.state;
        return (
            <IonGrid className={'mt-100'}>
                <IonRow>
                    <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                        <form onSubmit={this.handleSubmit} className="login-form">
                            <IonList>
                                {/*<IonItemDivider>Default Input with Placeholder</IonItemDivider>*/}
                                <IonItem>
                                    <IonInput autofocus={true} ref={this.userNameRef} placeholder={'Uživatelské jméno nebo email'} name={'usernameOrEmail'} />
                                    <IonBadge slot="end" color="warning" onClick={(e) => {
                                        e.persist();
                                        this.setState({ showPopover: true, eventPopover: e })
                                    }}>?</IonBadge>
                                </IonItem>
                                <IonItem>
                                    <IonInput ref={this.passwordRef} type={'password'} placeholder={'Heslo'} name={'password'} />
                                </IonItem>
                            </IonList>
                            <IonButton expand="block" type="submit" className="ion-no-margin">Přihlásit</IonButton>
                            <input type="submit" className="submit-enter" />
                        </form>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                        <IonButton className={'content-right'} color={'warning'} size={'small'}
                                   onClick={() => this.setForgottenPassword(true)}>
                            Zapomenuté heslo
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonAlert
                    isOpen={forgottenPassword}
                    onDidDismiss={() => this.setForgottenPassword(false)}
                    header={'Obnova zapomenutého hesla'}
                    inputs={[
                        {
                            name: 'email',
                            type: 'text',
                            value: emailOrUser,
                            placeholder: 'E-mail nebo uživatelské jméno'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Zrušit',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {

                            }
                        },
                        {
                            text: 'Odeslat email',
                            handler: async (alertData) => {
                                if (!alertData) return;
                                const res = await sendForgottenPassword({emailOrUser: alertData.email});
                                if (res.message) {
                                    this.showMessageTimeOut(res.message);
                                } else {
                                    this.showMessageTimeOut("Na email " + res.email+" byl odeslán odkaz pro obnovu hesla.");
                                }
                            }
                        }
                    ]}
                />
                <IonPopover className={'popover-content-custom-class'}
                    cssClass='popover-content-custom-class'
                    event={eventPopover}
                    isOpen={showPopover}
                    onDidDismiss={() => this.setState({ showPopover: false, eventPopover: undefined })}
                >
                    <IonContent>
                        <IonText>
                            <h2>Uživatelské jméno nebo email</h2>
                            <p><strong>Uživatelské jméno:</strong> například jméno "Aštar Šeran" odpovídá uživatelskému jménu "astar.seran".<br />
                                Tedy vaše jméno malými písmeny bez diakritiky, mezera je nahrazena tečkou.
                            </p>
                            <p><strong>Email:</strong> Váš email poskytnutý aeroklubu pro komunikaci.</p>
                            <p>Pokud se vám nedaří přihlásit, zkuste obnovu hesla přes <strong>zapomenuté heslo</strong></p>
                        </IonText>
                    </IonContent>
                </IonPopover>
                <IonToast isOpen={showMessage} message={message} />
            </IonGrid>

        );
    }
}

export default withRouter(LoginForm);