import {
  IonButton,
  IonButtons, IonChip, IonContent,
  IonHeader, IonIcon, IonInput, IonItem, IonItemDivider,
  IonLabel, IonList,
  IonMenuButton, IonModal,
  IonPage, IonTitle, IonToast,
  IonToolbar
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import {filter, menu, closeCircle} from 'ionicons/icons'
import {connect} from "react-redux";
import AprsLogbookContent from "./AprsLogbookContent";
import {REST_APRSLOGBOOK_URL} from "../common/constants";
import Flatpickr from "react-flatpickr";
import {formatDate, normalizeLocalDate, reverseDateToLocalDate} from "../common/TimeUtils";


function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};


const AprsLogbookCls = ({day}) => {

  const refContainer = useRef(0);
  const placeRef = useRef(0);
  const identifierRef = useRef(0);
  const [showFilter, setShowFilter] = useState(false);
  const [filterPlace, setFilterPlace] = useLocalStorage("aprsFilterPlace", "");
  const [filterIdentifier, setFilterIdentifier] = useLocalStorage("aprsFilterIdentifier", "");
  const [filterDay, setFilterDay] = useState(null);
  const [inputDay, setInputDay] = useState(null);



  /*const updateTracker = async () => {
    const res = await loadResource(concatUrlId(UPDATE_TRACKER_URL, day.replace(/\./g, "-")));
    if (!res.ok) {
      toastMessage = res.message;
      setShowToast(true);
    } else {
      refContainer.current.loadEntityResource();
    }
  };*/

/*  const setFilter = (value) => {
    setFilterValue(value);
  };
*/
 // const filterFce = (entity) => filterValue ? entity.registration === filterValue : entity;

  const getUrlApiQuery = () => {
    var url = REST_APRSLOGBOOK_URL;
    if (filterPlace) {
      url += "/search/findAllByTakeoffPlaceOrLandingPlace?takeOffPlace="+filterPlace+"&landingPlace=" +filterPlace;
    }
    return url;
  }

  const applyFilter = () => {
    setShowFilter(false);
    setFilterIdentifier(identifierRef.current.value);
    setFilterPlace(placeRef.current.value.toUpperCase());
    setFilterDay(inputDay);
  }

  const onDateChange = (date) => {
    console.log("Date changed: " +date);
    if (date) {
      setInputDay(formatDate(date));
    }
  }


  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
                <IonButton slot={'start'} color={'primary'} onClick={() => setShowFilter(true)}>
                  <IonIcon icon={filter}/> Filtr
                </IonButton>
            </IonButtons>
            {filterIdentifier &&
            <IonChip onClick={() => setFilterIdentifier("")}>
              <IonLabel>{filterIdentifier}</IonLabel>
              <IonIcon icon={closeCircle} />
            </IonChip>
            }
            {filterPlace &&
              <IonChip onClick={() => setFilterPlace("")}>
                <IonLabel>{filterPlace}</IonLabel>
                <IonIcon icon={closeCircle} />
              </IonChip>
             }
            {filterDay &&
            <IonChip onClick={() => setFilterDay(null)}>
              <IonLabel>{filterDay}</IonLabel>
              <IonIcon icon={closeCircle} />
            </IonChip>
            }
            <IonButtons slot="end">
              <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonModal isOpen={showFilter} cssClass='my-custom-class'>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Filter</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setShowFilter(false)}>Zavřít</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                <IonItem>
                  <IonInput value={filterIdentifier} placeholder={"Identifikátor"} ref={identifierRef}></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput value={filterPlace} placeholder={"Místo vzletu nebo přistání"} ref={placeRef}></IonInput>
                </IonItem>
                <IonItem>
                  Datum:
                  <Flatpickr value={filterDay} options={{dateFormat: "d.m.Y", locale: "cs", minDate: "1.7.2021" }}
                             onChange={onDateChange} />
                </IonItem>
              </IonList>
            </IonContent>
            <IonButton onClick={() => {applyFilter()}}>Filtrovat</IonButton>
          </IonModal>
          {/*<AprsLogbookList key={'aprsLogbookList'} ref={refContainer} loadApiQuery={'sort=day,desc&sort=takeOff,desc'}/>*/}
          <AprsLogbookContent
              key={'aprsLogbookList'}
              ref={refContainer}
              urlResource={getUrlApiQuery()}
              place={filterPlace}
              identifier={filterIdentifier}
              day={filterDay}
              setPlace={setFilterPlace}
              setIdentifier={setFilterIdentifier}
              identifier={filterIdentifier}

          />
          {/*showToast &&
          <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={toastMessage}
              duration={5000}
          />
          */}
        </IonContent>
      </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    day:state? state.day: ""
  }
};


export const AprsLogbook = connect(
    mapStateToProps,
    null
)(AprsLogbookCls)