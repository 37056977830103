import C from "../common/ReductTypeConstants"
import {stateData} from "../store/stateData";
import {mergeInitialState} from "../store/Store";

/*
export const aeroTow = (state = false, action) => {
  switch (action.type) {
    case C.FLY_DAY:

  }
}
*/


export const flyDay = (state = stateData, message) => {
  //console.log("-state--");
  //console.log(state);
  state = mergeInitialState(state);
  switch (message.type) {
    case C.FLY_DAY:
      let payload = message.payload;
      const canClose = payload.tows && payload.tows.reduce( (canClose, tow) => (canClose || (!tow.closed && !tow.suggest)), false);
      const canDownloadPdf = payload.tows && payload.tows.length > 0 && !canClose && payload.tows.some((item) => item.closed);
      //alert(payload.day);
      return Object.assign({}, state, {
            day: payload.day,
            tows: payload.tows,
            canClose: canClose,
            canDownloadPdf: canDownloadPdf,
            canReopen: canDownloadPdf && payload.canReopen,
            flyDay : payload
          }
      );
    case C.CLOSE_RESPONSE:
      let closePayload = message.payload;
      return Object.assign({}, state, {
        onCloseMessage: closePayload,
        onCloseMessageChange : true
      });
    case C.REOPEN_RESPONSE:
      return state;
    case C.USER:
      let userPayload = message.payload;
      return Object.assign({}, state, {
        currentUser: userPayload
      });
    case C.LOG_IN:
      return Object.assign({}, state, {
        isAuthenticated : true
      });
    case C.LOG_OUT:
      return Object.assign({}, state, {
        currentUser: null,
        isAuthenticated : false
      });
    case C.FILTER_PILOT:
      return Object.assign({}, state, {
        filterPilot: message.payload,

      });
    case C.FILTER_GLIDER:
      return Object.assign({}, state, {
        filterGlider: message.payload,

      });
    case C.FILTER_TOWING_PLANE:
      return Object.assign({}, state, {
        filterTowingPlane: message.payload,

      });
    case C.FILTER_TOWING_PILOT:
      return Object.assign({}, state, {
        filterTowingPilot: message.payload,

      });
    case C.ACTIVE_CONF:
      localStorage.setItem("active", JSON.stringify(message.payload));
      return Object.assign({}, state, {
        active: message.payload,

      });

    case C.ON_CLOSE_RECEIVE_CHANGE:
      return Object.assign({}, state, {
        onCloseMessageChange: message.payload,
      });

    case C.SWITCH_INCOMPLETE: {
      return Object.assign({}, state, {
        incompleteFilter: !state.incompleteFilter,
      });
    }

    case C.STOMP_CONNECTION: {
      let sc = message.payload;
      return Object.assign({}, state, {
        flyDayStompConnectionStatus :sc,
      });
    }
    case C.GLOBAL_INFO: {
      let di = message.payload;
      return Object.assign({}, state, {
        infoData : di,
      });
    }
  }

};