import React, {Component} from "react";
import {menu, refresh} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

class UtcTime extends Component {

  constructor(props) {
    super(props);
    var d = new Date();
    this.state = {
      date : d,
      diff : props.time ? (props.time - d.getTime()) : 0,
      updatedAt: props.time ? props.time : 0
    }
  }

  componentDidMount() {
    this.intervalID = setInterval(
        () => this.tick(),
        1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    const {diff} = this.state;
    let d = new Date()
    this.setState({
      date: new Date(d.getTime() - diff)
    });
  }

  componentDidUpdate(prevProps) {
    // Check if the relevant prop has changed
    if (this.props.time !== prevProps.time) {
      //console.log('Time changed!');
      var date = new Date();
      var d = date;
      if (this.props.time) {
        d = new Date(this.props.time);
      }
      this.setState( {
        date: d,
        updatedAt: this.props.time,
        diff : date.getTime() - d.getTime(),
      })
    }
  }

  formatedTime() {
    const {date} = this.state;
    let time = {
      hours : "" +date.getUTCHours(),
      minutes : "" +date.getUTCMinutes(),
      seconds : "" +date.getUTCSeconds()
    }


    if (time.hours < 10) {
      time.hours = "0" + time.hours;
    }
    if (time.minutes < 10) {
      time.minutes = "0" + time.minutes
    }
    if (time.seconds < 10) {
      time.seconds = "0" + time.seconds
    }

    return time.hours + ":" + time.minutes + ":" + time.seconds;
  }

  render() {
    const {updatedAt, date} = this.state;
    const {update} = this.props;
    let updatedBefore = date.getTime() - updatedAt;
    return(
        <div onClick={update ? () => update() : () => {}}>
          {this.formatedTime()} <span className={'refresh-wrapper'}>
            <IonIcon icon={refresh}/>
            <small>{updatedAt ? Math.round(updatedBefore / 1000) : '?'}</small>
          </span>
        </div>
    );
  }
}

export default UtcTime;


