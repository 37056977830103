import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import {TimeType} from "../common/constants";
import {addZero, timeOfDateString} from "../common/TimeUtils";
import "./TimeInput.css"
import TimeInputSelection from "./TimeInputSelection";


const currentTime = () => {
  let d = new Date()
  let a = timeOfDateString(d.getUTCHours() + ":" + d.getUTCMinutes());
  return a;
}

export const TimeInput = ({value, type, id, min=null, timeChange= f=>f, disabled=false}) => {

  const [selectedDate, setSelectedDate] = useState(value);
  const [open, setOpen] = useState(false);
/*
  useEffect(() => {
    console.log("input value:" + value);
    console.log("selectedDate updated:" + selectedDate);
  }, [selectedDate, value, open]);
*/

  //* Expect input "v" in format HH:mm
  const onChange = (v) => {
    setOpen(false);
    if (!v) {
      return
    }
    setSelectedDate(v);
    timeChange(id, type, v);
  };

  const changeToNone = () => {
    setOpen(false);
    setSelectedDate(undefined);
    timeChange(id, type, undefined);
  };

  /*
  const refreshValue = () => {
    setSelectedDate(currentTime()); // Update selectedDate with the current time
  };
  */

  const cancel = () => {
    //inputEl.current.value = null;
    setTimeout(() => {setOpen(false);},
        0);
  }

  const confirm = () => {
    //console.log("input value:" + inputValue);
    onChange(selectedDate);
  }

  const getParams = () => {
    return {
      height: 35,
      seperator: true,
      use12Hours: false,
      initialValue: selectedDate ? selectedDate : currentTime(),
      pickerDefaultValue: currentTime(),
      setSelectedDate: setSelectedDate ? setSelectedDate : currentTime()
    }
  };

  const openInput = () => {
    if (!value) {
      setSelectedDate(currentTime());
    }
    setOpen(true);
  }

  return (
        <>
          { disabled ?
            <div className={'show-only-time'}>
              <span className={'non-nullable'}>{value}</span>
            </div>

            :
            <div>
              <IonChip className={'show-only-time'} onClick={() => openInput()}>
              {value ?
                  <span className={'non-nullable' + (open ? ' open': '')}>{value}</span>
                  :
                  <span className={'nullable blinking'}>{
                    type === TimeType.LANDING_GLIDER_TIME ? "přistání" : type === TimeType.LANDING_TOWING_TIME ? "přistání" : "start"}</span>
              }
              </IonChip>
            </div>
          }
          <IonModal isOpen={open} canDismiss={true} onWillDismiss={() => cancel()}
                    cssClass='my-custom-modal'>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start" className={'flex-buttons-space'}>
                  <IonButton onClick={() => cancel() }  fill="solid">Zrušit</IonButton>
                  { type !== TimeType.START_TIME && <IonButton  fill="solid" strong={true} onClick={() => changeToNone()}>
                    Žádný
                  </IonButton>
                  }
                  <IonButton strong={true} onClick={() => confirm()} fill="solid">
                    Uložit
                  </IonButton>
                  {/*<IonButton strong={true} onClick={() => refreshValue()}>
                    refresh
                  </IonButton>
                  */}
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              {open && !disabled &&
                    <TimeInputSelection {...getParams()} />
              }
            </IonContent>
          </IonModal>
        </>
)
}