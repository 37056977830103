import React, {Component} from "react";
import {
  IonButton, IonButtons,
  IonCheckbox, IonContent,
  IonFooter, IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel, IonList,
  IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar
} from "@ionic/react";
import { create, trash } from "ionicons/icons";
import {loadResource, patchResource} from "../common/ApiUtils";
import {ActiveSelect, REST_TASK_GROUP_URL} from "../common/constants";

var taskGroups = [];


var loadTaskGroups = async () => {
  const result = await loadResource(REST_TASK_GROUP_URL);
  if (result && result.status === 401) {
    console.log("Neautorizovany pristup");
    return;
  }
  taskGroups = result ? result._embedded.taskGroup : [];
};

loadTaskGroups();

const findLinkTaskGroupById = elementId => taskGroups.find(element => element.id === elementId)._links.self.href;

export const PilotForm = ({entity = {name: "", surname: "", lifter: false, guest: false, active:true, taskGroupId: null}, type = null,
                            onSave = f => f, setShowToast = g => g} ) => {


  let _pilot = {
    name: null,
    surname: null,
    lifter: null,
    guest : null,
    inActive: null,
    taskGroup: taskGroups.length > 0 && entity.taskGroupId ? findLinkTaskGroupById(entity.taskGroupId) :null
  };

  if (type && type === ActiveSelect.LIFTER) {
    entity.lifter = true;
  }

  const submit = e => {
    e.preventDefault();
    const pilot = {
      name: _pilot.name.value,
      surname: _pilot.surname.value,
      lifter: _pilot.lifter.checked,
      inActive: _pilot.inActive.checked,
      guest: _pilot.guest.checked,
      taskGroup: _pilot.taskGroup

    }
    if (!pilot.surname) {
      setShowToast(true, "Příjmení pilota nesmí být prázdne!");
    } else {
      onSave(pilot);
    }

  };

  const taskGroupChanged = e => {
    if (!e.target.value) return;
    _pilot.taskGroup = findLinkTaskGroupById(e.target.value);
  };

  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Jméno</IonLabel>
            <IonInput ref={input => _pilot.name = input} value={entity.name}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Příjmení</IonLabel>
            <IonInput ref={input => _pilot.surname = input} value={entity.surname}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Vlekař</IonLabel>
            <IonCheckbox ref={input => _pilot.lifter = input} checked={entity.lifter}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Návštěva</IonLabel>
            <IonCheckbox ref={input => _pilot.guest = input} checked={entity.guest}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Neaktivní</IonLabel>
            <IonCheckbox ref={input => _pilot.inActive = input} checked={entity.inActive}/>
          </IonItem>


          <IonItem>
            <IonLabel>Výcvik</IonLabel>
            <IonSelect onIonChange={taskGroupChanged} value={entity.taskGroupId}>
              {taskGroups.map((task, i) => {
                return (
                    <IonSelectOption key={task.id} value={task.id}>
                      {task.groupName}
                    </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonList>
        <IonFooter>
          <IonButton expand={'full'} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )

};

class Pilot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pilot : props.entity,
      editModal: false,
      taskGroupHref: null
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.pilot._links.self.href) {
      this.setState({pilot :nextProps.entity});
    }
  }

  async showModal() {
    this.setState({
      editModal: true,
    })

  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(pilotUpdated) {
    this.closeModal();
    const {pilot} = this.state;
    const p = await patchResource(pilot._links.self.href, pilotUpdated);
    this.setState({pilot: p});
  }

  render() {
    const {pilot, editModal } = this.state;
    const {active, type, setShowToast} = this.props;
    if (type === ActiveSelect.LIFTER && !pilot.lifter) return (<div></div>);
    let isActive = false;
    if (type === ActiveSelect.FIRST) {
      isActive = pilot && active.first && (pilot.id === active.first.id);
    } else if (type === ActiveSelect.SECOND) {
      isActive = pilot && active.second && (pilot.id === active.second.id);
    } else if (type === ActiveSelect.LIFTER) {
      isActive = pilot && active.lifter && (pilot.id === active.lifter.id);
    }
    return (
      <IonItem color={isActive? 'secondary': pilot.inActive ?  'medium' : ''}>
        <IonLabel onClick={() => this.props.setPilot(pilot)}>
          <h2>{pilot.surname}</h2>
          <h3>{pilot.name}</h3>
        </IonLabel>
        <IonButtons slot={'end'}>
          <IonButton size="small" onClick={() => this.props.deleteEntity(pilot)}>
            <IonIcon icon={trash} />
          </IonButton>
          <IonButton size="small" onClick={() =>this.showModal()}>
            <IonIcon icon={create} />
          </IonButton>
        </IonButtons>

        <IonModal isOpen={editModal} backdropDismiss={false}>
          <IonHeader translucent={true}>
            <IonToolbar>
              <IonTitle>Editace pilota</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <PilotForm entity={pilot} onSave={this.onUpdate} setShowToast={setShowToast} />
          </IonContent>
        </IonModal>
      </IonItem>

    )
  }
}

export default Pilot;