import {
    IonButtons,
    IonItem,
    IonLabel,

} from "@ionic/react";
import React from "react";
import {gliderInfo} from "../common/GliderUtils";


export const GliderRO = ({view, active, entity, setGlider=f=>f}) => {
  return (
      <IonItem>
        <IonLabel onClick={() => setGlider(entity)}>
            <h2>{entity.name}{entity.owner ? <span className={'small'}> | {entity.owner}</span> : ""}</h2>
            <h3>{entity.registration}{entity.cn ? <> | {entity.cn}</>  : ""}</h3>
        </IonLabel>
        <IonButtons slot={'end'}>
          {gliderInfo(entity, true)}
        </IonButtons>
      </IonItem>
  )
}