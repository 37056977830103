import React from "react";
import {IonItem, IonLabel, IonList} from "@ionic/react";

export const PlaneBookDayItems = ({items}) =>
    <IonList>
      {items.map((item, index) =>
          <IonItem key={index}>
            <IonLabel>
              <h2>{item.identifier}</h2>
              {item.glider ?
                  <>
                      {item.count  > 0 &&
                        <h3>A: {item.count}x {item.hours}° {item.minutes}'</h3>
                      }
                      {item.winchCount > 0 &&
                        <h3>N: {item.winchCount}x {item.winchHours}° {item.winchMinutes}'</h3>
                      }
                  </>
                  :
                  <h3>{item.count}x {item.hours}° {item.minutes}'</h3>
              }

            </IonLabel>
          </IonItem>
      )}
    </IonList>