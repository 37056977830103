import React from 'react';
import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonPage, IonContent, IonIcon, IonButtons, IonButton
} from '@ionic/react';
import {PlaceList} from "../place/PlaceList";
import {arrowBack, menu} from "ionicons/icons";

const PlacePage = ({back=false} ) => {
  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
              {back &&
              <IonButtons slot="start">
                  <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                      <IonIcon icon={arrowBack}/> Zpět
                  </IonButton>
              </IonButtons>
              }
            <IonTitle>
              Letiště a plochy pro přistání
            </IonTitle>
            <IonButtons slot="end">
              <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <PlaceList />
        </IonContent>
      </IonPage>
  );
}

export default PlacePage;