import React from "react";
import {IonIcon} from "@ionic/react";
import Col from "./Col";
import Row from "./Row";
import {dateToHuman2Year, formatDate, normalizeLocalDate, parseDate, reverseDateToLocalDate} from "../common/TimeUtils";
import {create} from "ionicons/icons";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {filterGlider} from "../reduce/actions";

export const LogbookItem =  ({item, hideParts, setEditItem=f=>f, glider}) =>
{
  const history = useHistory();
  const dispatch = useDispatch();

  const changeFlyDay = (day) => {
      history.push('/flyday/' + reverseDateToLocalDate(normalizeLocalDate(day)));
      dispatch(filterGlider(glider));
  }

  return (
      <tr key={item.id} className={item.incompleteTime ? 'incomplete':''}>
          <Col><span className={'btn-link'} onClick={() => changeFlyDay(item.day)}>{dateToHuman2Year(parseDate(item.day))}</span>
          {item.manual && <IonIcon icon={create} className={'edit-icon'} onClick={() => setEditItem(item)}/>}
        </Col>
          <Col>{item.takeOffPlace ? item.takeOffPlace.name : ''} - {item.landingPlace ? item.landingPlace.name: ''}{item.incompleteTime && <div>Chybí čas přistání</div>}</Col>
        <Col className={!hideParts ? 'hide' : 'show-column'}>&nbsp;</Col>
        <Col className={hideParts ? 'hide-part':''}>?</Col>
        <Col className={hideParts ? 'hide-part':''}>?</Col>
        <Col>{item.winch ? 'N': 'A'}</Col>
        <Col>{item.count}</Col>
        <Col>{item.hours}</Col>
        <Col>{item.minutes}</Col>
        <Col>{item.totalCount}</Col>
        <Col>{item.totalHours}</Col>
        <Col>{item.totalMinutes}</Col>
      </tr>
  )
};