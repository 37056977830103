import React, {Component} from "react";
import {
  IonButton, IonButtons, IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {create, trash} from "ionicons/icons";

export const TaskGroupForm = ({entity={groupName: ""}, onSave=f=>f}) => {
  let _entity = {
    groupName: null,
  };

  const submit = e => {
    e.preventDefault();
    const entity = {
      groupName: _entity.groupName.value,
    };
    onSave(entity);

  };
  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Typ úlohy</IonLabel>
            <IonInput ref={input => _entity.groupName = input} value={entity.groupName} />
          </IonItem>
        </IonList>
        <IonFooter>
          <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )
};



class TaskGroup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity : props.entity,
      editModal: false
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.entity._links.self.href) {
      this.setState({entity :nextProps.entity});
    }
  }

  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(entityToUpdate) {
    this.closeModal();
    const {entity} = this.state;
    const p = await updateResource(entity._links.self.href, entityToUpdate);
    this.setState({entity: p});
  }

  render() {
    const {entity, editModal } = this.state;
    const {active} = this.props;
    const isActive = entity && active && active.entity && active.entity._links.self.href === entity._links.self.href;
    //console.log("edit modal: " + editModal + " glider s:" + glider.surname + " p glider: " +this.props.glider.surname) ;
    return (
        <>
          { entity ?
              <IonItem color={isActive? 'secondary':''}>
                <IonButton onClick={() => this.props.setEntity(entity)} fill={'clear'}>
                  <IonLabel >
                    <h2>{entity.groupName} <IonIcon name={'ios-arrow-forward'} /></h2>
                  </IonLabel>
                </IonButton>
                <IonButtons slot={'end'}>
                  <IonButton size="small" onClick={() => this.props.deleteEntity(entity)}>
                    <IonIcon icon={trash}/>
                  </IonButton>
                  <IonButton size="small" onClick={() => this.showModal()}>
                    <IonIcon icon={create}/>
                  </IonButton>
                </IonButtons>

                <IonModal isOpen={editModal} backdropDismiss={false}>
                  <IonHeader translucent={true}>
                    <IonToolbar>
                      <IonTitle>Editace typu úlohy</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <TaskGroupForm entity={entity} onSave={this.onUpdate}/>
                  </IonContent>
                </IonModal>
              </IonItem>
              :
              <IonItem>
                Chyba
              </IonItem>
          }
        </>

    )
  }
}

export default TaskGroup;