export const findPilotFce = search => function(item) {
  var name = "";
  if (item.name) {
    name = item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  var surname = "";
  if (item.surname) {
    surname = item.surname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  var s = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return name.indexOf(s) === 0 || surname.indexOf(s) === 0;
}

export const findGliderFce = search => function(item) {
  var name = "";
  if (item.name) {
    name = item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  var registration = "";
  if (item.registration) {
    registration = item.registration.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  var cn = "";
  if (item.cn) {
    cn = item.cn.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  var owner = "";
  if (item.owner) {
    owner = item.owner.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  var s = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return name.indexOf(s) === 0 || registration.indexOf(s) > 0 || cn.indexOf(s) === 0 || owner.indexOf(s) === 0;
}

export const findUserFce = findPilotFce;
