import React from "react";
import {reverseDateToHuman} from "./TimeUtils";

export const getCheckResult = (entity, showType=false) => {
    return (
        <div className={'hours-minutes'}>
            {showType && <>{entity.type.name} <br/> </>}
            {entity.restCount && <>zbývá: {entity.restCount} startů <br /> </> }
            {entity.restHours && <> zbývá: {entity.restHours}˚ {entity.restMinutes}' <br /></>}
            {entity.dueDate && <>zbývá: {entity.restDays + ' dnů do '+ reverseDateToHuman(entity.dueDate)} <br /> </>}
        </div>
    );
}


const getInfoFirst = (check, showType=false) => {
    if (!check.checkResults || check.checkResults.length === 0) return;
    return getCheckResult(check.checkResults[0], showType);
}


export const colorSemaphore = (check, firstBad=false) => {
    let cls = "not-available";
    let showFirstBad = false;
    if (check.state === "BAD") {
        cls = "bad";
        showFirstBad = true;
    } else if (check.state === "WARN") {
        cls = "warn";
        showFirstBad = true;
    } else if (check.state === "OK") {
        cls = "ok";
    }
    return (
        <>
            {firstBad && showFirstBad &&
                getInfoFirst(check, true)
            }
            <div className={'semaphore ' + cls}>
            </div>
        </>
    )
}

export const gliderInfo = (glider,firstBad=false)  => {

    return (
        <div className={'glider-info'}>
            {/*hoursAndMinutes(glider.check)*/}
            {colorSemaphore(glider.check, firstBad)}
        </div>
    )
}

const hoursAndMinutes = check => {
    return (
        <>
        {check && check.totalCount &&
            <div className={'hours-minutes'}>
                startů: {check.totalCount} <br/>
                nálet: {check.totalHours}° {check.totalMinutes}'
            </div>
        }
        </>
    )
}