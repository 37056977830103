import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon, IonLabel,
    IonMenuButton,
    IonPage, IonSegment, IonSegmentButton,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";
import {arrowBack, menu} from "ionicons/icons";
import PilotList from "../pilot/PilotList";
import {findPilotFce} from "../common/FindFuctions";
import {REST_PILOT_URL} from "../common/constants";
import {PilotStats} from "../stats/PilotStats";

const PilotsPage = ({back=false} ) => {

    const [viewType, setViewType] = useState("pilots");

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {back &&
                    <IonButtons slot="start">
                        <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                            <IonIcon icon={arrowBack}/> Zpět
                        </IonButton>
                    </IonButtons>
                    }

                    <IonSegment swipeGesture={true} value={viewType}
                                onIonChange={(e) => setViewType(e.detail.value)}>
                        <IonSegmentButton value="pilots">
                            <IonLabel>Piloti</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="stats">
                            <IonLabel>Grafikon</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>

                    <IonButtons slot="end">
                        <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {viewType === "pilots" ?
                <IonContent >
                    <PilotList key={'pilotList'} findFce={findPilotFce} setPilot={f => f} resourceUrl={REST_PILOT_URL} loadApiQuery={'active=false'}/>
                </IonContent>
                :
                <IonContent scrollX={true} scroll-x={true}>
                    <PilotStats />
                </IonContent>
            }
        </IonPage>
)
}

export default PilotsPage;