import {
  IonButtons, IonContent,
  IonHeader, IonIcon,
  IonMenuButton,
  IonPage, IonToast,
  IonToolbar
} from "@ionic/react";

import React, {useState} from "react";
import {menu} from 'ionicons/icons'
import {connect} from "react-redux";


const TrackingComponent = ({day}) => {

  const [showToast, setShowToast] = useState(false);

  let toastMessage = "";

  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {/*<TrackerList key={'trackingList'} setFilter={setFilter} filterFce={filterFce} ref={refContainer} loadApiQeury={'sort=date,desc&sort=takeOffTime,desc'}/>*/}
          {showToast &&
          <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={toastMessage}
              duration={5000}
          />
          }
        </IonContent>
      </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    day:state? state.day: ""
  }
};


export const Settings = connect(
    mapStateToProps,
    null
)(TrackingComponent)