import React, {Component} from "react";
import {
  WS_URL,
} from "../common/constants";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonFooter,
  IonList,
  IonItem,
  IonToast,
  IonMenuButton,
  IonIcon,
  IonButtons,
  IonListHeader, IonSearchbar, IonText
} from "@ionic/react";
import {connect} from "react-redux";
import './aprs.css';
import {menu} from "ionicons/icons";
import { Client } from '@stomp/stompjs';
import {flyDayDispatch} from "../reduce/actions";
import {useSubscription} from "react-stomp-hooks";

function SubscribingComponent({messageDispatch}) {


  const onMessageReceived = (payload) => {
    let message = JSON.parse(payload.body);

    if (message.statusCodeValue === 200) {
      messageDispatch(message.body);
    }
  }

  //const [lastMessage, setLastMessage] = useState("No message received yet");
  useSubscription("/topic/aprs", (payload) => onMessageReceived(payload));
  return (
      <></>
  );
}


class AprsFlights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: false,
      channelId : null,
      aprsFlights : null,
      showToast : false,
      toastMessage: "",
      error: '',
      search:''
    }
    this.searchChange = this.searchChange.bind(this);
    this._messageReceived = this._messageReceived.bind(this);
    this.contentRef = React.createRef();
  }

  _messageReceived = (message) => {
    this.setState({ aprsFlights: message.aprsFlights});
  };

  setShowToast(value, message) {
    this.setState({showToast: value, toastMessage: message});
  }

  toastAlert() {
    const {showToast, toastMessage} = this.state;
    return (
        <IonToast
            isOpen={showToast}
            onDidDismiss={() => this.setShowToast(false, "")}
            message={toastMessage}
            duration={5000}
        />
    )
  }

  closeDayAlert() {
    const {onCloseMessage} = this.props;
    if (!onCloseMessage || !onCloseMessage.error) return;
    return (
        <IonToast
            isOpen={onCloseMessage != null}
            onDidDismiss={() => this.props.clearOnCloseMessage()}
            message={onCloseMessage.message}
            buttons={[
              {
                text: 'Zavřít',
                role: 'cancel',
                handler: () => {
                  //console.log('Cancel clicked');
                }
              }
            ]}
        />
    )
  }

  findFce = search => function(item) {
    if (!search) return true;
    search = search.toUpperCase();
    var delimiterIndex = search.indexOf(':');
    if (delimiterIndex > 0) {
      return item.key.indexOf(search) >= 0;
    } else {
      var keyParts = item.key.split(":");
      return keyParts[1].indexOf(search) >= 0 || (item.identifier && item.identifier.indexOf(search) >= 0);
    }
  }

  searchChange(e) {
    this.setState({search: e.target.value});
  }

  render() {
    const {  aprsFlights, search } = this.state;
    let searchInput;
    return (

        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                APRS - aktivní FLARM, OGN, ICAO
                <SubscribingComponent messageDispatch={this._messageReceived}/>
              </IonTitle>
              <IonButtons slot="end">
                <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>


          <IonContent ref={this.contentRef}>
            <IonList>
              <IonListHeader>
                <IonToolbar>
                  <IonSearchbar onInput={this.searchChange} ref={_input => searchInput = _input}
                                placeholder={'Hledat'}/>
                </IonToolbar>
              </IonListHeader>
              { aprsFlights && aprsFlights.filter(this.findFce(search)).map((entity, i) => (
                <IonItem key={entity.identifier}>
                      <IonLabel className={'small-column'}>
                        { entity.key !== entity.identifier ?
                            <>
                              <span>{entity.identifier}</span>
                              <br/>
                              <small>{entity.key}</small><br/>
                              <small>před: {entity.lastBeacon} s</small>
                            </>
                            :
                            <>
                              <span>{entity.identifier}</span><br/>
                              <small>před: {entity.lastBeacon} s</small>
                            </>
                        }
                      </IonLabel>
                      <IonText className={'small-column right-space'}>
                        <small>LKHS: {Number((entity.distance /1000).toFixed(1))} km</small><br/>
                        <small>azimut: {entity.heading}°</small><br/>
                        <small>pozice: { (entity.latitude).toFixed(5)} {(entity.longtitude).toFixed(5)}</small>
                      </IonText>
                      <IonText className={'small-column'}>
                        <small>v: { Number((entity.speed).toFixed(0))} km/h</small><br/>
                        <small>AGL: { (entity.altitude - entity.agl === -1)  ? "?": Number((entity.agl).toFixed(0))} m</small><br/>
                        <small>MSL: { Number((entity.altitude).toFixed(0))} m</small>
                      </IonText>
                </IonItem>
              ))
              }

            </IonList>
            {this.toastAlert()}
          </IonContent>
          <IonFooter>

          </IonFooter>
        </IonPage>
    )
  }
}


const mapStateToProps = function(state) {
  return {
    // onCloseMessage: state ? state.onCloseMessage : null,
    // onCloseMessageChange : state ? state.onCloseMessageChange : null,
    // filterPilot: state ? state.filterPilot : null,
    // filterGlider : state ? state.filterGlider : null,
    // filterTowingPlane : state ? state.filterTowingPlane : null,
    // canDownloadPdf : state ? state.canDownloadPdf : null,
    // day : state ? state.day: "",
    // active: state ? state.active : {},
    // incompleteFilter: state ? state.incompleteFilter : false
  }
}

const mapDispatchToProps = function (dispatch) {
  return {

  }
}



export default connect(mapStateToProps, mapDispatchToProps)(AprsFlights);