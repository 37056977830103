//const HOST_PORT = "www.lkhs.cz";
//const HOST_PORT = "localhost:8001";
//const HOST_PORT = "localhost:8080";
//const HOST_PORT = "192.168.100.227:8001";
//const HOST_PORT = "192.168.0.106:8001";
//const HOST_PORT = "192.168.50.192:8001";
//const PROTOCOL = "https";
//const PROTOCOL = "http";

if (typeof window.ctxPath === 'undefined') {
  window.ctxPath = '';
}

export const ROLE_ADMIN="ROLE_ADMIN";
export const ROLE_USER="ROLE_USER";
export const ROLE_TECHNICIAN="ROLE_TECHNICIAN";


export const API_BASE_URL = window.ctxPath + '/api';
export const WS_URL = window.ctxPath + '/ws';
//export const WS_URL_WS = 'ws://' + HOST_PORT + '/ws';

export const ACCESS_TOKEN = 'accessToken';

export const REST_PILOT_URL = API_BASE_URL + "/pilot";
export const REST_GLIDER_URL = API_BASE_URL + "/glider";
export const REST_PLACE_URL = API_BASE_URL + "/place";
export const REST_TASK_URL = API_BASE_URL + "/task";
export const REST_TOW_TASK_URL = API_BASE_URL + "/towtask";

export const UPDATE_TRACKER_URL = API_BASE_URL + "/updateTracker";
export const REST_TASK_GROUP_URL = API_BASE_URL + "/taskGroup";

export const REST_TOWINGPLANE_URL = API_BASE_URL + "/towingplane";
export const REST_TRACKER_URL = API_BASE_URL + "/tracker";
export const REST_USERS_URL = API_BASE_URL + "/users";
export const FORGOTTEN_PASSWORD_URL = API_BASE_URL + "/forgottenPassword";
export const APRS_LOGBOOK_FIND = API_BASE_URL + "/aprslogbookfind";
export const FORGOTTEN_PASSWORD_QUERY_URL = API_BASE_URL + "/forgottenPasswordQuery";
export const FORGOTTEN_PASSWORD_CHANGE_URL = API_BASE_URL + "/forgottenPasswordChange";
export const PASSWORD_CHANGE_URL = API_BASE_URL + "/users/change";
export const CREATE_AEROTOW_URL = API_BASE_URL + "/createtow/";
export const REST_AEROTOW_URL = API_BASE_URL + "/tow";
export const REST_AEROTOWS_URL = API_BASE_URL + "/aerotow";
export const START_ACTION_URL = API_BASE_URL + "/start";
export const CLOSE_ACTION_URL = API_BASE_URL + "/close";
export const RECOMPUTE_ACTION_URL = API_BASE_URL + "/recompute";
export const REOPEN_ACTION_URL = API_BASE_URL + "/reopen";
export const DOCUMENT_CHECK_URL = API_BASE_URL + "/gliderDocumentCheck";
export const ACTIVE_DAYS_URL = API_BASE_URL + "/activeDays";
export const DOWNLOAD_PDF_URL = API_BASE_URL + "/pdf";
export const DOWNLOAD_IGC_URL = API_BASE_URL + "/aprslogbookigc";
export const PLANE_LOG_BOOK_URL = API_BASE_URL + "/planeLogBook";
export const PLANE_LOG_BOOK_ITEM_URL = API_BASE_URL + "/planeLogBookItem";
export const REST_APRSLOGBOOK_URL = API_BASE_URL + "/aprslogbook";
export const MANDATORY_TASKS_URL = API_BASE_URL + "/mandatoryTasks";
export const GENERATE_TOKEN_KEY_URL = API_BASE_URL + "/generateTokenKey";

export const REST_APRSLOGBOOK_ID_URL = API_BASE_URL + "/aprslogbookid";
export const REST_DOCUMENT_CHECK_TYPE_URL = API_BASE_URL + "/documentCheckType"

export const SET_ACTIVE_URL = API_BASE_URL + "/setActive";
export const AISVIEW_METAR_URL = API_BASE_URL + "/aisViewMetar";

export const STATE_ACTIVE_SELECT = "ActiveEdit";


export const concatUrlId = (url, id) => {
  return url + "/" +id;
};

export const igcDownloadLink = (logbook) => {
  return concatUrlId(DOWNLOAD_IGC_URL, logbook.id);
}



export const ActiveSelect = Object.freeze({
  HOME:   Symbol("Home"),
  ACTIVE_DETAIL:   Symbol("Aktivni vyber"),
  FIRST:   Symbol("První pilot"),
  SECOND:  Symbol("Druhý pilot"),
  LIFTER:  Symbol("Vlekař"),
  TOWINGPLANE: Symbol("Vlečná"),
  GLIDER : Symbol("Kluzák"),
  TOW_DETAIL : Symbol("Detail"),
  GLIDER_TASK_DETAIL : Symbol("Glider task Detail"),
  TOW_TASK_DETAIL : Symbol("Tow task Detail")
});


export const TimeType = Object.freeze({
  START_TIME: Symbol("Start"),
  LANDING_GLIDER_TIME: Symbol("Landing glider"),
  LANDING_TOWING_TIME: Symbol("Landing towing"),
});

export const PilotType = Object.freeze({
  TOWING_PILOT: Symbol("Towning pilot"),
  GLIDER_FIRST: Symbol("Glider first pilot"),
  GLIDER_SECOND: Symbol("Glider second pilot"),
});


export const DetailType = Object.freeze({
  HOME:   Symbol("Home"),
  TOWING_PILOT: Symbol("Towning pilot"),
  GLIDER_FIRST:  Symbol("Glider first pilot"),
  GLIDER_SECOND: Symbol("Glider second pilot"),
  NOTE: Symbol("Note"),
  GLIDER: Symbol("Glider"),
  TOWING_PLANE: Symbol("Towing plane"),
  TAKEOFF_PLACE: Symbol("Take off place"),
  LANDING_PLACE: Symbol("Landing place"),
});

export const LogbookView = Object.freeze({
  GLIDER_LIST:   Symbol("Glider list"),
  LOGBOOK_ITEMS: Symbol("Logbook items"),
  LOGBOOK_ADD_ITEM: Symbol("Logbook add item"),
  LOGBOOK_EDIT_ITEM: Symbol("Logbook edit item"),
});


export const MonthNamesShortCZ = [
    "Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čvc", "Srp", "Zář", "Říj", "Lis", "Pro"
];

export const MonthNamesCZ = [
  "Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"
];

export const DayNamesShortCZ = [
  "Pon", "Úte", "Stř", "Čtv", "Pát", "Sob", "Ned"
];

export const DayNamesCZ = [
  "Ponělí", "Úterý", "Středa", "Čtvtek", "Pátek", "Sobota", "Neděle"
];


