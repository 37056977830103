import { REST_TRACKER_URL} from "../common/constants";
import ExpandableROList from "../common/ExpandableROList";
import {TrackerRO} from "./TrackerRO";
import {parseDate} from "../common/TimeUtils";
import React from "react";

const convertToHuman = (value) => {
  let date = parseDate(value);
  return <h5>{date.getDate()}.{date.getMonth()+1}. {date.getFullYear()}</h5>;
};

export const TrackerList = ExpandableROList(TrackerRO, REST_TRACKER_URL, "tracker", null, (f)=>f, "date", convertToHuman);

