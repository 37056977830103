import React, { useEffect, useState } from 'react';
import HourWheel from "../timepicker/components/HourWheel";
import MinuteWheel from "../timepicker/components/MinuteWheel";
import "../timepicker/styles/react-ios-time-picker.css"


function TimeInputSelection({
   pickerDefaultValue,
   initialValue,
   height,
   setSelectedDate,
   seperator,
   use12Hours,
   onAmPmChange,

}) {
   const initialTimeValue = use12Hours ? initialValue.slice(0, 5) : initialValue;
   const [value, setValue] = useState(
      initialValue === null ? pickerDefaultValue : initialTimeValue,
   );
   const [hourFormat, setHourFormat] = useState({
      mount: false,
      hourFormat: initialValue.slice(6, 8),
   });

   useEffect(() => {
      setValue(initialValue === null ? pickerDefaultValue : initialTimeValue);
   }, [initialValue]);

   useEffect(() => {
      if (hourFormat.mount) {
         onAmPmChange(hourFormat.hourFormat);
      }
   }, [hourFormat]);

    useEffect(() => {
        //console.log("value has been changed: " + value);
        setSelectedDate(value);
    }, [value]);

   const params = {
      height,
      value,
      setValue,
      use12Hours,
      onAmPmChange,
      setHourFormat,
      hourFormat,
   };


   return (
        <div className="react-ios-time-picker-container" style={{height: `${height * 5 + 40-1}px`}}>
            <div
                className="react-ios-time-picker-selected-overlay"
                style={{
                   top: `${height * 2 + 20}px`,
                   height: `${height}px`,
                }}
            />
            <HourWheel {...params} />
            {seperator && <div className="react-ios-time-picker-colon">:</div>}
            <MinuteWheel {...params} />
         </div>
   );
}

export default TimeInputSelection;
