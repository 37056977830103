import React from "react";

const Row = (props) => {
  let cols = props.children;
  if (props.head) {
    return (<tr>
      {cols.map((col, i) => React.cloneElement(col, {head:true}))}
    </tr>);
  } else {
    return <tr>{props.children}</tr>;
  }
};

export default Row;