import React, {Component} from "react";
import TaskCompose from "./TaskCompose";


class GliderTaskCompose extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tow: props.tow,
    };
  }

  render() {
    const {setTask, back, groupId} = this.props;
    const {tow} = this.state;
    return (
      <TaskCompose setTask={setTask} active={tow.gliderTask} back={back} groupId={groupId}/>
    )
  }
}

export default GliderTaskCompose;