import React, {useEffect, useState} from "react";
import {mandatoryTasks} from "../common/ApiUtils";
import {PilotTasks} from "./PilotTasks";
import {IonContent, IonItem, IonItemGroup, IonLabel, IonList, IonSelect, IonSelectOption} from "@ionic/react";

export const PilotStats = () => {

    const [stats, setStats] = useState(null);
    const [year, setYear] = useState(null);

    const loadStats = async (yearToFetch) => {
        const result = await mandatoryTasks(yearToFetch);
        console.log("result: ");
        if (result) {
            console.log(result);
            setStats(result);
            setYear(result.year);
        }
    }
    useEffect(() => {
        loadStats();
    }, []);

    if (!stats) {
        return <></>;
    }

    const handleSelectChange = (e) => {
        if (e.detail.value && e.detail.value !== year) {
            loadStats(e.detail.value);
        }
    }

    return (
        <>
            <IonList>
                <IonItem>
                    <IonSelect value={year} placeholder="Rok" label="Rok" onIonChange={handleSelectChange}>
                        {stats.years.map( (year) => {
                            return (<IonSelectOption value={year}>{year}</IonSelectOption>)
                        })}
                    </IonSelect>
                </IonItem>
            </IonList>
            <div className={'ion-padding'}>
            {stats.groupPilotStats && Object.keys(stats.groupPilotStats).map((key) => {
                if (!key) return <></>
                let gps = stats.groupPilotStats[key];
                return (
                    <div className={'ion-margin-bottom'}>
                        <h2>{gps.groupName}</h2>
                        <PilotTasks value={gps.pilotTasks}/>
                    </div>
                )
                }
            )
            }
            </div>

        </>

    )
};