import React, {useState} from "react";
import {
    IonButton,
    IonButtons, IonCol,
    IonContent, IonFab, IonFabButton,
    IonHeader,
    IonIcon, IonInput, IonItem, IonLabel, IonList,
    IonMenuButton, IonModal,
    IonPage, IonRow,
    IonTitle, IonToast,
    IonToolbar
} from "@ionic/react";
import {arrowBack, menu, cloudUpload} from "ionicons/icons";
import ChangeAccount from "./ChangeAccount";


const ChangeAccountPage = ({currentUser, back=false}) => {

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <>
                        {back &&
                        <IonButtons slot="start">
                            <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                                <IonIcon icon={arrowBack}/> Zpět
                            </IonButton>
                        </IonButtons>
                        }
                        <IonTitle>
                            Uživatelské konto
                        </IonTitle>
                    </>
                    <IonButtons slot="end">
                        <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ChangeAccount entity={currentUser} setShowToast={setShowToast} setToastMessage={setToastMessage} />
                {showToast &&
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false) }
                    message={toastMessage}
                    duration={5000}
                />
                }

            </IonContent>
        </IonPage>
    );
}

export default ChangeAccountPage;