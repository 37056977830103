

const constants = {
  FLY_DAY: "FLY_DAY",
  CLOSE_RESPONSE: "CLOSE_RESPONSE",
  REOPEN_RESPONSE: "REOPEN_RESPONSE",
  USER: "USER",
  LOG_OUT: "LOG_OUT",
  LOG_IN: "LOG_IN",
  FILTER_PILOT: "FILTER_PILOT",
  FILTER_GLIDER: "FILTER_GLIDER",
  FILTER_TOWING_PLANE: "FILTER_TOWING_PLANE",
  FILTER_TOWING_PILOT: "FILTER_TOWING_PILOT",
  ACTIVE_CONF : "ACTIVE_CONF",
  ON_CLOSE_RECEIVE_CHANGE : "ON_CLOSE_RECEIVE_CHANGE",
  SWITCH_INCOMPLETE : "SWITCH_INCOMPLETE",
  STOMP_CONNECTION : "STOMP_CONNECTION",
  GLOBAL_INFO: "GLOBAL_INFO"
};

export default constants;