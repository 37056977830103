import React from "react";
import {PlaneView} from "./PlaneView";
import {PilotView} from "../pilot/PilotView";
import {ActiveSelect} from "../common/constants";

export const ActiveGlider = ({glider, first, second, activeSelectFce=f=>f}) =>

    <div className={'activeGlider'}>
        <div>
          <div className={'big'} onClick={() => activeSelectFce(ActiveSelect.GLIDER)}>
            {/*Kluzák:&nbsp;*/}
            {glider ?
                <PlaneView plane={glider} />
                :
                "vybrat kluzák"
            }
          </div>
          <div className={'big'}  onClick={() => activeSelectFce(ActiveSelect.FIRST)}>
            {/*PIC: &nbsp;*/}
            1:&nbsp;
            {glider && first ?
                <PilotView pilot={first} />
                :
                 "vybrat"
            }
          </div>
          { glider && glider.twoSeat &&
            <div className={'big'} onClick={() => activeSelectFce(ActiveSelect.SECOND)}>
              {/*Druhý pilot: &nbsp;*/}
              2:&nbsp;
              {second ?
                  <PilotView pilot={second}/>
                  :
                  "Není"
              }
            </div>
          }
        </div>
    </div>