import React from 'react';
import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonPage, IonContent, IonIcon, IonButtons
} from '@ionic/react';
import {menu} from "ionicons/icons";

const About = () => {
    return (
        <IonPage>
            <IonHeader>
              <IonToolbar>
                  <IonButtons slot="end">
                      <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
                  </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <h1>About</h1>
                    </IonCardHeader>
                    <IonCardContent>
                        This is the about page.
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
}

export default About;