import {RECOMPUTE_ACTION_URL} from "../common/constants";
import {minutesToLogTime} from "../common/TimeUtils";

export const PilotTasks = ({value}) => {

    const svgDCircle = () =>
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="5" r="4" fill="black"/>
            <circle cx="10" cy="15" r="4" fill="black"/>
        </svg>

    const svgCircle = () =>
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="15" r="4" fill="black"/>
        </svg>

    const tskSoloClass = (task) => {
        if (task.soloCountDone >= task.soloCount && task.soloMinutesDone >= task.soloMinutes) return 'done';
        else if (task.soloCountDone > 0) return 'part'
        else return 'none';
    }

    const tskDoubleClass = (task) => {
        if (task.doubleCountDone >= task.doubleCount && task.doubleMinutesDone >= task.doubleMinutes) return 'done';
        else if (task.doubleCountDone > 0) return 'part'
        else return 'none';
    }

    if (!value) return <></>

    //let firstTaskProgress = value.find((element) => element.taskProgress !== null);
    let firstTaskProgress = value[0].taskProgress;
    if (!firstTaskProgress) {
        return (
            <div>{value.map((item) => {
                    return ( <>{item.pilot.surname} {item.pilot.name}, </> )
                })}
            </div>
        );
    }
 /*   if (!firstTaskProgress) {
        firstTaskProgress = { "-1" : value[0].noTask };
    }*/
    return (
        <table className={'graficon'}>
            <tr className={'sticky-header'}>
                <th></th>
                {Object.keys(firstTaskProgress).map((key) => {
                    let task = firstTaskProgress[key];
                    return (<th colSpan={task.doubleCount && task.soloCount ? 2 : 0}>{task.name}</th>);
                })
                }
                <th colSpan={2}>Bez úlohy</th>
                <th colSpan={2}>Celkem</th>
            </tr>
            <tr>
                <td></td>
                {Object.keys(firstTaskProgress).map((key) => {
                    let task = firstTaskProgress[key];
                    return (
                        <>
                            {task.doubleCount && <td>{svgDCircle()}</td>}
                            {task.soloCount && <td>{svgCircle()}</td>}
                        </>
                    )
                })}
                <td>{svgDCircle()}</td>
                <td>{svgCircle()}</td>
                <td>{svgDCircle()}</td>
                <td>{svgCircle()}</td>
            </tr>
            {value.map((item) => {
                let taskProgress = item.taskProgress;
                return (
                    <tr>
                        <td>{item.pilot.surname} {item.pilot.name}</td>
                        {taskProgress && Object.keys(taskProgress).map((key) => {
                            let task = taskProgress[key];
                            if (!task) return <></>
                            return (
                                <>
                                    {task.doubleCount &&
                                        <td className={tskDoubleClass(task)}>{task.doubleCountDone}/{task.doubleCount}<br/><small>{task.doubleMinutesDone}'/{task.doubleMinutes}'</small>
                                        </td>}
                                    {task.soloCount &&
                                        <td className={tskSoloClass(task)}>{task.soloCountDone}/{task.soloCount}<br/><small>{task.soloMinutesDone}'/{task.soloMinutes}'</small>
                                        </td>}
                                </>
                            )
                        })}

                        <td>{item.noTask.doubleCountDone}<br/><small>{minutesToLogTime(item.noTask.doubleMinutesDone)}</small>
                        </td>
                        <td>{item.noTask.soloCountDone}<br/><small>{minutesToLogTime(item.noTask.soloMinutesDone)}</small></td>
                        <td>{item.doubleCount}<br/><small>{minutesToLogTime(item.doubleMinutes)}</small></td>
                        <td>{item.soloCount}<br/><small>{minutesToLogTime(item.soloMinutes)}</small></td>
                    </tr>
                )
            })}

        </table>
    )
}