import React, {Component} from "react";
import {
  IonButton, IonButtons, IonCheckbox,
  IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {create, trash} from "ionicons/icons";

export const TowingPlaneForm = ({entity={name: "", registration: "", foreignFlag:false, ognId:"" }, onSave=f=>f}) => {
  let _plane = {
    name: null,
    registration: null,
    foreignFlag: null,
    ognId : null
  };

  const submit = e => {
    e.preventDefault();
    const plane = {
      name: _plane.name.value,
      registration: _plane.registration.value,
      ognId: _plane.ognId.value,
      foreignFlag: _plane.foreignFlag.checked,
    };
    onSave(plane);

  };
  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Název vlečné</IonLabel>
            <IonInput ref={input => _plane.name = input} value={entity.name} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Imatrikulace</IonLabel>
            <IonInput ref={input => _plane.registration = input} value={entity.registration} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Cizí</IonLabel>
            <IonCheckbox ref={input => _plane.foreignFlag = input} checked={entity.foreignFlag} />
          </IonItem>
        </IonList>
        <IonItem>
          <IonLabel position="stacked">OGN id</IonLabel>
          <IonInput ref={input => _plane.ognId = input} value={entity.ognId} />
        </IonItem>
        <IonFooter>
          <IonButton expand={'full'} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )
};



class TowingPlane extends Component {

  constructor(props) {
    super(props);
    this.state = {
      plane : props.entity,
      editModal: false
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.plane._links.self.href) {
      this.setState({plane :nextProps.entity});
    }
  }

  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(entity) {
    this.closeModal();
    const {plane} = this.state;
    const p = await updateResource(plane._links.self.href, entity);
    this.setState({plane: p});
  }

  isActive() {
    const {plane} = this.state;
    const {active} = this.props;
    return plane && active && ((active._links && active._links.self && active._links.self.href === plane._links.self.href) || active.id === plane.id);
  }

  render() {
    const {plane, editModal } = this.state;
    const isActive = this.isActive();
    return (
        <>
          { plane ?
              <IonItem color={isActive? 'secondary':''}>
                <IonLabel onClick={() => this.props.setPlane(plane)}>
                  <h2>{plane.name}</h2>
                  <h3>{plane.registration}</h3>
                </IonLabel>
                <IonButtons slot={'end'}>
                  <IonButton size="small" onClick={() => this.props.deleteEntity(plane)}>
                    <IonIcon icon={trash}/>
                  </IonButton>
                  <IonButton size="small" onClick={() => this.showModal()}>
                    <IonIcon icon={create}/>
                  </IonButton>
                </IonButtons>

                <IonModal isOpen={editModal} backdropDismiss={false}>
                  <IonHeader translucent={true}>
                    <IonToolbar>
                      <IonTitle>Editace vlečné</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <TowingPlaneForm entity={plane} onSave={this.onUpdate}/>
                  </IonContent>
                </IonModal>
              </IonItem>
              :
              <IonItem>
                Chyba
              </IonItem>
          }
        </>

    )
  }
}

export default TowingPlane;