export const stateData = {
    day: "",
    tows: [],
    canClose: false,
    canReopen: false,
    canDownloadPdf : false,
    onCloseMessage : null,
    onCloseMessageChange : false,

    currentUser: null,
    isAuthenticated: true,
    isLoading: false,

    filterPilot: null,
    filterGlider: null,
    filterTowingPlane: null,
    filterTowingPilot: null,
    incompleteFilter: false,

    active : {
        reel: false,
        first: null,
        second: null,
        lifter: null,
        glider: null,
        towTask: null,
        towingPlane: null,
        flyDay: null
    },

    flyDay: null,
    flyDayStompConnectionStatus : false,
    infoData: null
};