import {REST_USERS_URL} from "../common/constants";

import React from "react";
import ExpandableList from "../common/ExpandableList";
import User, {UserForm} from "./User";

export const sortUser = (a, b) => {
    // Check if both users are active or both are inactive
    if (!!a.active === !!b.active) {
        // If both users have the same active status, sort by surname
        let sc = 0;
        if (a.surname && b.surname) {
            sc = a.surname.localeCompare(b.surname, undefined, {numeric: true, sensitivity: 'base'});
        }
        return  sc === 0 ? a.name.localeCompare(b.name) : sc;
    } else if (!a.active) {
        // If 'a' is inactive, place 'a' after 'b'
        return 1;
    } else {
        // If 'b' is inactive, place 'b' after 'a'
        return -1;
    }
};

export const UsersList = ExpandableList(User,
    UserForm, REST_USERS_URL,
    "users", "", "Nový uživatel", "Přidat užvatele",  sortUser);



