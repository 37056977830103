import React, {Component, useState} from "react";
import {
  IonButton, IonButtons, IonCheckbox,
  IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {create, trash} from "ionicons/icons";

export const DocumentCheckTypeForm = ({entity={id: null, name: "", dueDate: false, dueDateWarnBefore: null, startLimit:false, startLimitWarnBefore:null, hourLimit: false, hourLimitWarnBefore: null, other:false}, onSave=f=>f}) => {

  const [dueDate, setDueDate] = useState(entity.dueDate);
  const [dueDateWarnBefore, setDueDateWarnBefore] = useState(entity.dueDateWarnBefore);
  const [startLimit, setStartLimit] = useState(entity.startLimit);
  const [startLimitWarnBefore, setStartLimitWarnBefore] = useState(entity.startLimitWarnBefore);
  const [hourLimit, setHourLimit] = useState(entity.hourLimit);
  const [hourLimitWarnBefore, setHourLimitWarnBefore] = useState(entity.hourLimitWarnBefore);
  const [name, setName] = useState(entity.name);
  const [other, setOther] = useState(entity.other);

  const submit = e => {
    e.preventDefault();
    const documentCheckType = {
      id: entity.id,
      name: name,
      dueDate : dueDate,
      dueDateWarnBefore : dueDateWarnBefore,
      startLimit : startLimit,
      startLimitWarnBefore : startLimitWarnBefore,
      hourLimit : hourLimit,
      hourLimitWarnBefore : hourLimitWarnBefore,
      other : other
    }
    onSave(documentCheckType);

  };

  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Název dokumentu</IonLabel>
            <IonInput onIonChange={(e) => setName(e.detail.value)}  value={name} />
          </IonItem>
          {!other &&
          <>
            <IonItem>
              <IonLabel position="stacked">Lhůta platnost</IonLabel>
              <IonCheckbox onIonChange={(e) => setDueDate(e.detail.checked)} checked={dueDate}/>
            </IonItem>
            {dueDate &&
            <IonItem>
              <IonLabel position="stacked">Varovat dnů před:</IonLabel>
              <IonInput onIonChange={(e) => setDueDateWarnBefore(e.detail.value)} value={dueDateWarnBefore}/>
            </IonItem>
            }
            <IonItem>
              <IonLabel position="stacked">Limit startů</IonLabel>
              <IonCheckbox onIonChange={(e) => setStartLimit(e.detail.checked)} checked={startLimit}/>
            </IonItem>
            {startLimit &&
            <IonItem>
              <IonLabel position="stacked">Varovat startů před:</IonLabel>
              <IonInput onIonChange={(e) => setStartLimitWarnBefore(e.detail.value)} value={startLimitWarnBefore}/>
            </IonItem>
            }
            <IonItem>
              <IonLabel position="stacked">Limit hodin</IonLabel>
              <IonCheckbox onIonChange={(e) => setHourLimit(e.detail.checked)} checked={hourLimit}/>
            </IonItem>
            {hourLimit &&
            <IonItem>
              <IonLabel position="stacked">Varovat hodin před:</IonLabel>
              <IonInput onIonChange={(e) => setHourLimitWarnBefore(e.detail.value)} value={hourLimitWarnBefore}/>
            </IonItem>
            }
          </>
          }
          <IonItem>
            <IonLabel position="stacked">Jiný důvod</IonLabel>
            <IonCheckbox onIonChange={(e) => setOther(e.detail.checked)} checked={other} />
          </IonItem>
        </IonList>
        <IonFooter>
          <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )
};


class DocumentCheckType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      documentCheckType : props.entity,
      editModal: false
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.documentCheckType._links.self.href) {
      this.setState({documentCheckType :nextProps.entity});
    }
  }

  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(entity) {
    this.closeModal();
    const {documentCheckType} = this.state;
    const p = await updateResource(documentCheckType._links.self.href, entity);
    this.setState({documentCheckType: p});
  }

  isActive() {
    const {documentCheckType} = this.state;
    const {active} = this.props;
    return documentCheckType && active && (
        active.id === documentCheckType.id || (active._links && active._links.self &&
        active._links.self.href === documentCheckType._links.self.href)
    );
  }

  render() {
    const {documentCheckType, editModal } = this.state;
    const isActive = this.isActive();
    const setDocumentCheckType = this.props.setDocumentCheckType ? this.props.setDocumentCheckType : f => f;
    return (
        <>
        { documentCheckType ?
              <IonItem color={isActive? 'secondary':''}>
                <IonLabel onClick={() => setDocumentCheckType(documentCheckType)}>
                  <h2>{documentCheckType.name}</h2>
                  <h3>Lhůta platnosti: {documentCheckType.dueDate ? 'Ano': 'Ne'}   Limit startů: {documentCheckType.startLimit ? 'Ano': 'Ne'}<br />
                    Limit hodin: {documentCheckType.hourLimit ? 'Ano': 'Ne'}    Jiný důvod: {documentCheckType.other ? 'Ano': 'Ne'}
                  </h3>
                </IonLabel>
                <IonButtons slot={'end'}>
                  <IonButton size="small" onClick={() => this.props.deleteEntity(documentCheckType)}>
                    <IonIcon icon={trash}/>
                  </IonButton>
                  <IonButton size="small" onClick={() => this.showModal()}>
                    <IonIcon icon={create}/>
                  </IonButton>
                </IonButtons>

                <IonModal isOpen={editModal} backdropDismiss={false}>
                  <IonHeader translucent={true}>
                    <IonToolbar>
                      <IonTitle>Editace typu dokumentu</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <DocumentCheckTypeForm entity={documentCheckType} onSave={this.onUpdate}/>
                  </IonContent>
                </IonModal>
              </IonItem>
              :
              <IonItem>
                Chyba
              </IonItem>
        }
        </>

    )
  }
}

export default DocumentCheckType;