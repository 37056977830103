import React, {Component} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage, IonTitle,
  IonToolbar
} from "@ionic/react";
import {TaskGroupList} from "./TaskGroupList";
import {TaskList} from "./TaskList";
import {getTaskListByGroupUrl, loadResource} from "../common/ApiUtils";
import {REST_TASK_GROUP_URL, REST_TASK_URL} from "../common/constants";
import {arrowBack, menu} from "ionicons/icons";

class TaskCompose extends Component {

  constructor(props) {
    super(props);
    this.state = {
      group: null,
    };
    this.setGroup = this.setGroup.bind(this);
    if (props.groupId) {
      this.loadGroupById(props.groupId);
    }
  }


  async loadGroupById(groupId) {
    if (groupId) {
      const res = await loadResource(REST_TASK_GROUP_URL + "/" + groupId);
      this.setState({
        group: res,
      });
    }
  }


  setGroup(group) {
    //if (group) console.log(group.toSource());
    this.setState( {group : group});
  }


  render() {
    const {group} = this.state;
    const {setTask, back} = this.props;
    const TaskListSpec = group ? TaskList(getTaskListByGroupUrl(group)) : null;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              {!group &&
                <>
                  {back &&
                  <IonButtons slot="start">
                    <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                      <IonIcon icon={arrowBack}/> Zpět
                    </IonButton>
                  </IonButtons>
                  }
                  <IonTitle>
                    Úlohy
                  </IonTitle>
                </>
              }
              {group &&
                  <>
                    <IonButtons slot="start">
                      <IonButton slot={'start'} color={'primary'} onClick={() => this.setGroup(null)}>
                        <IonIcon icon={arrowBack}/> Zpět
                      </IonButton>
                    </IonButtons>
                    <IonTitle>
                      {group.groupName}
                    </IonTitle>
                  </>
              }
              <IonButtons slot="end">
                <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {group ?

                <TaskListSpec setEntity={setTask ? setTask : f=>f} createEntityUrl={REST_TASK_URL} group={group} active={this.props.active} />
                :
                <TaskGroupList setEntity={this.setGroup}/>
            }
          </IonContent>
        </IonPage>
    );
  }
}

export default TaskCompose;