import {ROLE_ADMIN, ROLE_TECHNICIAN, ROLE_USER} from "./constants";
import {returnDownBack} from "ionicons/icons";


export const roleToName = (role) => {
    if (role === ROLE_USER) {
        return "Uživatel";
    } else if (role === ROLE_ADMIN) {
        return "Administrátor";
    } else if (role === ROLE_TECHNICIAN) {
        return "Technik";
    }
}

export const rolesToNames = (roleArray) => {
    if (!roleArray) return "---";
    return roleArray.map( v => { return roleToName(v)} ).join(", ");
}

export const hasAdminRole = (user) => {
    return user.roles && user.roles.includes(ROLE_ADMIN);
}