import React from 'react';
import { IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';

const WeatherModal = ({ infoData, isOpen, onClose }) => {

    const observeDate = new Date(infoData.observeDate);

    const formatDate = date => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return new Intl.DateTimeFormat('default', options).format(date);
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>LKCS metar</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p><strong>QNH: {infoData.qnh}</strong></p>
                <p><strong>Visibility:</strong> {infoData.visibility}</p>
                <p><strong>Wind:</strong> {infoData.wind}</p>
                <p><strong>Observation Date:</strong> {formatDate(observeDate)}</p>
                <p><strong>Temperature:</strong> {infoData.temperature}</p>
                <p><strong>{infoData.sunriseSunsetElement.txt}:</strong> {infoData.sunriseSunset}</p>
                <p>{infoData.metar}</p>
                <IonButton onClick={onClose}>Zavřít</IonButton>
            </IonContent>
        </IonModal>
    );
};

export default WeatherModal;
