import {IonButton, IonButtons, IonDatetime} from "@ionic/react";
import {DayNamesCZ, DayNamesShortCZ, MonthNamesCZ, MonthNamesShortCZ} from "./constants";
import React, {useRef, useState} from "react";
//import {normalizeLocalDate} from "./TimeUtils";
import { format, parseISO } from 'date-fns';

export const DateInput = ({value, id, dateChange= f=>f, max=new Date().getFullYear(), min=new Date().getFullYear()-10}) => {

  const [current, setCurrent] = useState(value);
  const datetime = useRef();

  const changeDateIntern = (val) => {
    //console.log(val);
    const tmp = format(parseISO(val), 'yyyy-MM-dd')
        //normalizeLocalDate(val.year.value+"-"+val.month.value+ "-"+val.day.value);
    setCurrent(tmp);
    dateChange(id, tmp);
  };

  return (
      <IonDatetime value={current}
                   ref={datetime}
                   displayFormat="DD. MMM, YYYY"
                   presentation={'date'}
                   placeholder="Datum"
                   max={max}
                   min={min}

                   monthShortNames={MonthNamesShortCZ}
                   monthNames={MonthNamesCZ}
                   dayNames={DayNamesCZ}
                   dayShortNames={DayNamesShortCZ}
                   //showDefaultButtons={true}
                   onIonChange={(e) => changeDateIntern(e.detail.value)}

      />



  )
}