import React, {Component} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon, IonItem, IonLabel,
  IonList,
  IonToolbar,
  IonPage, IonFooter, IonRow, IonCol, IonTitle, IonModal, IonTextarea
} from "@ionic/react";
import { DetailType, REST_AEROTOW_URL, REST_PILOT_URL, TimeType } from "../common/constants";
import {TimeInput} from "./TimeInput";
import {arrowBack, save, trash} from "ionicons/icons";
import {createTow, deleteResource, updateResource} from "../common/ApiUtils";
import PilotROList from "../pilot/PilotROList";
import {TowingPlaneList} from "../towingPlane/TowingPLaneList";
import {PlaceList} from "../place/PlaceList";
import {findGliderFce, findPilotFce} from "../common/FindFuctions";
import "./AeroTowDetail.css";
import GliderListRO from "../glider/GliderListRO";
import {completePilotName} from "../common/StringUtils";


class AeroTowDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view : DetailType.HOME,
      deleteModal: false
    };
    this.setPilot = this.setPilot.bind(this);
    this.setNote = this.setNote.bind(this);
    this.setGlider = this.setGlider.bind(this);
    this.setTowingPlane = this.setTowingPlane.bind(this);
    this.setPlace = this.setPlace.bind(this);
  }

  setView(type) {
    this.setState( {view :type});
  }

  backToDetail = () =>
      <IonButton slot={'start'} color={'primary'} onClick={() => this.setView(DetailType.HOME)}>
        <IonIcon icon={arrowBack}/> Zpět
      </IonButton>

  setPilot(pilot) {
    const {view} = this.state;
    const {tow} = this.props;
    if (view === DetailType.GLIDER_FIRST) {
      tow.first = pilot;
      //this.setActive('first', pilot);
    } else if (view === DetailType.GLIDER_SECOND) {
      tow.second = pilot;
    } else if (view === DetailType.TOWING_PILOT) {
      tow.lifter = pilot;
    }
    if (tow.id) {
      //alert(tow.id);
      updateResource(REST_AEROTOW_URL, tow);
    }
    this.setView(DetailType.HOME);
  }

  setGlider(glider) {
    const {tow} = this.props;
    tow.glider = glider;
    if (tow.id) {
      updateResource(REST_AEROTOW_URL, tow);
    }
    this.setView(DetailType.HOME);
  }

  setTowingPlane(plane) {
    const {tow} = this.props;
    tow.towingPlane = plane;
    if (tow.id) {
      updateResource(REST_AEROTOW_URL, tow);
    }
    this.setView(DetailType.HOME);
  }

  setNote(value) {
    const {id} = this.props.tow;
    const {changeTow} = this.props;
    if (id) {
      changeTow(id, DetailType.NOTE, value);
    }
    this.setView(DetailType.HOME);
  }

  pilot() {
    const {view} = this.state;
    const {tow} = this.props;
    let selectMessage = "Vyber pilota";
    if (view === DetailType.GLIDER_FIRST) {
      selectMessage = "Vyber prnvího pilota kluzáku";
    } else if (view === DetailType.GLIDER_SECOND) {
      selectMessage = "Vyber druhého pilota kluzáku";
    } else if (view === DetailType.TOWING_PILOT) {
      selectMessage = "Vyber vlekaře";
    }
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                {this.backToDetail()}
                <IonTitle size={'small'}>{selectMessage}</IonTitle>
                {view === DetailType.GLIDER_SECOND &&
                <IonButton color={"primary"} slot={'end'} onClick={() => this.setPilot(null)}>Žádný pilot</IonButton>
                }
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <PilotROList key={'pilotList'} resourceUrl={REST_PILOT_URL} view={view} active={tow} setPilot={this.setPilot} findFce={findPilotFce} loadApiQuery={'active=true'}/>
          </IonContent>
        </IonPage>
    )
  }

  closeModal() {
    this.setState({deleteModal:false});
  }

  showModal() {
    this.setState({deleteModal:true});
  }

  delete() {
    this.props.home();
    deleteResource(REST_AEROTOW_URL+"/"+ this.props.tow.id);
  }

  async createAndHome() {
    const {home = f => f, tow, date} = this.props;
    const res = await createTow(tow, date)
    home();
  }


  home() {
    if (!this.props.tow) return (<></>);
    const {id, towingPlane, lifter, glider, first, second, start, towingPlaneLanding,
      gliderLanding, note, landingPlace, takeOffPlace, towTask, index, suggest} = this.props.tow;
    const {deleteModal} = this.state;
    const {timeChange=f=>f, changeTow=f=>f, home=f=>f } = this.props;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => home()} color={'primary'}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
              </IonButtons>
              <IonButton slot={'end'} onClick={() => this.showModal()}>
                <IonIcon icon={trash}/>
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem>
                <IonLabel>Typ</IonLabel>
                <div>{towingPlane || suggest ?  (towTask.onlyTow ? 'Úloha bez kluzáku': 'Aerovlek' ) : 'Naviják' }</div>
              </IonItem>
              <IonItem>
                <IonLabel>Start</IonLabel>
                <TimeInput value={start} id={id} type={TimeType.START_TIME} timeChange={timeChange} disabled={suggest}/>
              </IonItem>
              {(towingPlane || suggest) &&
              <>
                <IonItem onClick={()=> this.setView(DetailType.TOWING_PLANE)}>
                  <IonLabel>Vlečná</IonLabel>
                  {towingPlane ?
                  <div>
                    {towingPlane.name} {towingPlane.registration}
                  </div>
                      :
                  <div className={'item-required'}>
                    vyplnit!
                  </div>
                  }
                </IonItem>

                <IonItem onClick={()=> this.setView(DetailType.TOWING_PILOT)}>
                  <IonLabel>Vlekař</IonLabel>
                  {lifter ?
                    <div>
                      {completePilotName(lifter)}
                    </div>
                      :
                      <div className={'item-required'}>
                        vyplnit!
                      </div>
                  }
                </IonItem>
                <IonItem>
                  <IonLabel>Přistání vleku</IonLabel>
                  <TimeInput value={towingPlaneLanding} min={start} id={id} type={TimeType.LANDING_TOWING_TIME} timeChange={timeChange} disabled={suggest} />
                </IonItem>
              </>
              }
              {glider ?
                  <>
                    <IonItem onClick={() => this.setView(DetailType.GLIDER)}>
                      <IonLabel>Kluzák</IonLabel>
                      <div>
                        {glider.name} {glider.registration}
                      </div>
                    </IonItem>
                    <IonItem onClick={()=> this.setView(DetailType.GLIDER_FIRST)}>
                      <IonLabel>První pilot</IonLabel>
                      {first ?
                        <div>
                          {completePilotName(first)}
                        </div>
                          :
                        <div className={'item-required'}>
                          vyplnit!
                        </div>
                      }
                    </IonItem>
                    {glider.twoSeat &&
                      <IonItem onClick={() => this.setView(DetailType.GLIDER_SECOND)}>
                        <IonLabel>Druhý pilot</IonLabel>
                        <div>
                          {second ?
                              second.name + ' ' + second.surname
                              :
                              "žádný"
                          }
                        </div>
                      </IonItem>
                    }
                    <IonItem>
                      <IonLabel>Přistání kluzáku</IonLabel>
                      <TimeInput value={gliderLanding} id={id} min={start} type={TimeType.LANDING_GLIDER_TIME} timeChange={timeChange} disabled={suggest} />
                    </IonItem>
                </>
                  :
                  (suggest &&
                    <>
                      <IonItem onClick={() => this.setView(DetailType.GLIDER)}>
                        <IonLabel>Kluzák</IonLabel>
                        <div className={'item-required'}>
                          vyplnit!
                        </div>
                      </IonItem>
                      <IonItem onClick={()=> this.setView(DetailType.GLIDER_FIRST)}>
                        <IonLabel>První pilot</IonLabel>
                        {first ?
                          <div>
                            {completePilotName(first)}
                          </div>
                            :
                            <div className={'item-required'}>
                              vyplnit!
                            </div>
                        }
                      </IonItem>
                      {glider && glider.twoSeat &&
                        <IonItem onClick={() => this.setView(DetailType.GLIDER_SECOND)}>
                          <IonLabel>Druhý pilot</IonLabel>
                          <div>
                            {second ?
                                completePilotName(second)
                                :
                                "žádný"
                            }
                          </div>
                        </IonItem>
                      }
                    </>
                  )
              }
              {!suggest &&
              <>
                <IonItem onClick={() => this.setView(DetailType.NOTE)}>
                  <IonLabel>Poznámka</IonLabel>
                  <span>{note}</span>
                </IonItem>

                <IonItem onClick={() => this.setView(DetailType.TAKEOFF_PLACE)}>
                  <IonLabel>Místo vzletu</IonLabel>
                  <span>{takeOffPlace ? takeOffPlace.name : ""}</span>
                </IonItem>
                <IonItem onClick={() => this.setView(DetailType.LANDING_PLACE)}>
                  <IonLabel>Místo přistání</IonLabel>
                  <span>{landingPlace? landingPlace.name : ""}</span>
                </IonItem>
              </>
              }
            </IonList>
          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                {suggest ?
                    <IonButton expand='full' onClick={() => this.createAndHome()} color={'primary'} disabled={!glider || !first || !towingPlane || !lifter}>
                      <IonIcon icon={save}/> <span>Uložit</span>
                    </IonButton>
                    :
                    <IonButton expand='full' onClick={() => home()} color={'primary'}>
                      <IonIcon icon={arrowBack}/> <span>Zpět</span>
                    </IonButton>

                }
              </IonCol>
            </IonRow>
          </IonFooter>

          <IonModal isOpen={deleteModal} backdropDismiss={false}>
            <IonHeader translucent={true}>
              <IonToolbar>
                <IonTitle>Smazaní letu</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonRow className="ion-align-items-center">
                <IonCol><h2>Opravdu smazat?</h2></IonCol>

              </IonRow>
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <IonButton onClick={() => this.closeModal()}>Ne</IonButton>
                  <IonButton onClick={() => this.delete()}>Ano</IonButton>
                </IonCol>
              </IonRow>
            </IonContent>
          </IonModal>

        </IonPage>
    )
  }

  note() {
    const {note} = this.props.tow;
    let _note;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => this.setView(DetailType.HOME)} color={'primary'}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
              </IonButtons>
              <IonTitle >Poznámka</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonTextarea value={note} ref={input => _note = input} placeholder={'Poznámka'}/>
          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                <IonButton expand='full' onClick={() => this.setNote(_note.value)} color={'primary'}> Uložit</IonButton>

              </IonCol>
            </IonRow>
          </IonFooter>
        </IonPage>
    )
  }

  glider() {
    const {tow} = this.props;
    const {id, glider, index} = this.props.tow;
    let selectMessage = "Vyber kluzák";
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                {this.backToDetail()}
                <IonTitle >{selectMessage}</IonTitle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <GliderListRO key={'gliderList'} setGlider={this.setGlider} active={glider} findFce={findGliderFce}/>
          </IonContent>
        </IonPage>
    )
  }

  place() {
    const {view} = this.state;
    const {takeOffPlace, landingPlace} = this.props.tow;
    let place = landingPlace;
    let selectMessage = "Vyber místo přistání";
    if (view === DetailType.TAKEOFF_PLACE) {
      selectMessage = "Vyber místo vzletu";
      place = takeOffPlace;
    }
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                {this.backToDetail()}
                <IonTitle >{selectMessage}</IonTitle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <PlaceList key={'placeList'} setPlace={this.setPlace} active={place}/>
          </IonContent>
        </IonPage>
    )
  }

  setPlace(place) {
    const {view} = this.state;
    const {tow} = this.props;
    if (view === DetailType.TAKEOFF_PLACE) {
      tow.takeOffPlace = place;
    } else {
      tow.landingPlace = place;
    }

    updateResource(REST_AEROTOW_URL, tow);
    this.setView(DetailType.HOME);

  }

  towingPlane() {
    const {towingPlane} = this.props.tow;
    let selectMessage = "Vyber kluzák";
    return (
            <IonPage>
              <IonHeader>
                <IonToolbar>
                  <IonButtons>
                    {this.backToDetail()}
                    <IonTitle >{selectMessage}</IonTitle>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <TowingPlaneList key={'planeList'} setPlane={this.setTowingPlane} active={towingPlane}/>
              </IonContent>
            </IonPage>
        )
  }

  render() {
    const {view} = this.state;

    if (view === DetailType.HOME) {
      return this.home();
    } else if (view === DetailType.GLIDER_FIRST || view === DetailType.GLIDER_SECOND || view === DetailType.TOWING_PILOT) {
      return this.pilot();
    } else if (view === DetailType.NOTE) {
      return this.note();
    } else if (view === DetailType.GLIDER) {
      return this.glider();
    } else if (view === DetailType.TOWING_PLANE ) {
      return this.towingPlane();
    } else if (view === DetailType.TAKEOFF_PLACE || view === DetailType.LANDING_PLACE) {
      return this.place();
    } else {
      return this.home();
    }
  }
}

export default AeroTowDetail;
