import {DetailType, PilotType} from "../common/constants";
import {
    IonItem,
  IonLabel,

} from "@ionic/react";
import React from "react";


export const PilotRO = ({view, active, entity, setPilot=f=>f}) => {

  if (view === DetailType.TOWING_PILOT && !entity.lifter) return (<div></div>);
  let isActive = false;
  if (view === DetailType.GLIDER_FIRST) {
    isActive = entity && active && active.first && active.first.id === entity.id;
  } else if (view === DetailType.GLIDER_SECOND) {
    isActive = entity && active && active.second && active.second.id === entity.id;
  } else if (view === DetailType.TOWING_PILOT) {
    isActive = entity && active && active.lifter && active.lifter.id === entity.id;
  }
  return (
      <IonItem color={isActive? 'secondary':''}>
        <IonLabel onClick={() => setPilot(entity)}>
          <h2>{entity.surname}</h2>
          <h3>{entity.name}</h3>
        </IonLabel>
      </IonItem>
  )
}