import React, {useEffect, useRef} from "react";
import {IonChip, IonIcon} from "@ionic/react";
import {addCircleOutline} from "ionicons/icons";
import TimeSubtraction from "../common/TimeSubtraction";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {activeDispatch, filterGlider, filterPilot, filterTowingPilot, filterTowingPlane} from "../reduce/actions";
import './suggest.css'


const SuggestCls = ({id, uuid, towingPlane, lifter, glider, first, second, start,
                      towingPlaneLanding, gliderLanding, note, gliderTask, towTask,
                      towTaskChange, closed, index, typeIndex, timeChange=f=>f, detail=f=>f, taskChange=f=>f, tow,
                      filterPilotDispatch, filterGliderDispatch, filterTowingPlaneDispatch, filterTowingPilotDispatch,
                      mark=false, scrollToPosition=f=>f, setActive=f=>f, active, changeActive, canReopen}) =>

{
  let refRow = useRef(null);

  useEffect( () => {
    if (refRow && refRow.current && mark) {
      setTimeout(() => {
        scrollToPosition(refRow.current.offsetTop);
      }, 100);
    }
  },[mark]);

  if (closed) return (<></>);
  return (
  <>
    <tr className={'suggest-row'}>
      <td rowSpan={2}>
          <IonChip onClick={() => detail(uuid)} className={'edit-tow'}>
            Zapsat<IonIcon icon={addCircleOutline}/>
          </IonChip> <span className={'start-time'}>{start}</span>

      </td>


            <td className={'plane-tow'}>
              {towingPlane ?
                  <>
                    {towingPlane.name}<br/>
                    <small>{towingPlane.registration}</small>
                  </>
                  :
                  <>?</>
              }
            </td>
            <td className={'tow-landing'}>
              <div>{towingPlaneLanding}</div>
              <TimeSubtraction t1={start} t2={towingPlaneLanding}/>
            </td>
    </tr>
    <tr className={'suggest-row'}>
      <td className={'plane-glider'}>
        {glider ?
          <>
          {glider.name} <br/>
          <small>{glider.registration}</small>
          </>
            :
            <>?</>
        }
      </td>
      <td className={'glider-landing'}>
        <div>{gliderLanding}</div>
        <TimeSubtraction t1={start} t2={gliderLanding}/>
      </td>
    </tr>

  </>)

}

const mapStateToProps = function(state) {
  return {
    active: state ? state.active : {},
    canReopen: state ? state.canReopen : false,
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    filterPilotDispatch(load) {
      {
        dispatch(filterPilot(load))
      }
    },
    filterGliderDispatch(load) {
      dispatch(filterGlider(load))
    },
    filterTowingPlaneDispatch(load) {
      dispatch(filterTowingPlane(load))
    },
    changeActive(load) {
      dispatch(activeDispatch(load))
    },
    filterTowingPilotDispatch(load) {
      dispatch(filterTowingPilot(load))
    }
  }
}


const Suggest = connect(
    mapStateToProps,
    mapDispatchToProps
)(SuggestCls);


export default withRouter(
    Suggest
);