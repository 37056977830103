import {IonItem, IonLabel} from "@ionic/react";
import React from "react";


export const TrackerRO = ({entity, setFilter=f=>f}) => {
  return (
      <IonItem>
        <IonLabel>
            <h2>{entity.takeOffTime} → {entity.landingTime} &nbsp; | &nbsp;<a onClick={() =>setFilter(entity.registration)}  className={'btn btn-link'}>{entity.registration}</a> <small>{entity.ognId && <span>({entity.ognId})</span>}{entity.airTowAltitude && <span> | {entity.airTowAltitude}m</span>}</small></h2>
          <h4><small>{entity.takeOffPlace} → {entity.landingPlace} &nbsp; | &nbsp;{entity.duration}</small></h4>
        </IonLabel>
      </IonItem>
  )
}