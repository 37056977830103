import VisibleSideMenu from "./menu/SideMenuWithRouter";
import LoginHandler from "./login/LoginHandler";
import {IonPage, IonSplitPane} from "@ionic/react";
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "./login/Login";
import FlyDay from "./flyday/FlyDay";
import {formatDate} from "./common/TimeUtils";
import Logbook from "./logbook/Logbook";
import PlacePage from "./pages/PlacePage";
import TaskPage from "./pages/TaskPage";
import TrackingPage from "./tracking/TrackingPage";
import About from "./pages/About";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import SettingsPage from "./settings/SettingsPage";
import UsersPage from "./users/UsersPage";
import ForgottenPassword from "./login/ForgottenPassword";
import AprsFlights from "./arps/AprsFlights";
import AprsLogbookPage from "./arps/AprsLogbookPage";
import {hasRole} from "./common/ApiUtils";
import {ROLE_ADMIN, WS_URL} from "./common/constants";
import ChangePasswordPage from "./users/ChangeAccountPage";
import GlidersPage from "./pages/GlidersPage";
import PilotsPage from "./pages/PilotsPage";
import {StompSessionProvider} from "react-stomp-hooks";
import C from "./common/ReductTypeConstants";
import {
    activeDispatch,
    filterGlider, filterIncomplete,
    filterPilot,
    filterTowingPilot,
    filterTowingPlane,
    onCloseReceiveFalse, stompClientConnectionDispatch
} from "./reduce/actions";




const MainApp = ({isAuthenticated, currentUser, stompConnectionDispatch}) => {
    //console.log(isAuthenticated);
    //console.log(currentUser);
  return(

    <IonSplitPane contentId="main">
      {isAuthenticated && currentUser && <VisibleSideMenu/> }
      <LoginHandler />
      <IonPage id="main">
        <Switch>
            <Route path="/aprslogbook" component={AprsLogbookPage}/>
          {!isAuthenticated &&
            <>
                <Route path="/forgottenPassword/:uuid" render={(props) => <ForgottenPassword {...props} />}/>
                <Route path="/login" render={(props) => <Login {...props} />}/>
            </>
          }
          {isAuthenticated && currentUser &&<>
            <Route path="/flyday/:dateParam" render={(props) =>
                <StompSessionProvider url={WS_URL}
                                      //debug={STOMP =>  console.log(STOMP)}
                                      heartbeatIncoming={1000}
                                      heartbeatOutgoing={1000}
                                      onConnect={() => {stompConnectionDispatch(true)}}
                                      reconnectDelay={1000}
                                      connectionTimeout={1000}
                                      onWebSocketClose={(state) => stompConnectionDispatch(false)}
                >
                    <FlyDay {...props} />
                </StompSessionProvider> }/>
            <Route exact path="/flyday" render={() => <Redirect to={"/flyday/" + formatDate(Date.now())}/>}/>
            <Route exact path="/" render={() => <Redirect to={"/flyday/" + formatDate(Date.now())}/>}/>
            <Route path="/gliders" component={GlidersPage}/>
            <Route path="/pilots" component={PilotsPage}/>
            <Route path="/aprs" render={() => <StompSessionProvider url={WS_URL}>
                <AprsFlights/>
            </StompSessionProvider>}/>
            <Route path="/logbook" component={Logbook}/>
            <Route path="/place" component={PlacePage}/>
            <Route path="/task" component={TaskPage}/>
            {hasRole(ROLE_ADMIN, currentUser) &&
              <Route path="/users" component={UsersPage}/>
            }
            <Route path="/user" render={(props => <ChangePasswordPage currentUser={currentUser}/> )} />
            <Route path="/tracking" component={TrackingPage}/>
            <Route path="/settings" component={SettingsPage}/>
            <Route path="/about" component={About}/>
            <Route exact path="/login" >
                <Redirect to="/" />
            </Route>

          </>
          }
        </Switch>
      </IonPage>
    </IonSplitPane>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated : state ? state.isAuthenticated : false,
    currentUser : state ? state.currentUser : null
  }
};


const mapDispatchToProps = function (dispatch) {
    return {
        stompConnectionDispatch(connectionStatus) {
            dispatch(stompClientConnectionDispatch(connectionStatus))
        }
    }
}

const Main = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainApp);




export default withRouter(
    Main
);


