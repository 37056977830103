import React, {Component} from "react";
import {loadResource} from "./ApiUtils";
import {
  IonInfiniteScroll, IonInfiniteScrollContent,
  IonLabel,
  IonList,
  IonListHeader,
  IonSearchbar,
  IonToolbar
} from "@ionic/react";

const ExpandableROList = (EntityComponent, mainResourceURL, resourceName,
                        listMessage ="List of items",
                        sortFce=((a,b) => (a.name < b.name) ? 1: -1), headerListProperty=null, headerFce=f=>f, disableInfiniteScroll=false ) =>

    class ExpandableROList extends Component {

      constructor(props) {
        super(props);
        this.state = {
          entityList: [],
          search: "",
          disableInfiniteScroll: disableInfiniteScroll,
          scrollPage: 1
        };
        this.searchChange = this.searchChange.bind(this);
          this.loadEntityResource = this.loadEntityResource.bind(this);
      }

      componentDidMount() {
        this.loadEntityResource();
      }

      async loadEntityResource() {
        const resource = await loadResource(mainResourceURL, this.props.loadApiQuery);
        if (resource) {
          this.setState({
            entityList: resource._embedded[resourceName]
          })
        } else {
          //console.log("Resource of " + mainResourceURL + " return null");
        }
      }

      async loadEntityNext() {
        const {scrollPage, entityList} = this.state;

        var query = this.props.loadApiQuery;
        if (query) {
          query += "&page=" + scrollPage;
        } else {
          query = "page=" + scrollPage;
        }
        const resource = await loadResource(mainResourceURL, query);
        if (resource) {
          //console.log("Resource: " + resource.toSource());
          var list = resource._embedded[resourceName];
          if (list && list.length >= 100) {
            this.setState({
              scrollPage: scrollPage + 1,
              entityList: [...entityList, ...list]
            })
          } else {
            this.setState({
              disableInfiniteScroll: true
            })

          }

        }
      }

      searchChange(e) {
        this.setState({search: e.target.value});
      }

      async pageNext(e) {
        await this.loadEntityNext();
        e.target.complete();
      }


      render() {
        const {entityList, search, disableInfiniteScroll} = this.state;
        const {findFce, filterFce} = this.props;
        let searchInput;
        let lastProperty = null;
        let list = entityList;
        if (filterFce) {
          list = list.filter(filterFce);
        }
        if (findFce) {
          setTimeout(() => {
            if (searchInput) searchInput.setFocus();
          },150);

        }
        return (
            <>
              <IonList>
                {(findFce || listMessage) &&
                  <IonListHeader>
                    <IonToolbar>
                      {listMessage &&
                      <IonLabel>{listMessage}</IonLabel>
                      }
                      {findFce &&
                      <IonSearchbar onInput={this.searchChange} ref={_input => searchInput = _input}/>
                      }
                    </IonToolbar>
                  </IonListHeader>
                }
                {list && findFce && search && list.filter(findFce(search)).sort(sortFce).map((entity, i) => {
                  let showHeader = headerListProperty && lastProperty !== entity[headerListProperty];
                  lastProperty = entity[headerListProperty];
                  return (showHeader) ?
                      <>
                        <IonListHeader key={i+'_header'}>{headerFce(entity[headerListProperty])}</IonListHeader>
                        <EntityComponent key={i} entity={entity} {...this.props} reloadAll={this.loadEntityResource}/>
                      </>
                          :
                      <EntityComponent key={i} entity={entity} {...this.props} />
                })}
                {list && (!findFce || findFce && !search) && list.sort(sortFce).map((entity, i) => {
                  let showHeader = headerListProperty && lastProperty !== entity[headerListProperty];
                  lastProperty = entity[headerListProperty];
                  return (showHeader) ?
                      <>
                        <IonListHeader key={i+'_header'}>{headerFce(entity[headerListProperty])}</IonListHeader>
                        <EntityComponent key={i} entity={entity} {...this.props} reloadAll={this.loadEntityResource}/>
                      </>
                      :
                      <EntityComponent key={i} entity={entity} {...this.props} reloadAll={this.loadEntityResource}/>
                })}

              </IonList>
              <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll}
                  onIonInfinite={(e) => this.pageNext(e)}>
                <IonInfiniteScrollContent loadingText="Načítám další ...">
                </IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
        )
      }

    }

export default ExpandableROList;