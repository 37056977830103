import React from "react";

export const PlaneView = ({plane}) =>
    <span className={'planeView'}>
      {plane &&
      <span>
        <strong>{plane.name}</strong>
        <span className={'small'}> ({plane.registration})</span>
      </span>
      }
    </span>