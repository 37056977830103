import React, {Component, useState} from "react";
import {
  IonAlert,
  IonButton, IonButtons, IonCol,
  IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonRow, IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {
  concatUrlId,
  LogbookView,
  PLANE_LOG_BOOK_ITEM_URL, PLANE_LOG_BOOK_URL
} from "../common/constants";
import GliderListRO from "../glider/GliderListRO";
import {
  createResource,
  deleteResource, getPlaneLogBookItems,
  loadResource,
  patchResource,
  sendForgottenPassword,
  sendRecompute
} from "../common/ApiUtils";
import {DateInput} from "../common/DateInput";
import {LogbookItem} from "./LogbookItem";
import Col from "./Col";
import Row from "./Row";
import "./Logbook.css";
import {arrowBack, menu, refresh, refreshCircle} from "ionicons/icons";
import {findGliderFce} from "../common/FindFuctions";
import C from "../common/ReductTypeConstants";
import {filterGlider, filterPilot} from "../reduce/actions";



function sortLogBookItem(a,b) {
  var r = a.day.localeCompare(b.day);
  if (r !== 0) return r;
  return a.position - b.position;
}

function removeBrackets(url) {
  var index = url.indexOf('{');
  if (index > 0) {
    return url.substring(0, index);
  } else {
    return url
  }
}

export const LogbookForm = ({entity = {day: "", totalCount: 0, totalHours: 0, totalMinutes: 0}, onSave = f => f, remove = f =>f}) => {


  const [alert, setAlert] = useState(false);

  let _logbook = {
    day: entity.day,
    totalCount: entity.totalCount,
    totalHours: entity.totalHours,
    totalMinutes: entity.totalMinutes
  };

  const changeDate = (id, value) => {
    //alert(value);
    _logbook.day = value;
  };


  const submit = e => {
    e.preventDefault();
    const logbook = {
      day: _logbook.day,
      totalCount: parseInt(_logbook.totalCount.value),
      totalHours: parseInt(_logbook.totalHours.value),
      totalMinutes: parseInt(_logbook.totalMinutes.value)

    };
    onSave(logbook);
  };

  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel>Datum</IonLabel>
            <DateInput value={entity.day} dateChange={changeDate} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Celkový počet startů</IonLabel>
            <IonInput required={true} type={'number'}  ref={input => _logbook.totalCount = input} value={entity.totalCount}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Celkový počet hodin</IonLabel>
            <IonInput required={true} type={'number'} ref={input => _logbook.totalHours = input} value={entity.totalHours}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Celkový počet minut</IonLabel>
            <IonInput required={true} type={'number'} max={59} ref={input => _logbook.totalMinutes = input} value={entity.totalMinutes}/>
          </IonItem>

        </IonList>
        <div className={'flex-sb side-space'}>
          <IonButton type={"submit"}>Uložit</IonButton>
          {entity.id &&
            <IonButton color={'danger'} type={"button"} onClick={() => setAlert(true)}>Smazat</IonButton>
          }
        </div>
        <IonAlert
            isOpen={alert}
            onDidDismiss={() => setAlert(false)}
            header={'Opravdu chcete smazat tento záznam?'}
            buttons={[
              {
                text: 'Zrušit',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => setAlert(false)
              },
              {
                text: 'Opravdu smazat',
                handler: () => remove(entity)
              }
            ]}
        />

      </form>
  )

};


class Logbook extends Component {

  constructor(props) {
    super(props);
    this.state = {
      glider : null,
      view: LogbookView.GLIDER_LIST,
      editModal : false,
      items: [],
      editItem: null,
      hideParts: true
    };
    this.setGlider = this.setGlider.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.edit = this.edit.bind(this);
  }

  setHideParts(value) {
    this.setState({
      hideParts: value
    })
  }


  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }


  setGlider(entity) {
    this.loadItems(entity);
  }

  async loadItems(glider) {
    if (glider.foreignFlag) {
      this.loadItemsForeign(glider);
    } else {
      this.loadItemsNoForeign(glider);
    }
  }

  async loadItemsNoForeign(glider) {
    let items = [];

    let res = await loadResource(glider._links.logBook.href);
    if (res) {
      res = await loadResource(removeBrackets(res._links.items.href));
      if (res._embedded) {
        items = res._embedded.planeLogBookItems;
        if (items && items.length > 0) {
          items.sort(sortLogBookItem);
        }
        //alert(items.toSource());
      }
    }

    this.setState({
          view: LogbookView.LOGBOOK_ITEMS,
          glider: glider,
          items: items
        }
    );
  }

  async loadItemsForeign(glider) {
    let items = [];

    let res = await getPlaneLogBookItems(glider.id);
    if (res) {
      items = res;
      if (items && items.length > 0) {
        items.sort(sortLogBookItem);
      }
      console.log(items);
    }

    this.setState({
          view: LogbookView.LOGBOOK_ITEMS,
          glider: glider,
          items: items
        }
    );
  }


  viewLogBookItems() {
    this.setState({
      view: LogbookView.LOGBOOK_ITEMS
    });
  }

  viewGliderList() {
    this.setState({
      view: LogbookView.GLIDER_LIST
    });
  }

  viewAddItem() {
    this.setState({
      editItem : null,
      view: LogbookView.LOGBOOK_ADD_ITEM
    });
  }

  gliderList() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              Deník kluzáku
            </IonTitle>
            <IonButtons slot="end">
              <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <GliderListRO setGlider={this.setGlider} /**filterFce={(item) => !item.foreignFlag}*/ findFce={findGliderFce} />
        </IonContent>
      </IonPage>
    );
  }

  createItem(logbookItem) {
    const {glider} = this.state;
    const item = {...logbookItem};
    item.manual = true;
    item.logBook = concatUrlId(PLANE_LOG_BOOK_URL, glider.logBookId);
    createResource(PLANE_LOG_BOOK_ITEM_URL, item);
    this.loadItems(glider);
  };

  edit(item) {
    this.setState({editItem:item, view: LogbookView.LOGBOOK_EDIT_ITEM});
  }

  async recompute() {
    const {glider} = this.state;
    await sendRecompute(glider);
    this.loadItems(glider);
  }

  logbookItems() {
    const {glider, items, hideParts} = this.state;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => this.viewGliderList()} color={'primary'}><IonIcon icon={arrowBack}/> Zpět</IonButton>
              </IonButtons>
              <IonLabel>
                Deník kluzáku {glider.name} ({glider.registration})
                &nbsp;<IonIcon color={'primary'} icon={refreshCircle} onClick={() => this.recompute()}/>
              </IonLabel>
              <IonButtons slot="end">
                <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollX={true} scroll-x={true}>

              <table className={'logbook-table ' + (hideParts? ' partly-hidden': '')}>
                <thead>
                  <Row head={true}>
                    <Col key={1} rowSpan={'3'}>Datum</Col>
                    <Col key={2} rowSpan={'3'}>Místo (a čas UTC)<br/>Vzletu - Přistání</Col>
                    <Col key={3} rowSpan={'3'} className={!hideParts ? 'hide' : 'show-column'}>
                      <IonButton fill={'clear'} onClick={() => this.setHideParts(false)} className={'btn'}>
                      ...
                      </IonButton>
                    </Col>
                    <Col key={5} className={hideParts ? 'hide-part':''} rowSpan={'3'}>Posádka<br/>Velitel - Člen(ové)</Col>
                    <Col key={6} className={hideParts ? 'hide-part':''} rowSpan={'3'}>Druh letu / úkol / trať</Col>
                    <Col key={7} rowSpan={'3'}>Způsob<br/>vzletu</Col>
                    <Col key={8} colSpan={'6'}>Počet letů a doba provozu</Col>
                  </Row>
                  <Row head={true}>
                    <Col key={9} colSpan={'3'}>Za den</Col>
                    <Col key={10} colSpan={'3'}>Celkem</Col>
                  </Row>
                  <Row head={true}>
                    <Col key={11}>Letů</Col>
                    <Col key={12}>Hodin</Col>
                    <Col key={13}>Minut</Col>
                    <Col key={14}>Letů</Col>
                    <Col key={15}>Hodin</Col>
                    <Col key={16}>Minut</Col>
                  </Row>
                </thead>
                <tbody>
                    { items.map((item, i) =>
                        <LogbookItem key={i} item={item} hideParts={hideParts} setEditItem={this.edit} glider={glider} />
                      )
                     }
                </tbody>
              </table>

          </IonContent>
          <IonFooter>
              <IonButton expand='full' onClick={() => this.viewAddItem()} color={'primary'}>  Přidat záznam
              </IonButton>

          </IonFooter>
        </IonPage>
    )
  }

  async updateItem(item) {
    const {glider, editItem} = this.state;
    item.manual = true;
    item.id = editItem.id;
    item.logBook = concatUrlId(PLANE_LOG_BOOK_URL, glider.logBookId);
    await patchResource(concatUrlId(PLANE_LOG_BOOK_ITEM_URL, editItem.id), item);
    this.loadItems(glider);
  }

  async removeItem(item) {
    const {glider, editItem} = this.state;
    await deleteResource(concatUrlId(PLANE_LOG_BOOK_ITEM_URL, editItem.id), item);
    this.loadItems(glider);
  }

  addItem() {
    const {view, editItem} = this.state;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => this.viewLogBookItems()} color={'primary'}>
                  <IonIcon icon={arrowBack}/> Zpět
                </IonButton>
                <IonButtons slot="end">
                  <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
                </IonButtons>
              </IonButtons>

            </IonToolbar>
          </IonHeader>
          <IonContent>
            {view === LogbookView.LOGBOOK_EDIT_ITEM &&
              <LogbookForm entity={editItem} onSave={this.updateItem} remove={this.removeItem}/>
            }
            {view !== LogbookView.LOGBOOK_EDIT_ITEM &&
            <LogbookForm onSave={this.createItem}/>
            }

          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                <IonButton expand='full' onClick={() => this.viewLogBookItems()} color={'primary'}>
                  <IonIcon  icon={arrowBack}/> Zpět</IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>

          <IonModal isOpen={this.state.editModal} backdropDismiss={false}>
            <IonHeader translucent={true}>
              <IonToolbar>
                <IonTitle>Smazaní letu</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              Opravdu smazat?
              <IonButton onClick={() => this.closeModal()}>Ne</IonButton>
              <IonButton onClick={() => this.delete()}>Ano</IonButton>
            </IonContent>
          </IonModal>
        </IonPage>
    )
  }



  render() {
    const {view} = this.state;
    if (view === LogbookView.GLIDER_LIST) {
      return this.gliderList();
    } else if (view === LogbookView.LOGBOOK_ITEMS) {
      return this.logbookItems();
    } else if (view === LogbookView.LOGBOOK_ADD_ITEM) {
      return this.addItem();
    } else if (view === LogbookView.LOGBOOK_EDIT_ITEM) {
      return this.addItem();
    }
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    filterGliderDispatch(load) {
      dispatch(filterGlider(load))
    }
  }
}

export default Logbook;