import {func} from "prop-types";
import React from "react";

export const convTime = function(input) {
  return input + ":00";
  //return input.hour.text + ":" + input.minute.text + ":00"
}

export function addZero(a) {
  if (a.length < 2) {
    return ('0' + a);
  } else {
    return a;
  }
}

export const timeOfDateString = function(date) {
  if (typeof date === "string") {
    if (date.length === 5) return date;
    if (date.indexOf('T') > 0) {
      var parsed = date.substring(date.indexOf('T')+1);
      var index = parsed.indexOf('-');
      if (index > 0) {
        parsed = parsed.substring(0, index);
      }
      index = parsed.indexOf('.');
      if (index > 0) {
        date = parsed.substring(0, index);
      }
    }
    var arr = date.split(':');
    if (arr.length === 2 || arr.length === 3) {
      var resArr = [];
      resArr.push(addZero(arr[0]));
      resArr.push(addZero(arr[1]));
      return resArr.join(':');
    }

  }
  if (typeof date.getMonth !== 'function') {
    date = new Date(date);
  }
  return addZero(""+date.getHours()) + ":" + addZero(""+date.getMinutes());
}

export const normalizeLocalDate = function (input) {
  var arr = input.split('-');
  if (arr.length > 1) {
    arr[1] = addZero(arr[1]);
  }
  if (arr.length > 2) {
    arr[2] = addZero(arr[2]);
  }
  return arr.join('-');
};

export function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();


    month = addZero(month);

    day = addZero(day);

  return [day,  month, year].join('-');
}

export function reverseDateToLocalDate(date) {
  if (date) {
    var arr = date.split('-');
    if (arr.length === 3) {
      var localArr = [arr[2],arr[1],arr[0]];
      return localArr.join('-');
    }
  }
  return false;
}

export function reverseDateToHuman(date) {
  return reverseDateToLocalDate(date).replaceAll("-", ".");
}

export function parseDate(date) {
  if (date) {
    var arr = date.split('-');
    if (arr.length === 3) {
      var parsed = new Date(Date.UTC(arr[0], parseInt(arr[1])-1, parseInt(arr[2])));
      if (isNaN(parsed.getTime())) {
        return false;
      } else {
        return parsed;
      }
    }
  }
  return false;
}

export function dateToHumanNoYear(date) {
  var month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

  return day + "." + month+".";
}

export function dateToHuman2Year(date) {
  var month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

  return day + "." + month+". " + year;
}

export function fromHumanToLocalDate(humanDate) {
  var arr = humanDate.split('.');
  if (arr.length === 3) {
    var localDateArr = [ arr[2], arr[1], arr[0] ];
    return localDateArr.join('-');
  } else {
    console.log("Cannot convert date: " + humanDate);
    return "";
  }
}

export function timeArrayCorrection(arr) {
  if (arr[1] < 0) {
    arr[0] -=1;
    arr[1] += 60;
  } else if (arr[1] >= 60) {
    arr[0] +=1;
    arr[1] -= 60;
  }
  return arr;
}

export function subtractTimeArr(a, b) {
  var aArr = a.split(":");
  var bArr = b.split(":");
  var cArr = [parseInt(aArr[0]) - parseInt(bArr[0]), parseInt(aArr[1]) - parseInt(bArr[1])];
  cArr = timeArrayCorrection(cArr);
  return cArr;
}

export function addTimeArr(a, b) {
  var aArr = a.split(":");
  var cArr = [parseInt(aArr[0]) + b[0], parseInt(aArr[1]) + b[1]];
  cArr = timeArrayCorrection(cArr);
  return cArr;
}

export function subtractTimeString(a, b) {
  var cArr = subtractTimeArr(a, b);
  return [cArr[0], addZero(''+cArr[1])].join(":");
}

export function timeArrToString(arr) {
  return [arr[0], addZero(''+arr[1])].join(":");
}

export const dayConvertToHuman = (value) => {
  let date = parseDate(value);
  return date.getDate() + ". "+  (date.getMonth()+1)+ ". "+ date.getFullYear();
};

export const timestampToHuman = (value) => {
  if (!value) return value;
  let date = new Date(value);
  return date.getDate() + ". "+  (date.getMonth()+1)+ ". "+ date.getFullYear() + " "+  timeOfDateString(date);
};

export const dateStringJavaToDate = (value) => {
  var i = value.lastIndexOf('.');
  if ( i>0 ) {
    return new Date(value.substring(0, i) + "Z");
  } else {
    return null;
  }
}

export const minutesToLogTime = (minutes) => {
  return Math.floor(minutes/60) + '° ' + (minutes % 60) + '\'';
}

export class fromHumanToReversedLocalDate {
}