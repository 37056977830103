import React from "react";
import {subtractTimeString} from "./TimeUtils";

export const TimeSubtraction =  ({t2 , t1}) =>
{
  if (!t2 || !t1) return <></>;
  return (
      <span>
        {subtractTimeString(t2, t1)}
      </span>
  )
};

export default TimeSubtraction;