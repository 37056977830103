import React from 'react';
import './Login.css';
import LoginForm from "./LoginForm";
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";

import {connect} from "react-redux";
import {onLogin} from "../reduce/actions";
import {withRouter} from "react-router";


const Login = ({dispatchOnLogin, history})  => {

    const onLoginHandle = (response) => {
      dispatchOnLogin(response);
     // alert("----- TO HP")
      history.push("/");
      window.location.reload();
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Přihlášení</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={'login-background'}>
                <LoginForm onLogin={onLoginHandle} />
            </IonContent>
        </IonPage>
    )

};



const mapDispatchToProps = function (dispatch) {
  return {
    dispatchOnLogin(load) {
      {
        dispatch(onLogin(load))
      }
    },
  }
}



export default connect(null, mapDispatchToProps)(withRouter(Login));
