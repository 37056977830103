import React, {Component, useEffect, useState} from "react";
import {
  IonButton, IonButtons, IonCheckbox,
  IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonModal, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {loadResource, updateResource} from "../common/ApiUtils";
import {create, trash} from "ionicons/icons";
import {gliderInfo} from "../common/GliderUtils";
import {REST_PILOT_URL} from "../common/constants";
import {completePilotName} from "../common/StringUtils";
import {sortPilots} from "../pilot/PilotUtils";

export const GliderForm = ({entity={name: "", registration: "", cn: "", twoSeat:false, foreignFlag: false, ognId: "", description: "", ownerId: null}, onSave=f=>f}) => {

  const [name, setName] = useState(entity.name);
  const [registration, setRegistration] = useState(entity.registration);
  const [ognId, setOgnId] = useState(entity.ognId);
  const [cn, setCn] = useState(entity.cn);
  const [description, setDescription] = useState(entity.description);
  const [foreign, setForeign] = useState(entity.foreignFlag);
  const [ownerId, setOwnerId] = useState(entity.ownerId);
  const [twoSeat, setTwoSeat] = useState(entity.twoSeat);
  const [pilots, setPilots] = useState([]);

  const submit = e => {
    e.preventDefault();
    const glider = {
      name: name,
      registration: registration,
      cn : cn,
      description : description,
      twoSeat : twoSeat,
      foreignFlag : foreign,
      ognId: ognId,
      ownerId: ownerId
    }
    onSave(glider);
  };

  const loadPilots = async () => {
      const resource = await loadResource(REST_PILOT_URL, null);
      if (resource) {
        let list = resource._embedded["pilot"];
        if (list) {
          list = list.sort(sortPilots);
        }
        setPilots(list);
      }
      return resource;
  }

  useEffect(() => {
    if (foreign && pilots.length === 0) {
      loadPilots();
    }
  }, [foreign]);



  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Název kluzáku</IonLabel>
            <IonInput onIonChange={(e) => setName(e.detail.value)} value={name} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Imatrikulace</IonLabel>
            <IonInput onIonChange={(e) => setRegistration(e.detail.value)} value={registration} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Startovní znak</IonLabel>
            <IonInput onIonChange={(e) => setCn(e.detail.value)} value={cn} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Popis</IonLabel>
            <IonInput onIonChange={(e) => setDescription(e.detail.value)} value={description} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Dvousedadlový</IonLabel>
            <IonCheckbox onIonChange={(e) => setTwoSeat(e.detail.checked)} checked={twoSeat} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Cizí</IonLabel>
            <IonCheckbox onIonChange={(e) => setForeign(e.detail.checked)} checked={foreign} />
          </IonItem>
          {foreign && <IonItem>
            <IonLabel>Vlastník</IonLabel>
            <IonSelect onIonChange={(e) => setOwnerId(e.target.value)} value={ownerId}>
              <IonSelectOption value="null">Žádný</IonSelectOption>
              { pilots.map((pilot, i) => {
                return (
                    <IonSelectOption key={pilot.id} value={pilot.id}>
                      {completePilotName(pilot)}
                    </IonSelectOption>
                );
              }) }
            </IonSelect>
          </IonItem>
          }
          <IonItem>
            <IonLabel position="stacked">OGN id</IonLabel>
            <IonInput onIonChange={(e) => setOgnId(e.detail.value)} value={ognId} />
          </IonItem>
        </IonList>
        <IonFooter>
          <IonButton expand={'full'} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )
};



class Glider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      glider : props.entity,
      editModal: false
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.glider._links.self.href) {
      this.setState({glider :nextProps.entity});
    }
  }

  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(entity) {
    this.closeModal();
    const {glider} = this.state;
    const p = await updateResource(glider._links.self.href, entity);
    this.setState({glider: p});
  }

  isActive() {
    const {glider} = this.state;
    const {active} = this.props;
    return glider && active && (
        active.id === glider.id || (active._links && active._links.self &&
        active._links.self.href === glider._links.self.href)
    );
  }

  render() {
    const {glider, editModal } = this.state;
    const isActive = this.isActive();
    const setGlider = this.props.setGlider ? this.props.setGlider : f => f;
    return (
        <>
        { glider ?
              <IonItem color={isActive? 'secondary':''}>
                <IonLabel onClick={() => setGlider(glider)}>
                  <h2>{glider.name}{glider.owner ? <span className={'small'}>{glider.owner}</span>:''}</h2>
                  <h3>{glider.registration}{glider.cn ? " ("+ glider.cn + ")": ""}</h3>
                </IonLabel>
                <IonButtons slot={'end'}>
                  {gliderInfo(glider)}
                  <IonButton size="small" onClick={() => this.props.deleteEntity(glider)}>
                    <IonIcon icon={trash}/>
                  </IonButton>
                  <IonButton size="small" onClick={() => this.showModal()}>
                    <IonIcon icon={create}/>
                  </IonButton>
                </IonButtons>

                <IonModal isOpen={editModal} backdropDismiss={false}>
                  <IonHeader translucent={true}>
                    <IonToolbar>
                      <IonTitle>Editace kluzáku</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <GliderForm entity={glider} onSave={this.onUpdate}/>
                  </IonContent>
                </IonModal>
              </IonItem>
              :
              <IonItem>
                Chyba
              </IonItem>
        }
        </>

    )
  }
}

export default Glider;