import React, {Component} from "react";
import {
    documentCheckDelete, documentCheckUpdate,
    hasRole, loadResource,
} from "../common/ApiUtils";
import {
    IonAlert,
    IonButton,
    IonButtons, IonCheckbox, IonContent, IonFooter,
    IonHeader,
    IonIcon, IonInput,
    IonItem,
    IonLabel, IonList,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {arrowBack, create, trash} from "ionicons/icons";

import {connect} from "react-redux";
import {ROLE_ADMIN, ROLE_TECHNICIAN} from "../common/constants";
import {colorSemaphore, getCheckResult} from "../common/GliderUtils";
import {DocumentCheckTypeList} from "./DocumentCheckTypeList";
import {DateInput} from "../common/DateInput";
import "./GliderDocumentCheck.css";

export const GliderDocumentsCheckForm = ({documentCheck={id: null, name:"", dueDate:"", type:null, count: "", hours:"", minutes:"", stopped:false,stoppedReason:"" },
                                        close, showTypes, type}) => {

    let _documentCheck = {
        id: null,
        name: null,
        dueDate: null,
        count : null,
        hours: null,
        minutes: null,
        stopped: null,
        stoppedReason: null,
    };

    const submit = e => {
        e.preventDefault();
        const gliderDocumentCheck = {
            id: documentCheck.id,
            typeId: type ? type.id : (documentCheck.type ? documentCheck.type.id : null),
            name: _documentCheck.name ? _documentCheck.name.value : null,
            dueDate: _documentCheck.dueDate,
            count : _documentCheck.count ? _documentCheck.count.value : null,
            hours : _documentCheck.hours ? _documentCheck.hours.value : null,
            minutes : _documentCheck.minutes ? _documentCheck.minutes.value : null,
            stopped: _documentCheck.stopped ? _documentCheck.stopped.checked : null,
            stoppedReason: _documentCheck.stoppedReason ? _documentCheck.stoppedReason.value : null
        }
        onSave(gliderDocumentCheck);

    };

    const changeDate = (id, value) => {
        //alert(value);
        _documentCheck.dueDate = value;
    };

    const onSave = async (doc) => {
        console.log(doc);
        let resp = await documentCheckUpdate(doc);
        // FIXME resp error
        close(true);
    }


    const onlyInteger = e => {
        let pattern = /^([0-9])$/;
        let result = pattern.test(e.key);
        if (!result) e.preventDefault();
    }

    return (
        <form onSubmit={submit}>
            <IonList>
                <IonItem onClick={() => !type ? showTypes() : f=>f}>
                    <IonLabel position="stacked">Typ dokumentu</IonLabel>
                    <h3>{ type ? type.name: 'Vybrat'}</h3>
                </IonItem>
                {type &&
                <>
                    {type.dueDate &&
                    <IonItem>
                        <IonLabel position="stacked">Lhůta platnosti</IonLabel>
                        <DateInput value={documentCheck.dueDate} dateChange={changeDate} format={'DD MM YYYY'}
                                   min={new Date().getFullYear() - 1} max={new Date().getFullYear() + 10}/>
                    </IonItem>
                    }
                    {type.startLimit &&
                    <IonItem>
                        <IonLabel position="stacked">Počet startů</IonLabel>
                        <IonInput onKeyPress={onlyInteger} inputMode="numeric" type={'number'}
                                  ref={input => _documentCheck.count = input} value={documentCheck.count}/>
                    </IonItem>
                    }
                    {type.hourLimit &&
                    <>
                        <IonItem>
                            <IonLabel position="stacked">Počet hodin</IonLabel>
                            <IonInput onKeyPress={onlyInteger} type={'number'} ref={input => _documentCheck.hours = input}
                                      value={documentCheck.hours}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Počet minut</IonLabel>
                            <IonInput onKeyPress={onlyInteger} min={"0"} max={"59"} type={'number'}
                                      ref={input => _documentCheck.minutes = input} value={documentCheck.minutes}/>
                        </IonItem>
                    </>
                    }
                    {type.other &&
                    <>
                        <IonItem>
                            <IonLabel position="stacked">Provoz pozastaven</IonLabel>
                            <IonCheckbox ref={input => _documentCheck.stopped = input} checked={documentCheck.stopped}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Důvod pozastavení</IonLabel>
                            <IonInput ref={input => _documentCheck.stoppedReason = input}
                                      value={documentCheck.stoppedReason}/>
                        </IonItem>
                    </>
                    }
                </>
                }

            </IonList>

            <IonFooter>
                <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
            </IonFooter>

        </form>
    )
};


class GliderDocumentCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entity : props.entity,
            editModal: false,
            editDocumentModal: false,

            checkDocument: null,
            typesModal : false,
            documentType : null,
            alert: false
        };
        this.closeModal = this.closeModal.bind(this);

        this.showEditDocument = this.showEditDocument.bind(this);
        this.closeDocumentModal = this.closeDocumentModal.bind(this);
        this.toggleTypes = this.toggleTypes.bind(this);
        this.setDocumentCheckType = this.setDocumentCheckType.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        //console.log("nextProps: " + nextProps.toSource());
        if (nextProps.entity._links.self.href !== this.state.entity._links.self.href) {
            this.setState({entity :nextProps.entity});
        }
    }

    showModal() {
        this.setState({
            checkDocument: null,
            documentType : null,
            editModal: true
        })
    }


    closeDocumentModal(success) {
        this.setState({
            editDocumentModal: false,
        });
        if (success) {
           this.reloadEntity();
        }
    }

    closeModal() {
        this.setState({
            editModal: false
        })
    }

   async reloadEntity() {

        const {entity} = this.state;
        var resp = await loadResource(entity._links.self.href);
        this.setState({entity : resp});
        await this.props.reloadAll();
    }

    async deleteDocument(doc) {
        this.setState( {checkDocument: null});
        await documentCheckDelete(doc);
        this.reloadEntity();
    }

    showEditDocument(doc) {
        this.setState({checkDocument: doc, editDocumentModal: true,
            documentType : doc ? doc.type : null});
    }

    toggleTypes() {
        this.setState({typesModal: !this.state.typesModal} );
    }

    setDocumentCheckType(type) {
        console.log(type);
        this.setState({documentType : type, typesModal : false});
    }

    documentItem() {
        const {entity, editDocumentModal,typesModal,documentType, alert } = this.state;
        var checkDocument = this.state.checkDocument ? this.state.checkDocument: {};
        const {currentUser} = this.props;
        const techAdminRole = hasRole(ROLE_ADMIN, currentUser) || hasRole(ROLE_TECHNICIAN, currentUser);
        return (
            <>
                { entity ?
                    <IonItem>
                        <IonLabel className={'flex-b200'}>
                            {entity.type.name}<br/>
                            {entity.gliderName} ({entity.gliderRegistration})
                        </IonLabel>
                        <div className={'flex-columns justify-end'}>
                            {getCheckResult(entity)}
                            {colorSemaphore(entity)}
                        </div>
                        <IonButtons slot={'end'}>

                            {techAdminRole &&
                            <>
                                <IonButton size="small" onClick={() => this.setState({alert: true})}>
                                    <IonIcon icon={trash}/>
                                </IonButton>
                                <IonButton size="small" onClick={() => this.showEditDocument(entity)}>
                                    <IonIcon icon={create}/>
                                </IonButton>
                            </>
                            }
                        </IonButtons>
                        <IonModal isOpen={editDocumentModal} backdropDismiss={false}>
                            {!typesModal ?
                                <>
                                    <IonHeader translucent={true}>
                                        <IonToolbar>
                                            <IonTitle>Dokumenty {entity.gliderName + ' (' + entity.gliderRegistration + ')'}</IonTitle>
                                            <IonButtons slot="end">
                                                <IonButton
                                                    onClick={() => this.closeDocumentModal(false)}>Zavřít
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent>
                                        <GliderDocumentsCheckForm documentCheck={checkDocument}
                                                             close={this.closeDocumentModal}
                                                             showTypes={this.toggleTypes} type={documentType}/>
                                    </IonContent>
                                </>
                                :
                                <>
                                    <IonHeader translucent={true}>
                                        <IonToolbar>
                                            <IonTitle>Typ dokumentu</IonTitle>
                                            <IonButtons slot="start">
                                                <IonButton onClick={() => this.toggleTypes()} color={'primary'}>
                                                    <IonIcon icon={arrowBack}/> Zpět
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent>
                                        <DocumentCheckTypeList setDocumentCheckType={this.setDocumentCheckType}/>
                                    </IonContent>
                                </>

                            }
                        </IonModal>
                        <IonAlert
                            isOpen={alert}
                            onDidDismiss={() => this.setState({ alert: false})}
                            header={'Opravdu chcete smazat tento záznam?'}
                            buttons={[
                                {
                                    text: 'Zrušit',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: () => this.setState({ alert: false})
                                },
                                {
                                    text: 'Opravdu smazat',
                                    handler: () => this.deleteDocument(entity)
                                }
                            ]}
                        />
                    </IonItem>
                    :
                    <IonItem>
                        Chyba
                    </IonItem>
                }
            </>

        )
    }


    render() {
        return this.documentItem();
    }
}

const mapStateToProps = function(state) {
    return {
        currentUser: state ? state.currentUser : null,
    }
}

export default connect(mapStateToProps, null)(GliderDocumentCheck);
