import React, {Component, useEffect, useState} from "react";
import {
  getSimpleFlyDay, pilotById,
  sendStartAction,
  setActiveGlobal,
  updateResource
} from "../common/ApiUtils";
import {
  REST_PILOT_URL,
  WS_URL,
  ActiveSelect,
  SET_ACTIVE_URL, TimeType, REST_AEROTOW_URL, PilotType, DetailType, concatUrlId, DOWNLOAD_PDF_URL, WS_URL_WS
} from "../common/constants";
import {
  IonButton,
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonLabel,
  IonFooter,
  IonList,
  IonItem,
  IonToast,
  IonMenuButton,
  IonIcon,
  IonButtons,
  IonToggle, IonChip, IonGrid
} from "@ionic/react";
import {ActiveGlider} from "./ActiveGlider";
import PilotList from "../pilot/PilotList";
import {TowingPlaneList} from "../towingPlane/TowingPLaneList";
import UtcTime from "../common/UtcTime";
import AeroTowList, {filterList} from "../aerotow/AeroTowList";
import "./FlyDay.css"
import AeroTowDetail from "../aerotow/AeroTowDetail";
import {
  convTime, dateToHuman2Year,
  parseDate, reverseDateToHuman,
  reverseDateToLocalDate,
  subtractTimeArr,
  timeArrayCorrection, timeArrToString
} from "../common/TimeUtils";
import CustomCalendar from "./CustomCalendar";
import C from "../common/ReductTypeConstants"
import {connect} from "react-redux";
import {
  activeDispatch,
  filterGlider,
  filterIncomplete,
  filterPilot, filterTowingPilot,
  filterTowingPlane, flyDayDispatch,
  onCloseReceiveFalse, stompClientConnectionDispatch
} from "../reduce/actions";
import {findGliderFce, findPilotFce} from "../common/FindFuctions";
import {PlaneBookDayItems} from "./PlaneBookDayItems";
import {PlaneView} from "./PlaneView";
import {PilotView} from "../pilot/PilotView";
import {arrowBack, chevronForwardOutline, closeCircle, funnel, menu} from "ionicons/icons";
import GliderTaskCompose from "../task/GliderTaskCompose";
import TowTaskCompose from "../towtask/TowTaskCompose";
import {completePilotName} from "../common/StringUtils";
import GliderListRO from "../glider/GliderListRO";
import PilotROList from "../pilot/PilotROList";
import {useSubscription} from "react-stomp-hooks";

const mapDispatchToProps = function (dispatch) {
  return {
    changeDayDispatch(load) {
      {
        dispatch(load)
      }
    },
    clearOnCloseMessage() {
      {
        dispatch({type: C.CLOSE_RESPONSE, payload: null})
      }
    },
    filterPilotDispatch(load) {
      dispatch(filterPilot(load))
    },
    filterGliderDispatch(load) {
      dispatch(filterGlider(load))
    },
    filterTowingPlaneDispatch(load) {
      dispatch(filterTowingPlane(load))
    },
    filterTowingPilotDispatch(load) {
      dispatch(filterTowingPilot(load))
    },
    changeActive(load) {
      dispatch(activeDispatch(load))
    },
    closeReceiveChange() {
      dispatch(onCloseReceiveFalse())
    },
    switchIncompleteDispatch() {
      dispatch(filterIncomplete());
    },
    stompConnectionDispatch(connectionStatus) {
      dispatch(stompClientConnectionDispatch(connectionStatus))
    }
  }
}


function SubscribingComponentCls({dayDate, changeDayDispatch, stompConnectionDispatch}) {


    const onMessageReceived = (payload) => {
    //const { dateParam } = this.props.match.params;
    //console.log("dateParam: " + dateParam);
    //let dayDate = dateParam.replaceAll('-', '.');


    //console.log("receive message: " + payload);
    //console.log(payload);
    let message = JSON.parse(payload.body);

    if (message.statusCodeValue === 200) {
      let flyDayMessage = message.body;
      if (dayDate !== flyDayMessage.day) return;
      //setLastMessage(message.day);
      console.log("STOMP message for date " + flyDayMessage.day);
      changeDayDispatch(flyDayDispatch(flyDayMessage));
    }
  }

  //const [lastMessage, setLastMessage] = useState("No message received yet");
  useSubscription("/topic/flyday", (payload) => onMessageReceived(payload));
  return (
      <></>
  );
}

const SubscribingComponent = connect(null, mapDispatchToProps)(SubscribingComponentCls);

class FlyDay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: false,
      username: '',
      channelConnected: false,
      error: '',
      flyDay :null,
      type: ActiveSelect.HOME,
      detailId: -1,
      detailUUID: "",
      showToast : false,
      toastMessage: "",
      openDate: false,
      editTow: null,
      lastPositionY:0
    }

    this.activeSelectFce = this.activeSelectFce.bind(this);
    this.setPilot = this.setPilot.bind(this);
    this.setGlider = this.setGlider.bind(this);
    this.setTowingPlane = this.setTowingPlane.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.showDetail = this.showDetail.bind(this);
    this.selectHome = this.selectHome.bind(this);
    this.changeTow = this.changeTow.bind(this);
    this.gliderTaskChange = this.gliderTaskChange.bind(this);
    this.towTaskChange = this.towTaskChange.bind(this);
    this.setGliderTask = this.setGliderTask.bind(this);
    this.setTowTask = this.setTowTask.bind(this);
    this.scrollToPosition = this.scrollToPosition.bind(this);
    this.getFlyDay = this.getFlyDay.bind(this);

    this.contentRef = React.createRef();
  }



  // getChildContext() {
  //   return {
  //     store : this.props.store
  //   }
  // }

  backToDetail = () =>
    <IonButton slot={'start'} color={'primary'} onClick={() => this.selectActiveDetail()}>
      <IonIcon icon={arrowBack}/> Zpět
    </IonButton>

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.getFlyDay();
    }
  };


  componentDidMount() {
    //this.unsubscribe = this.props.store.subscribe(()=> this.forceUpdate());
    const { dateParam } = this.props.match.params;
    let date = reverseDateToHuman(dateParam);
    if (!date) {
      this.props.history.push('/');
      return;
    } else {

    }
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.getFlyDay();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    //this.unsubscribe();
  }

  componentDidUpdate(prevProps) {
    const { dateParam } = this.props.match.params;
    const {flyDay, channelConnected} = this.state;
    //console.log("lastDateParam: " + prevProps.match.params.dateParam + " dateParam: " + dateParam);
    var changed = prevProps.match.params.dateParam !== dateParam
    if (changed) {
      this.getFlyDay();
      return;
    }

    if (this.props.flyDay && (!flyDay || this.props.flyDay.time !== flyDay.time))   {
      //console.log(this.props.flyDay)
      this.setState({flyDay : this.props.flyDay});
    } else if (this.props.flyDayStompConnectionStatus !=null && this.props.flyDayStompConnectionStatus !== channelConnected) {
      this.setState({ channelConnected: this.props.flyDayStompConnectionStatus});
    }
  }

  setActive(type, value) {
    let active = {...this.props.active };
    active[type] = value;
    let message = {type: type};
    message[type] = value;
    setActiveGlobal(SET_ACTIVE_URL, message);
    this.props.changeActive(active);
  }

  // simple async getter
  async getFlyDay() {
    const { dateParam } = this.props.match.params;
    //let date = parseDate(dateParam);
    ///console.log("dateParam ----> : " + date);
    var response = await getSimpleFlyDay(dateParam);
    this._messageReceived(response);
  };

  showDetail(uuid) {
    //console.log("Detail uuid: "+ uuid);
    this.setState({detailUUID: uuid});
    this.selectTowDetail();
  };

  // stomp multicast
  onMessageReceived = (payload) => {
    //console.log("receive message: " + payload);
    var message = JSON.parse(payload.body);
    //console.log("STOMP message: " + message);
    if (message.statusCodeValue === 200) {
      this._messageReceived(message.body);
    }
  };

  _messageReceived = (fd) => {
    if (!fd) {
      console.log("Error, not flyday");
      return
    }
    const {flyDay} = this.state;
    const {changeDayDispatch, changeActive, active, day} = this.props;

    const { dateParam } = this.props.match.params;
    //console.log("dateParam: " + dateParam);
    let dayDate = dateParam.replaceAll('-', '.');
    //console.log("current: " + dayDate);
    //console.log("incoming:" + fd.day);
    //console.log("day: " + day);
    if (!flyDay || fd.day === dayDate) {
     /* let fdTest = {...fd};
      fdTest.tows = [];
      this.setState({ flyDay: fdTest, active : fdTest.active});
      */
      if (!active.glider && fd.active) {
        changeDayDispatch(flyDayDispatch(fd));
        //this.setState({ flyDay: fd});
        changeActive(fd.active);
      } else {
        changeDayDispatch(flyDayDispatch(fd));
        //console.log("setState fd");
        //this.setState({ flyDay: fd});
      }
    }
  };


  setPilot(pilot) {
    const {type} = this.state;
    if (type === ActiveSelect.FIRST) {
      this.setActive('first', pilot);
    } else if (type === ActiveSelect.SECOND) {
      this.setActive('second', pilot);
    } else if (type === ActiveSelect.LIFTER) {
      this.setActive('lifter', pilot);
    }
    //this.selectHome();
    this.selectActiveDetail();
  }

  async setGlider(glider) {
    this.setActive('glider', glider);
    if (glider && glider.foreignFlag && glider.ownerId) {
      this.setActive('first', await pilotById(glider.ownerId));
    }
    //this.selectHome();
    this.selectActiveDetail();
  }

  setTowingPlane(plane) {
    this.setActive('towingPlane', plane);
    //this.selectHome();
    this.selectActiveDetail();
  }


  activeSelectFce(activeSelectType) {
    this.setState({type:activeSelectType});
  }


  selectHome() {
    this.activeSelectFce(ActiveSelect.HOME);
    if (this.state.lastPositionY > 0) {
      setTimeout(() => {
        this.scrollToPosition(this.state.lastPositionY);
        this.setState({lastPositionY : 0})
      }, 100);

    }
  }

  selectActiveDetail() {
    this.activeSelectFce(ActiveSelect.ACTIVE_DETAIL)
  }

  selectTowDetail() {
    this.activeSelectFce(ActiveSelect.TOW_DETAIL)
  }

  async gliderTaskChange(tow) {
    const el = await this.contentRef.current.getScrollElement();
    this.setState({editTow: tow,
      type: ActiveSelect.GLIDER_TASK_DETAIL,
      lastPositionY: el.scrollTop}
      );
  }

  towTaskChange(tow) {
    this.setState({editTow:tow, type: ActiveSelect.TOW_TASK_DETAIL})
  }

  async timeChange(id, type, value) {
    if (!type) return;
    //alert("id: " +id + " value: " + value);
    let tow = {...this.state.flyDay.tows.filter(item=> item.id === id)[0]};
    if (type === TimeType.START_TIME) {
      if (!value) return;
      tow.start = convTime(value);
    } else if (type === TimeType.LANDING_GLIDER_TIME) {
      //alert("type TimeType.LANDING_GLIDER_TIME");
      tow.gliderLanding = value ? convTime(value) : null;
    } else if (type === TimeType.LANDING_TOWING_TIME) {
      //alert("type TimeType.LANDING_TOWING_TIME");
      tow.towingPlaneLanding = value ? convTime(value) : null;
    }
    let response = await updateResource(REST_AEROTOW_URL, tow);
    if (response.error) {
      this.setShowToast(true, response.message);
    }

    //console.log("tow: " + tow.toSource() + " value: " + value.hour.value + ":"+  value.minute.value);
    //alert("id: " + id+ " type: " +type + " e: " +e);
  }

  changeTow(id, type, value) {

    let tow = {...this.state.flyDay.tows.filter(item=> item.id === id)[0]};
    if (type === PilotType.TOWING_PILOT) {
      if (!value) return;
      tow.lifter = value;
    } else if (type === PilotType.GLIDER_FIRST) {
      if (!value) return;
      tow.first = value;
    } else if (type === PilotType.GLIDER_SECOND) {
      if (!value) return;
      tow.second = value;
    } else if (type === DetailType.NOTE) {
      tow.note = value;
    }

    updateResource(REST_AEROTOW_URL, tow);

    //console.log("tow: " + tow.toSource() + " value: " + value.hour.value + ":"+  value.minute.value);
    //alert("id: " + id+ " type: " +type + " e: " +e);
  }

  toggleReel() {
    let active = {...this.props.active};
    active.reel = !active.reel;
    active["type"] = "reel";
    setActiveGlobal(SET_ACTIVE_URL, active);
    this.props.changeActive(active);
  }

  detail() {
    const {flyDay} = this.state;
    const { dateParam } = this.props.match.params;
    if (flyDay && flyDay.tows) {
      const aerotow = flyDay.tows.find(element => element.uuid === this.state.detailUUID);
      return (
          <AeroTowDetail tow={aerotow} home={this.selectHome} timeChange={this.timeChange} changeTow={this.changeTow} date={dateParam}/>
      )
    }
  }



  activeDetail() {
    const {onCloseMessageChange, closeReceiveChange, switchIncompleteDispatch, incompleteFilter} = this.props;
    const { towingPlane, lifter, glider, first, second, reel, towTask } = this.props.active;
    if (onCloseMessageChange) {
      this.selectHome();
      // if (this.contentRef.current) {
      //   alert("xxx");
      //   this.contentRef.current.scrollToBottom();
      // }
      closeReceiveChange();
    }
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => this.selectHome()} color={'primary'}><IonIcon icon={arrowBack}/> Zpět</IonButton>
                <IonButton slot={'secondary'} onClick={() => switchIncompleteDispatch()}>
                  {incompleteFilter ? 'jen neúplné': 'všechny'} <IonIcon icon={funnel}/>
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem onClick={() => this.toggleReel()}>
                <IonLabel>Naviják</IonLabel>
                <IonToggle value="navikák" checked={reel}/>
              </IonItem>
              {!reel &&
                <>
                  <IonItem onClick={() => this.activeSelectFce(ActiveSelect.TOWINGPLANE)}>
                    <IonLabel>Vlečná:</IonLabel>
                    <div>
                      {towingPlane &&
                        towingPlane.name + ' ' +towingPlane.registration
                      }
                      {!towingPlane && <span>Není</span>

                      }
                    </div>
                  </IonItem>
                  <IonItem onClick={() => this.activeSelectFce(ActiveSelect.LIFTER)}>
                    <IonLabel>Vlekař:</IonLabel>
                    <div>
                      {completePilotName(lifter)}
                    </div>
                  </IonItem>
                </>
              }
              {!(towTask && towTask.onlyTow) &&
              <>
                <IonItem onClick={() => this.activeSelectFce(ActiveSelect.GLIDER)}>
                  <IonLabel>Kluzák:</IonLabel>
                  <div>
                    {glider &&
                    glider.name + ' ' + glider.registration
                    }
                    {!glider && <span>Neni</span>

                    }
                  </div>
                </IonItem>
                <IonItem onClick={() => this.activeSelectFce(ActiveSelect.FIRST)}>
                  <IonLabel>První pilot:</IonLabel>
                  <div>
                    {completePilotName(first)}
                  </div>
                </IonItem>
                {glider && glider.twoSeat &&
                <IonItem onClick={() => this.activeSelectFce(ActiveSelect.SECOND)}>
                  <IonLabel>Druhý pilot:</IonLabel>
                  <div>
                    {completePilotName(second)}
                  </div>
                </IonItem>
                }
              </>
              }
              { !reel &&
                <IonItem onClick={() => this.activeSelectFce(ActiveSelect.TOW_TASK_DETAIL)}>
                  <IonLabel>Úloha vlečné:</IonLabel>
                  <div>
                    {towTask &&
                    towTask.name
                    }
                    {!towTask && <span>vlek</span>

                    }
                  </div>
                </IonItem>
              }
            </IonList>
          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                <IonButton disabled={false} expand='full' onClick={() => this.selectHome()} color={'primary'}>
                  <IonIcon icon={arrowBack}/> Zpět</IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>

        </IonPage>
    )
  }

  async startAction() {
    const { active } = this.props;
    const { dateParam } = this.props.match.params;
    active.flyDay = reverseDateToLocalDate(dateParam);
    var response = await sendStartAction(active);
    if (response.code !== 0) {
      this.setShowToast(true, response.message);
    }
    //console.log(response.toSource());
  }


  filterChips() {
    const {filterPilotDispatch, filterGliderDispatch,filterPilot, filterGlider,
      filterTowingPlane, filterTowingPlaneDispatch,
      filterTowingPilot, filterTowingPilotDispatch} = this.props;
    const {flyDay} = this.state;
    if (flyDay == null) return;
    const filteredList = filterList(flyDay.tows,filterPilot, filterGlider,
        filterTowingPlane, filterTowingPilot);

    var timeArr = [0, 0];
    var timeArr1 = [0, 0];
    for (var i=0; i < filteredList.length; i++) {
      var tow = filteredList[i];
      if (tow.start && tow.gliderLanding) {
        var t = subtractTimeArr(tow.gliderLanding, tow.start);
        timeArr[0] += t[0];
        timeArr[1] += t[1];
        timeArr = timeArrayCorrection(timeArr);
      }
      if (tow.start && tow.towingPlaneLanding) {
        var t1 = subtractTimeArr(tow.towingPlaneLanding, tow.start);
        timeArr1[0] += t1[0];
        timeArr1[1] += t1[1];
        timeArr1 = timeArrayCorrection(timeArr1);
      }
    }
    return (
    <IonCol size={'8'}>
      <IonToolbar>
        {filterPilot &&
            <IonChip onClick={() => filterPilotDispatch(null)}>
              <IonLabel>{filterPilot.name} {filterPilot.surname}</IonLabel>
              <IonIcon icon={closeCircle}/>
            </IonChip>
        }
        {filterGlider &&
            <IonChip onClick={() => filterGliderDispatch(null)}>
              <IonLabel>{filterGlider.name} ({filterGlider.registration}) </IonLabel>
              <IonIcon icon={closeCircle}/>
            </IonChip>
        }
        {(filterPilot || filterGlider) &&
          <IonLabel>
            {flyDay.closed ?
                <>{filteredList.length}x - {timeArrToString(timeArr)}</>
                :
                <>{filteredList.length}x</>
            }
          </IonLabel>
        }
        {filterTowingPlane &&
        <>
          <IonChip onClick={() => filterTowingPlaneDispatch(null)}>
            <IonLabel>{filterTowingPlane.name} ({filterTowingPlane.registration}) </IonLabel>
            <IonIcon icon={closeCircle}/>
          </IonChip>
          <IonLabel>
            {flyDay.closed ?
                <>{filteredList.length}x - {timeArrToString(timeArr1)}</>
                :
                <>{filteredList.length}x</>
            }
          </IonLabel>
        </>
        }
        { filterTowingPilot &&
        <>
          <IonChip onClick={() => filterTowingPilotDispatch(null)}>
            <IonLabel>{filterTowingPilot.surname} {filterTowingPilot.name} </IonLabel>
            <IonIcon icon={closeCircle}/>
          </IonChip>
          <IonLabel>
            {flyDay.closed ?
              <>{filteredList.length}x - {timeArrToString(timeArr1)}</>
                :
                <>{filteredList.length}x</>
            }
          </IonLabel>
        </>

        }

      </IonToolbar>
    </IonCol>
  );
  }

  configButton() {
    const { towingPlane, lifter, glider, first, second, reel, towTask } = this.props.active;
    const showConf = reel || towingPlane || lifter || glider || first || second;
    return(
    <IonCol size={'8'} onClick={() => this.selectActiveDetail()}>
      <div expand={'full'} className={'big-button'}>
        {showConf &&
        <IonGrid>
          <IonRow >
            <IonCol>
              { reel ?
                  <span>Naviják</span>
                  :
                  <>
                    <PlaneView plane={towingPlane} /><br />
                    <PilotView pilot={lifter} />
                  </>
              }
            </IonCol>
            <IonCol>
              { (!towTask || !towTask.onlyTow)
                  ?
                <ActiveGlider glider={glider} first={first} second={second} activeSelectFce={this.activeSelectFce} />
                :
                <span>Úloha pouze pro vlečnou ({towTask.name})</span>

              }
            </IonCol>
          </IonRow>
        </IonGrid>
        }
        { !showConf &&
        <h2 className={'text-center'}>Konfigurace</h2>
        }
      </div>
    </IonCol>
    );
  }

  scrollToPosition(positionY) {
    if (this.contentRef && this.contentRef.current) {
      this.contentRef.current.scrollToPoint(0, positionY, 100);
    } else {
      console.log("No ref to scrollable content");
    }
  }

  flyday() {
    //console.log("show flyday");
    const {flyDay, channelConnected, reconnectTimeOut, lastScrollY } = this.state;
    const {onCloseMessage, filterPilot, filterGlider, filterTowingPlane, filterTowingPilot, canDownloadPdf, day} = this.props;
    const { towingPlane, lifter, glider, first, second, reel, towTask } = this.props.active;
    const onlyTow = lifter && towingPlane && towTask && towTask.onlyTow;
    const startDisabled = !((lifter && towingPlane && glider && first) || (reel && glider && first) || onlyTow);
    let markTowId = onCloseMessage && onCloseMessage.error ? onCloseMessage.id : null;

    //console.log("flyDay.day: " + (flyDay ? flyDay.day : '----'));
    return (
        <IonPage>
          <IonHeader>

            <IonToolbar>
              <IonRow>
                <IonCol size={'4'}>
                  <IonLabel>
                    <h2 className={'date'}> {channelConnected ?
                        <div className={"btn"}><strong>{flyDay && <CustomCalendar date={flyDay.day} {...this.props} />}</strong></div>
                        :
                        <span><strong>Odpojeno! ({reconnectTimeOut}s)</strong></span>
                        }
                        </h2>
                    <h2><UtcTime time={flyDay?.time} update={this.getFlyDay}/>{markTowId}</h2>
                  </IonLabel>
                </IonCol>
                {filterPilot || filterGlider || filterTowingPlane || filterTowingPilot ?
                    this.filterChips()
                    :
                    this.configButton()
                }
              </IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollEvents={true}
                      onIonScrollStart={() => {}}
                      onIonScroll={() => {}}
                      onIonScrollEnd={() => {}}
                      ref={this.contentRef}>

            <IonMenuButton horizontal="start" slot="fixed" className={'fab-menu'}>
              <IonIcon icon={chevronForwardOutline} />
            </IonMenuButton>




            {flyDay && flyDay.planeBookDayItems &&
                <PlaneBookDayItems items={flyDay.planeBookDayItems}/>
            }
            {flyDay && flyDay.tows &&
                <AeroTowList aeroTowList={flyDay.tows} timeChange={this.timeChange} detail={this.showDetail} taskChange={this.gliderTaskChange} towTaskChange={this.towTaskChange} markId={markTowId} scrollToPosition={this.scrollToPosition} />
            }
            {this.closeDayAlert()}
            {this.toastAlert()}
          </IonContent>
          <IonFooter>
              <IonRow>
                <IonCol sizeXs={'12'} sizeMd={'4'} offsetMd={'4'}>
                {canDownloadPdf
                    ?
                      <IonButton expand='full' disabled={false} href={concatUrlId(DOWNLOAD_PDF_URL, day.replace(/\./g, "-"))} target={"_blank"}>Stáhnout Pdf</IonButton>
                    :
                    <IonButton expand='full' disabled={startDisabled} href={undefined}
                               onClick={() => this.startAction()}>Start</IonButton>

                }
                </IonCol>
              </IonRow>
          </IonFooter>
        </IonPage>
    )
  }

  setShowToast(value, message) {
    this.setState({showToast: value, toastMessage: message});
  }


  closeDayAlert() {
    const {onCloseMessage} = this.props;
    if (!onCloseMessage || !onCloseMessage.error) return;
    return (
        <IonToast
            isOpen={onCloseMessage != null}
            onDidDismiss={() => this.props.clearOnCloseMessage()}
            message={onCloseMessage.message}
            buttons={[
              {
                text: 'Zavřít',
                role: 'cancel',
                handler: () => {
                  //console.log('Cancel clicked');
                }
              }
            ]}
        />
    )
  }

  toastAlert() {
    const {showToast, toastMessage} = this.state;
    return (
      <IonToast
          isOpen={showToast}
          onDidDismiss={() => this.setShowToast(false, "")}
          message={toastMessage}
          duration={5000}
      />
    )
  }



  pilot() {
    const { type/*, active*/ } = this.state;
    const {active} = this.props;
    let lifter = false;
    let selectMessage = "Vyber pilota";
    if (type === ActiveSelect.FIRST) {
      selectMessage = "Vyber prvního pilota kluzáku";
    } else if (type === ActiveSelect.SECOND) {
      selectMessage = "Vyber druhého pilota kluzáku";
    } else if (type === ActiveSelect.LIFTER) {
      lifter = true;
      selectMessage = "Vyber vlekaře";
    }
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                {this.backToDetail()}
                <IonTitle size={'small'}>{selectMessage}</IonTitle>
                {type === ActiveSelect.SECOND &&
                  <IonButton color={"primary"} slot={'end'} onClick={() => this.setPilot(null)}>Žádný pilot</IonButton>
                }
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <PilotROList key={'pilotList'} resourceUrl={REST_PILOT_URL} view={type} active={active} setPilot={this.setPilot} findFce={findPilotFce} loadApiQuery={'active=true' + (lifter ? '&lifter=true': '')}/>
          </IonContent>
        </IonPage>
    )
  }



  glider() {
    const {type/*, active*/} = this.state;
    const { active } = this.props;
    let activeGlider = active ? active.glider : null;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                {this.backToDetail()}
                <IonTitle>Vyber kluzák</IonTitle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <GliderListRO key={'gliderList'} type={type} setGlider={this.setGlider} active={activeGlider} findFce={findGliderFce}/>
          </IonContent>
        </IonPage>
    )
  }

  towingPlane() {
    const { active } = this.props;
    const {type/*, active*/} = this.state;
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                {this.backToDetail()}
                <IonTitle>Vyber vlečnou</IonTitle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <TowingPlaneList key={'planeList'} type={type} setPlane={this.setTowingPlane} active={active ? active.towingPlane : null}/>
          </IonContent>
        </IonPage>
    )
  }

  setGliderTask(task) {
    //alert("task: " + task.toSource());
    const {editTow} = this.state;
    editTow.gliderTask = task;

    updateResource(REST_AEROTOW_URL, editTow);
    this.selectHome();
  }

  setTowTask(task) {
    //alert("task: " + task.toSource());
    const {editTow} = this.state;
    if (editTow) {
      editTow.towTask = task;
    }
    this.setActive('towTask', task);

    updateResource(REST_AEROTOW_URL, editTow);
    this.selectHome();
  }


  gliderTaskView() {
    const {editTow} = this.state;
    // group id of first pilot
    const groupId = editTow.first && editTow.first.taskGroup ? editTow.first.taskGroup.id : null;
    return (
        <GliderTaskCompose tow={editTow} setTask={this.setGliderTask} back={this.selectHome} groupId={groupId} />
    );
  }

  towTaskView() {
    const { editTow } = this.state;
    const { active } = this.props;
    return (
        <TowTaskCompose tow={editTow} setTask={this.setTowTask} active={active} back={this.selectHome} />
    );
  }

  render() {
    //console.log("render");
    const { dateParam } = this.props.match.params;
    let dayDate = dateParam.replaceAll('-', '.');
    return <><SubscribingComponent dayDate={dayDate}/>{this.renderType()}</>;
  }

  renderType() {
    const {type} = this.state;
    if (type === ActiveSelect.HOME) {
      return this.flyday();
    } else if (type === ActiveSelect.ACTIVE_DETAIL) {
      return this.activeDetail();
    } else if (type === ActiveSelect.FIRST) {
      return this.pilot();
    } else if (type === ActiveSelect.SECOND) {
      return this.pilot();
    } else if (type === ActiveSelect.LIFTER) {
      return this.pilot();
    } else if (type === ActiveSelect.GLIDER) {
      return this.glider();
    } else if (type === ActiveSelect.TOWINGPLANE) {
      return this.towingPlane();
    } else if (type === ActiveSelect.TOW_DETAIL) {
      return this.detail();
    } else if (type === ActiveSelect.GLIDER_TASK_DETAIL) {
      return this.gliderTaskView();
    } else if (type === ActiveSelect.TOW_TASK_DETAIL) {
      return this.towTaskView();
    }
  }
}


const mapStateToProps = function(state) {
  return {
    onCloseMessage: state ? state.onCloseMessage : null,
    onCloseMessageChange : state ? state.onCloseMessageChange : null,
    filterPilot: state ? state.filterPilot : null,
    filterGlider : state ? state.filterGlider : null,
    filterTowingPlane : state ? state.filterTowingPlane : null,
    filterTowingPilot : state ? state.filterTowingPilot : null,
    canDownloadPdf : state ? state.canDownloadPdf : null,
    day : state ? state.day: "",
    active: state ? state.active : {},
    incompleteFilter: state ? state.incompleteFilter : false,
    flyDay : state ? state.flyDay : null,
    flyDayStompConnectionStatus: state ? state.flyDayStompConnectionStatus : false
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(FlyDay);