import React, {Component} from "react";
import {
    documentCheckDelete,
    documentCheckSave, hasRole, loadResource,
    updateResource
} from "../common/ApiUtils";
import {
    IonButton,
    IonButtons, IonCheckbox, IonContent, IonFooter,
    IonHeader,
    IonIcon, IonInput,
    IonItem,
    IonLabel, IonList,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {arrowBack, create, document, trash} from "ionicons/icons";
import {GliderForm} from "./Glider";
import "./GliderDocument.css";
import {DateInput} from "../common/DateInput";
import {DocumentCheckTypeList} from "./DocumentCheckTypeList";
import {gliderInfo} from "../common/GliderUtils";
import {reverseDateToHuman, reverseDateToLocalDate} from "../common/TimeUtils";
import {connect} from "react-redux";
import {ROLE_ADMIN, ROLE_TECHNICIAN} from "../common/constants";

export const GliderDocumentsForm = ({glider, documentCheck={id: null, name:"", dueDate:"", type:null, count: "", hours:"", minutes:"", stopped:false,stoppedReason:"" },
                                        add, close, showTypes, type}) => {

    let _documentCheck = {
        id: null,
        name: null,
        dueDate: null,
        count : null,
        hours: null,
        minutes: null,
        stopped: null,
        stoppedReason: null,
    };

    const submit = e => {
        e.preventDefault();
        const gliderDocumentCheck = {
            id: documentCheck.id,
            gliderId: glider.id,
            typeId: type ? type.id : (documentCheck.type ? documentCheck.type.id : null),
            name: _documentCheck.name ? _documentCheck.name.value : null,
            dueDate: _documentCheck.dueDate,
            count : _documentCheck.count ? _documentCheck.count.value : null,
            hours : _documentCheck.hours ? _documentCheck.hours.value : null,
            minutes : _documentCheck.minutes ? _documentCheck.minutes.value : null,
            stopped: _documentCheck.stopped ? _documentCheck.stopped.checked : null,
            stoppedReason: _documentCheck.stoppedReason ? _documentCheck.stoppedReason.value : null
        }
        onSave(gliderDocumentCheck);

    };

    const changeDate = (id, value) => {
        //alert(value);
        _documentCheck.dueDate = value;
    };

    const onSave = async (doc) => {
        console.log(doc);
        let resp = await documentCheckSave(doc);
        // FIXME resp error
        close(true);
    }


    const onlyInteger = e => {
        let pattern = /^([0-9])$/;
        let result = pattern.test(e.key);
        if (!result) e.preventDefault();
    }

    return (
        <form onSubmit={submit}>
            <IonList>
                <IonItem onClick={() => !type ? showTypes() : f=>f}>
                    <IonLabel position="stacked">Typ dokumentu</IonLabel>
                    <h3>{ type ? type.name: 'Vybrat'}</h3>
                </IonItem>
                {type &&
                <>
                    {type.dueDate &&
                        <IonItem>
                            <IonLabel position="stacked">Lhůta platnosti</IonLabel>
                            <DateInput value={documentCheck.dueDate} dateChange={changeDate} format={'DD MM YYYY'}
                                       min={new Date().getFullYear() - 1} max={new Date().getFullYear() + 10}/>
                        </IonItem>
                    }
                    {type.startLimit &&
                        <IonItem>
                            <IonLabel position="stacked">Počet startů</IonLabel>
                            <IonInput onKeyPress={onlyInteger} inputMode="numeric" type={'number'}
                                      ref={input => _documentCheck.count = input} value={documentCheck.count}/>
                        </IonItem>
                    }
                    {type.hourLimit &&
                        <>
                            <IonItem>
                                <IonLabel position="stacked">Počet hodin</IonLabel>
                                <IonInput onKeyPress={onlyInteger} type={'number'} ref={input => _documentCheck.hours = input}
                                          value={documentCheck.hours}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Počet minut</IonLabel>
                                <IonInput onKeyPress={onlyInteger} min={"0"} max={"59"} type={'number'}
                                ref={input => _documentCheck.minutes = input} value={documentCheck.minutes}/>
                            </IonItem>
                        </>
                    }
                    {type.other &&
                        <>
                            <IonItem>
                                <IonLabel position="stacked">Provoz pozastaven</IonLabel>
                                <IonCheckbox ref={input => _documentCheck.stopped = input} checked={documentCheck.stopped}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Důvod pozastavení</IonLabel>
                                <IonInput ref={input => _documentCheck.stoppedReason = input}
                                value={documentCheck.stoppedReason}/>
                            </IonItem>
                        </>
                    }
                </>
                }

            </IonList>
            {add ?
                <IonFooter>
                    <IonButton expand={'full'} expand={true} type="submit">Vytvořit</IonButton>
                </IonFooter>
                :
                <IonFooter>
                    <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
                </IonFooter>
            }
        </form>
    )
};

/**
 * @param documentsExcept - list of documents for excluding types
 * @returns {function(*): *}
 */
const exceptDocumentTypes = documentsExcept => function(documentTypeList) {
    return documentTypeList.filter(type => !documentsExcept.map( d => d.type.id).includes(type.id));
}



class GliderDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            glider : props.entity,
            editModal: false,
            editDocumentModal: false,
            addDocumentModal: false,
            showDocuments: false,
            documents : [],
            checkDocument: null,
            typesModal : false,
            documentType : null
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.showAddDocument = this.showAddDocument.bind(this);
        this.showEditDocument = this.showEditDocument.bind(this);
        this.closeDocumentModal = this.closeDocumentModal.bind(this);
        this.toggleShowDocuments = this.toggleShowDocuments.bind(this);
        this.toggleTypes = this.toggleTypes.bind(this);
        this.setDocumentCheckType = this.setDocumentCheckType.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        //console.log("nextProps: " + nextProps.toSource());
        if (nextProps.entity._links.self.href !== this.state.glider._links.self.href) {
            this.setState({glider :nextProps.entity});
        }
    }

    showModal() {
        this.setState({
            checkDocument: null,
            documentType : null,
            editModal: true
        })
    }

    showAddDocument() {
        this.setState({
            checkDocument: null,
            documentType : null,
            addDocumentModal: true
        })
    }

    closeDocumentModal(success) {
        this.setState({
            editDocumentModal: false,
            addDocumentModal: false
        });
        if (success) {
            this.reloadGlider();
        }
    }

    closeModal() {
        this.setState({
            editModal: false
        })
    }

    toggleShowDocuments() {
        //if (this.state.editDocumentModal || this.state.addDocumentModal) return;
        const {showDocuments, glider} = this.state;
        if (!showDocuments) {
            this.reloadGlider();
        }
        this.setState({
            showDocuments: !showDocuments,
            documents: glider.check && glider.check.checkResults ? glider.check.checkResults : []
        });
    }

    async reloadGlider() {
        const {glider} = this.state;
        //var resp = await documentCheckByGlider(glider.id);
        //console.log(resp);
        var resp = await loadResource(glider._links.self.href);
        this.setState({glider : resp, documents: resp.check && resp.check.checkResults ? resp.check.checkResults : []});
        //this.setState({documents: resp._embedded ? resp._embedded.gliderDocumentCheck : []});
    }

    async onUpdate(entity) {
        this.closeModal();
        const {glider} = this.state;
        const p = await updateResource(glider._links.self.href, entity);
        this.setState({glider: p});
    }

    isActive() {
        const {glider} = this.state;
        const {active} = this.props;
        return glider && active && (
            active.id === glider.id || (active._links && active._links.self &&
                active._links.self.href === glider._links.self.href)
        );
    }

    async deleteDocument(doc) {
        this.setState( {checkDocument: null});
        await documentCheckDelete(doc);
        this.reloadGlider();
    }

    showEditDocument(doc) {
        this.setState({checkDocument: doc, editDocumentModal: true,
            documentType : doc ? doc.type : null});
    }

    toggleTypes() {
        this.setState({typesModal: !this.state.typesModal} );
    }

    setDocumentCheckType(type) {
        console.log(type);
        this.setState({documentType : type, typesModal : false});
    }

    glidersItem() {
        const {glider, editModal, editDocumentModal, addDocumentModal, showDocuments, documents, typesModal,documentType } = this.state;
        var checkDocument = this.state.checkDocument ? this.state.checkDocument: {};
        const {currentUser} = this.props;
        const techAdminRole = hasRole(ROLE_ADMIN, currentUser) || hasRole(ROLE_TECHNICIAN, currentUser);
        const isActive = this.isActive();
        const setGlider = this.props.setGlider ? this.props.setGlider : this.toggleShowDocuments;
        return (
            <>
                { glider ?
                    <IonItem color={isActive? 'secondary':''} >
                        <div className={'flex-rows'}>
                            <div className={'flex-columns'} onClick={() => setGlider(glider)}>
                                <IonLabel >
                                    <h2>{glider.name}{glider.owner ? <span className={'small'}> | {glider.owner}</span> : ''}</h2>
                                    <h3>{glider.registration}{glider.cn ? " ("+ glider.cn + ")": ""}</h3>
                                </IonLabel>
                                <IonButtons slot={'end'}>
                                    {gliderInfo(glider)}
                                    <IonButton size="small" onClick={() => this.props.deleteEntity(glider)}>
                                        <IonIcon icon={trash}/>
                                    </IonButton>
                                    <IonButton size="small" onClick={() => this.showModal()}>
                                        <IonIcon icon={create}/>
                                    </IonButton>
                                </IonButtons>
                            </div>
                            { showDocuments &&
                            <>
                                <hr />
                                <IonList>
                                    {documents.map((doc, i) =>
                                        <IonItem>
                                            <IonLabel>
                                                {doc.type.name}
                                            </IonLabel>
                                            <IonButtons slot={'end'}>
                                                <div className={'hours-minutes'}>
                                                    {doc.restCount && <>zbývá: {doc.restCount} startů <br /> </> }
                                                    {doc.restHours && <> zbývá: {doc.restHours}˚ {doc.restMinutes}' <br /></>}
                                                    {doc.dueDate && <>zbývá: {doc.restDays + 'dnů do '+ reverseDateToHuman(doc.dueDate)} <br /> </>}
                                                </div>
                                                {techAdminRole &&
                                                <>
                                                    <IonButton size="small" onClick={() => this.deleteDocument(doc)}>
                                                        <IonIcon icon={trash}/>
                                                    </IonButton>
                                                    <IonButton size="small" onClick={() => this.showEditDocument(doc)}>
                                                        <IonIcon icon={create}/>
                                                    </IonButton>
                                                </>
                                                }
                                            </IonButtons>
                                        </IonItem>
                                    )}

                                    { techAdminRole &&
                                    <IonItem>
                                        <div>
                                            <IonButton size="small" onClick={() => this.showAddDocument()}>
                                                Přidat dokument
                                            </IonButton>
                                        </div>
                                    </IonItem>
                                    }
                                </IonList>
                            </>
                            }
                        </div>

                        <IonModal isOpen={editModal} backdropDismiss={false}>
                            <IonHeader translucent={true}>
                                <IonToolbar>
                                    <IonTitle>Editace kluzáku {glider.name + ' ('+ glider.registration + ')'}</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <GliderForm entity={glider} onSave={this.onUpdate}/>
                            </IonContent>
                        </IonModal>
                        <IonModal isOpen={editDocumentModal || addDocumentModal} backdropDismiss={false}>
                            {!typesModal ?
                                <>
                                    <IonHeader translucent={true}>
                                        <IonToolbar>
                                            <IonTitle>Dokumenty {glider.name + ' (' + glider.registration + ')'}</IonTitle>
                                            <IonButtons slot="end">
                                                <IonButton
                                                    onClick={() => this.closeDocumentModal(false)}>Zavřít
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent>
                                        <GliderDocumentsForm glider={glider} documentCheck={checkDocument}
                                                             add={addDocumentModal} close={this.closeDocumentModal}
                                                             showTypes={this.toggleTypes} type={documentType}/>
                                    </IonContent>
                                </>
                                :
                                <>
                                    <IonHeader translucent={true}>
                                        <IonToolbar>
                                            <IonTitle>Typ dokumentu</IonTitle>
                                            <IonButtons slot="start">
                                                <IonButton onClick={() => this.toggleTypes()} color={'primary'}>
                                                    <IonIcon icon={arrowBack}/> Zpět
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent>
                                        <DocumentCheckTypeList setDocumentCheckType={this.setDocumentCheckType} mapFce={exceptDocumentTypes(documents)} />
                                    </IonContent>
                                </>

                            }
                        </IonModal>
                    </IonItem>
                    :
                    <IonItem>
                        Chyba
                    </IonItem>
                }
            </>

        )
    }


    render() {
        return this.glidersItem();
    }
}

const mapStateToProps = function(state) {
    return {
        currentUser: state ? state.currentUser : null,
    }
}

export default connect(mapStateToProps, null)(GliderDocument);
