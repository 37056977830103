import C from "../common/ReductTypeConstants"

export const onCloseReceive = (response) => ({
  type : C.CLOSE_RESPONSE,
  payload: response
});


export const onReOpenReceive = (response) => ({
  type : C.REOPEN_RESPONSE,
  payload: response
});


export const logout = () => ({
  type : C.LOG_OUT,
  payload: null
});

export const onLogin = (response) => ({
  type : C.LOG_IN,
  payload: response
});

export const filterPilot = (filterPilot) => ({
  type : C.FILTER_PILOT,
  payload: filterPilot
});

export const filterGlider = (filterGlider) => ({
  type : C.FILTER_GLIDER,
  payload: filterGlider
});

export const filterTowingPlane = (filterTowingPlane) => ({
  type : C.FILTER_TOWING_PLANE,
  payload: filterTowingPlane
});

export const filterTowingPilot = (liftPilot) => ({
  type : C.FILTER_TOWING_PILOT,
  payload: liftPilot
});


export const activeDispatch = (activeLoad) => ({
  type : C.ACTIVE_CONF,
  payload: activeLoad
});

export const flyDayDispatch = (fd) => ({
  type : C.FLY_DAY,
  payload: fd
});


export const onCloseReceiveFalse = () => ({
  type : C.ON_CLOSE_RECEIVE_CHANGE,
  payload: false
});

export const filterIncomplete = () => ({
  type : C.SWITCH_INCOMPLETE,
  payload: false
})

export const stompClientConnectionDispatch = (connectionStatus) => ({
  type : C.STOMP_CONNECTION,
  payload: connectionStatus
});

export const infoDataDispatch = (infoData) => ({
  type : C.GLOBAL_INFO,
  payload: infoData
});

