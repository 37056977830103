import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon, IonLabel,
    IonMenuButton,
    IonPage, IonSegment, IonSegmentButton,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {GliderList} from "../glider/GliderList";
import React, {useState} from "react";
import {arrowBack, menu} from "ionicons/icons";
import {gliders} from "../common/ApiUtils";
import {GliderDocumentCheckList} from "../glider/GliderDocumentCheckList";
import {findGliderFce} from "../common/FindFuctions";


const GlidersPage = ({back=false} ) => {

    const [viewType, setViewType] = useState("gliders");


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {back &&
                    <IonButtons slot="start">
                        <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                            <IonIcon icon={arrowBack}/> Zpět
                        </IonButton>
                    </IonButtons>
                    }

                    { /*<IonTitle>
                    /   {!onlyDocuments ? 'Flotila kluzáků' : 'Dokumenty dle platnosti'}
                    </IonTitle>
                    */}
                    <IonSegment swipeGesture={true} value={viewType}
                                onIonChange={(e) => setViewType(e.detail.value)}>
                        <IonSegmentButton value="gliders">
                            <IonLabel>Kluzáky</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="documents">
                            <IonLabel>Dokumenty</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    <IonButtons slot="end">
                        <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {viewType === "documents" ?
                    <GliderDocumentCheckList key={'gliderList'}/>
                    :
                    <GliderList key={'gliderList'} findFce={findGliderFce}/>
                }

            </IonContent>
        </IonPage>
)
}

export default GlidersPage;