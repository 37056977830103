import React, {Component} from "react";
import {
  IonButton, IonButtons, IonCheckbox,
  IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {create, trash} from "ionicons/icons";

export const PlaceForm = ({entity={name: "", fullName: "", defaultPlace: false}, onSave=f=>f}) => {
  let _place = {
    name: null,
    fullName: null,
    defaultPlace: false
  };

  const submit = e => {
    e.preventDefault();
    const place = {
      name: _place.name.value,
      fullName: _place.fullName.value,
      defaultPlace : _place.defaultPlace.checked,
    }
    onSave(place);

  };
  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Zkratka</IonLabel>
            <IonInput ref={input => _place.name = input} value={entity.name} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Jméno letiště</IonLabel>
            <IonInput ref={input => _place.fullName = input} value={entity.fullName} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Výchozí</IonLabel>
            <IonCheckbox ref={input => _place.defaultPlace = input} checked={entity.defaultPlace} />
          </IonItem>
        </IonList>
        <IonFooter>
          <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )
};



class Place extends Component {

  constructor(props) {
    super(props);
    this.state = {
      place : props.entity,
      editModal: false
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.place._links.self.href) {
      this.setState({place :nextProps.entity});
    }
  }

  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(entity) {
    this.closeModal();
    const {place} = this.state;
    const p = await updateResource(place._links.self.href, entity);
    this.setState({place: p});
  }

  isActive() {
    const {place} = this.state;
    const {active} = this.props;
    return place && active && (
        place.id === active.id || (active._links && active._links.self &&
            active._links.self.href === place._links.self.href)
    );
  }

  render() {
    const {place, editModal } = this.state;
    let setPlace = this.props.setPlace ? this.props.setPlace : f=>f;
    const isActive = this.isActive();
    //console.log("edit modal: " + editModal + " glider s:" + glider.surname + " p glider: " +this.props.glider.surname) ;
    return (
        <>
          { place ?
              <IonItem color={isActive? 'secondary':''}>
                <IonLabel onClick={() => setPlace(place)}>
                  <h2>{place.name}</h2>
                  <h3>{place.fullName}</h3>
                </IonLabel>
                <IonButtons slot={'end'}>
                  <IonButton size="small" onClick={() => this.props.deleteEntity(place)}>
                    <IonIcon icon={trash}/>
                  </IonButton>
                  <IonButton size="small" onClick={() => this.showModal()}>
                    <IonIcon icon={create}/>
                  </IonButton>
                </IonButtons>

                <IonModal isOpen={editModal} backdropDismiss={false}>
                  <IonHeader translucent={true}>
                    <IonToolbar>
                      <IonTitle>Editace letiště</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <PlaceForm entity={place} onSave={this.onUpdate}/>
                  </IonContent>
                </IonModal>
              </IonItem>
              :
              <IonItem>
                Chyba
              </IonItem>
          }
        </>

    )
  }
}

export default Place;