import React, {Component, useState} from "react";
import {
    IonAlert,
    IonButton, IonButtons, IonCheckbox,
    IonContent, IonFooter,
    IonHeader, IonIcon, IonInput,
    IonItem,
    IonLabel, IonList, IonModal, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {checkmarkCircle, create, trash} from "ionicons/icons";
import {ROLE_ADMIN, ROLE_TECHNICIAN, ROLE_USER} from "../common/constants";
import {rolesToNames} from "../common/UserUtils";

export const UserForm = ({entity={name: "", email: "", sendClosedPdf:false, sendDocumentWarn:false, namedRoles:[ROLE_USER], active:true}, onSave=f=>f, update=false}) => {
    const [getChangePassword, setChangePassword] = useState(false);
    const [active, setActive] = useState(entity.active);
    const [passwordMessage, setPasswordMessage] = useState(false);
    const [pilotId, setPilotId] = useState(entity.pilotRelation);
    const [sendClosedPdf, setSendClosedPdf] = useState(entity.sendClosedPdf);
    const [sendDocumentWarn, setSendDocumentWarn] = useState(entity.sendDocumentWarn);

    let _entity = {
        name: entity.name,
        surname: entity.surname,
        email: entity.email,
        namedRoles: entity.namedRoles,
        changePassword : null,
        password: null,
        passwordAgain: null,
    };

    const showError = () => {
        return (
            <div
                style={{
                    color: "red",
                    padding: 5,
                    paddingLeft: 12,
                    fontSize: "smaller"
                }}
            >
            Hesla nejsou shodná.
            </div>
        );
    };

    const submit = e => {
        e.preventDefault();
        var disabled = !submitEnabled();
        setPasswordMessage(disabled);
        if (disabled) {
            _entity.password.value = "";
            _entity.passwordAgain.value = "";
            return;
        }

        let needPassword = !update || getChangePassword;
        const user = {
            name: _entity.name.value ? _entity.name.value : entity.name,
            surname: _entity.surname.value ? _entity.surname.value : entity.surname,
            email: _entity.email.value ? _entity.email.value : entity.email,
            namedRoles: _entity.namedRoles,
            sendClosedPdf: sendClosedPdf,
            sendDocumentWarn: sendDocumentWarn,
            active: active,
            changePassword: needPassword,
            password: needPassword ? _entity.password.value : null,
            passwordAgain: needPassword ? _entity.passwordAgain.value : null,
            pilotId : pilotId
        }
        if (user.name) {
            user.name = user.name.trim();
        }
        if (user.surname) {
            user.surname = user.surname.trim();
        }
        if (user.email) {
            user.email = user.email.trim();
        }
        onSave(user);

    };


    const submitEnabled = () => {
        return (update && !getChangePassword) || (_entity.password.value &&
            _entity.passwordAgain.value && _entity.password.value === _entity.passwordAgain.value);
    }

    const rolesChanged = e => {
        if (!e.target.value) return;
        _entity.namedRoles = e.target.value;
    };

    const passwordChangeCheckbox = e => {
        setChangePassword(e === true);
    }

    const changePilot = e => {
        setPilotId(e.target.value);
    }

    return (
        <form onSubmit={submit}>
            <IonList>
                <IonItem>
                    <IonLabel position="stacked">Jméno</IonLabel>
                    <IonInput ref={input => _entity.name = input} value={entity.name} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Příjmení</IonLabel>
                    <IonInput ref={input => _entity.surname = input} value={entity.surname} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput ref={input => _entity.email = input} value={entity.email} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zasílat PDF po uzavření dne</IonLabel>
                    <IonCheckbox checked={sendClosedPdf} onIonChange={e => setSendClosedPdf(e.detail.checked)}/>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zasílat upozornění o expiraci dokumentu</IonLabel>
                    <IonCheckbox checked={sendDocumentWarn} onIonChange={e => setSendDocumentWarn(e.detail.checked)}/>
                </IonItem>

                <IonItem>
                    <IonLabel position="stacked">Aktivní</IonLabel>
                    <IonCheckbox ref={input => _entity.active = input} checked={active} onIonChange={e => setActive(e.detail.checked)}/>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Role</IonLabel>
                    <IonSelect onIonChange={e => rolesChanged(e)} value={entity.namedRoles} multiple={true}>
                        <IonSelectOption key={ROLE_USER} value={ROLE_USER}>
                            Uživatel
                        </IonSelectOption>
                        <IonSelectOption key={ROLE_TECHNICIAN} value={ROLE_TECHNICIAN}>
                            Technik
                        </IonSelectOption>
                        <IonSelectOption key={ROLE_ADMIN} value={ROLE_ADMIN}>
                            Administrátor
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                {update &&
                    <>
                        {entity.pilotRelationSuggestion &&
                            <IonItem>
                                <IonLabel position="stacked">Pilot</IonLabel>
                                <IonSelect onIonChange={e => changePilot(e)} value={pilotId}>
                                    {entity.pilotRelationSuggestion.map((pilot) =>
                                        <IonSelectOption key={pilot.id} value={pilot.id}>
                                            {pilot.name + ' ' + pilot.surname}
                                        </IonSelectOption>
                                    )
                                    }
                                </IonSelect>
                            </IonItem>
                        }
                        <IonItem>
                            <IonLabel position="stacked">Změnit heslo</IonLabel>
                            <IonCheckbox ref={input => _entity.changePassword = input} checked={getChangePassword}
                                         onIonChange={e => passwordChangeCheckbox(e.detail.checked)}/>
                        </IonItem>
                    </>
                }
                {(!update || getChangePassword) &&
                    <>
                        <IonItem>
                            <IonLabel position="stacked">Heslo</IonLabel>
                            <IonInput type={"password"} ref={input => _entity.password = input} value={entity.password} autocomplete={'off'}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Heslo znovu</IonLabel>
                            <IonInput type={"password"} ref={input => _entity.passwordAgain = input} value={entity.passwordAgain}/>
                        </IonItem>
                        { passwordMessage && showError() }
                    </>
                }
            </IonList>
            <IonFooter>
                <IonButton expand={'full'} type="submit">Uložit</IonButton>
            </IonFooter>
        </form>
    )
};



class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entity : props.entity,
            editModal: false,
            confirmDelete: false
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.entity._links.self.href !== this.state.entity._links.self.href) {
            this.setState({entity :nextProps.entity});
        }
    }

    showModal() {
        this.setState({
            editModal: true
        })
    }

    closeModal() {
        this.setState({
            editModal: false
        })
    }

    setConfirmDelete(value) {
        this.setState({
            confirmDelete: value
        })
    }

    async onUpdate(entityToUpdate) {
        this.closeModal();
        const {entity} = this.state;
        const p = await updateResource(entity._links.self.href, entityToUpdate);
        this.setState({entity: p});
    }

    render() {
        const {entity, editModal, confirmDelete } = this.state;
        const {active} = this.props;
        let setEntity = this.props.setEntity;
        if (!setEntity) {
            setEntity = f=>f;
        }
        //console.log(entity);
        const isActive = entity && active && active.entity && active.entity._links.self.href === entity._links.self.href;
        return (
            <>
                { entity ?
                    <IonItem color={!entity.active? 'medium':''}>
                        <IonLabel onClick={() => setEntity(entity)}>
                            <h2>{entity.surname} {entity.name} | <small>{rolesToNames(entity.namedRoles)}</small></h2>
                            <h3>{entity.email} | <small>{entity.username}</small> {entity.pilotRelation && <IonIcon size={'medium'} icon={checkmarkCircle} style={{color: 'green', verticalAlign: 'middle'}} />}</h3>
                        </IonLabel>
                        <IonButtons slot={'end'}>
                            <IonButton size="small" onClick={() => this.setConfirmDelete(true)}>
                                <IonIcon icon={trash}/>
                            </IonButton>
                            <IonButton size="small" onClick={() => this.showModal()}>
                                <IonIcon icon={create}/>
                            </IonButton>
                        </IonButtons>

                        <IonModal isOpen={editModal} backdropDismiss={false}>
                            <IonHeader translucent={true}>
                                <IonToolbar>
                                    <IonTitle>Editace uživatele</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <UserForm entity={entity} onSave={this.onUpdate} update={true} />
                            </IonContent>
                        </IonModal>

                        <IonAlert
                            isOpen={confirmDelete}
                            onDidDismiss={() => this.setConfirmDelete(false)}
                            header={'Smazání uživatele'}
                            message={'Opravdu chcete smazat uživatele: <strong>'+ entity.name+ '</strong>?'}
                            buttons={[
                                {
                                    text: 'Ne',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: () => {
                                    }
                                },
                                {
                                    text: 'Ano',
                                    handler: () => {
                                        this.props.deleteEntity(entity);
                                    }
                                }
                            ]}
                        />
                    </IonItem>
                    :
                    <IonItem>
                        Chyba
                    </IonItem>
                }
            </>

        )
    }
}

export default User;