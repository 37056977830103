import React from "react";


const Col = (props) => {
  const {head, ...rest} = props;
  if (head) {
    return <th {...rest}>{props.children}</th>
  } else {
    return <td {...rest}>{props.children}</td>
  }

};

export default Col;
