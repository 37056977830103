import React, {Component} from "react";
import {
  IonButton, IonButtons, IonCheckbox,
  IonContent, IonFooter,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel, IonList, IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import "../common/Common.css"
import {updateResource} from "../common/ApiUtils";
import {create, trash} from "ionicons/icons";

export const TowTaskForm = ({entity={name: "", fullName: "", onlyTow: false}, onSave=f=>f}) => {
  let _entity = {
    name: null,
    fullName: null,
    onlyTow: false
  };

  const submit = e => {
    e.preventDefault();
    const entity = {
      name: _entity.name.value,
      fullName: _entity.fullName.value,
      onlyTow: _entity.onlyTow.checked,
    }
    onSave(entity);

  };
  return (
      <form onSubmit={submit}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Zkratka úlohy</IonLabel>
            <IonInput ref={input => _entity.name = input} value={entity.name} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Název úlohy</IonLabel>
            <IonInput ref={input => _entity.fullName = input} value={entity.fullName} />
          </IonItem>
        </IonList>
        <IonItem>
          <IonLabel position="stacked">Jen vlečná</IonLabel>
          <IonCheckbox ref={input => _entity.onlyTow = input} checked={entity.onlyTow}/>
        </IonItem>
        <IonFooter>
          <IonButton expand={'full'} expand={true} type="submit">Uložit</IonButton>
        </IonFooter>
      </form>
  )
};



class TowTask extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity : props.entity,
      editModal: false
    };
    this.onUpdate = this.onUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps: " + nextProps.toSource());
    if (nextProps.entity._links.self.href !== this.state.entity._links.self.href) {
      this.setState({entity :nextProps.entity});
    }
  }

  showModal() {
    this.setState({
      editModal: true
    })
  }

  closeModal() {
    this.setState({
      editModal: false
    })
  }

  async onUpdate(entityToUpdate) {
    this.closeModal();
    const {entity} = this.state;
    const p = await updateResource(entity._links.self.href, entityToUpdate);
    this.setState({entity: p});
  }

  render() {
    const {entity, editModal } = this.state;
    const {active} = this.props;
    const isActive = entity && active && active.entity && active.entity._links.self.href === entity._links.self.href;
    //console.log("edit modal: " + editModal + " glider s:" + glider.surname + " p glider: " +this.props.glider.surname) ;
    return (
        <>
          { entity ?
              <IonItem color={isActive? 'secondary':''}>
                <IonLabel onClick={() => this.props.setEntity(entity)}>
                  <h2>{entity.name}</h2>
                  <h3>{entity.fullName}</h3>
                </IonLabel>
                <IonButtons slot={'end'}>
                  <IonButton size="small" onClick={() => this.props.deleteEntity(entity)}>
                    <IonIcon icon={trash}/>
                  </IonButton>
                  <IonButton size="small" onClick={() => this.showModal()}>
                    <IonIcon icon={create}/>
                  </IonButton>
                </IonButtons>

                <IonModal isOpen={editModal} backdropDismiss={false}>
                  <IonHeader translucent={true}>
                    <IonToolbar>
                      <IonTitle>Editace úlohy</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.closeModal()}>Zavřít</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <TowTaskForm entity={entity} onSave={this.onUpdate} group={entity.group}/>
                  </IonContent>
                </IonModal>
              </IonItem>
              :
              <IonItem>
                Chyba
              </IonItem>
          }
        </>

    )
  }
}

export default TowTask;