import React, {useRef, useState} from "react";
import {UsersList} from "./UsersList";
import {
    IonButton,
    IonButtons, IonCol,
    IonContent, IonFab, IonFabButton,
    IonHeader,
    IonIcon, IonInput, IonItem, IonLabel, IonList,
    IonMenuButton, IonModal,
    IonPage, IonRow,
    IonTitle, IonToast,
    IonToolbar
} from "@ionic/react";
import {arrowBack, menu, cloudUpload} from "ionicons/icons";
import {uploadCsv} from "../common/ApiUtils";
import {findUserFce} from "../common/FindFuctions";


const UsersPage = ({back=false}) => {

    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const listRef = useRef({});

    const csvFileRef = useRef({file: false});

    const onFileChange = (fileChangeEvent) => {
        csvFileRef.current.file = fileChangeEvent.target.files[0];
        setFileSelected(csvFileRef.current.file);
    };


    const addOnTab = () => {
        return (
            <IonCol>
                <IonFabButton onClick={() => setShowModal(true)}>
                    <IonIcon icon={cloudUpload} />
                </IonFabButton>
            </IonCol>
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(e);
        const file = csvFileRef.current.file;

        // Create a form data object using the FormData API
        let formData = new FormData();

        // Add the file that was just added to the form data
        formData.append("csvFile", file, file.name);
        //console.log(formData);
        const resp = await uploadCsv(formData);
        if (resp.message) {
            setToastMessage(resp.message);
            //console.log(resp.message);
            setShowToast(true);
        } else {
            listRef.current.loadResource();
            setToastMessage("Bylo přidáno "+ resp.success + " nových uživatelů.");
            //console.log(resp.message);
            setShowToast(true);
            setShowModal(false);
        }

    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <>
                        {back &&
                        <IonButtons slot="start">
                            <IonButton slot={'start'} color={'primary'} onClick={() => back()}>
                                <IonIcon icon={arrowBack}/> Zpět
                            </IonButton>
                        </IonButtons>
                        }
                        <IonTitle>
                            Uživatelé
                        </IonTitle>
                    </>
                    <IonButtons slot="end">
                        <IonMenuButton><IonIcon icon={menu}/></IonMenuButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <UsersList addon={addOnTab()} ref={listRef} findFce={findUserFce}/>

                <IonModal isOpen={showModal} cssClass='my-custom-class' onDidDismiss={() => setShowModal(false)}>
                    <IonHeader translucent={true}>
                        <IonToolbar>
                            <IonTitle>Upload uživatelů přes CSV format</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setShowModal(false)}>Zavřít</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <form onSubmit={handleSubmit} className="login-form"  method="post" encType="multipart/form-data">
                            <IonList>
                                <IonItem>
                                    <IonLabel>
                                        Formát csv: Jméno a přijmení ; Email
                                    </IonLabel>
                                </IonItem>
                                {/*<IonItemDivider>Default Input with Placeholder</IonItemDivider>*/}
                                <IonItem>
                                    <input onChange={(ev) => onFileChange(ev)} accept=".csv"  type={'file'} placeholder={'CSV file'} name={'csvFile'} />
                                </IonItem>
                            </IonList>
                            <IonButton expand="block" type="submit" className="ion-no-margin" disabled={!fileSelected}>Nahrát</IonButton>
                        </form>

                    </IonContent>
                </IonModal>
                {showToast &&
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMessage}
                    duration={5000}
                />
                }

            </IonContent>
        </IonPage>
    );
}

export default UsersPage;