import React from "react";
import Pilot, {PilotForm} from "./Pilot";
import {REST_PILOT_URL} from "../common/constants";
import ExpandableList from "../common/ExpandableList";


export const sortPilot = (a, b) => {
    // Check if both users are active or both are inactive
    if (!!a.inActive === !!b.inActive) {
        // If both users have the same active status, sort by surname
        let sc = 0;
        if (a.surname && b.surname) {
            sc = a.surname.localeCompare(b.surname, undefined, {numeric: true, sensitivity: 'base'});
        }
        return  sc === 0 ? a.name.localeCompare(b.name) : sc;
    } else if ( a.inActive) {
        // If 'a' is inactive, place 'a' after 'b'
        return 1;
    } else {
        // If 'b' is inactive, place 'b' after 'a'
        return -1;
    }
};

const PilotList = ExpandableList(Pilot, PilotForm, REST_PILOT_URL, "pilot",
    "Seznam pilotů", "Nový pilot", "Přidat pilota", sortPilot);

export default PilotList;